import React, { useEffect, useState } from "react"

import useIsSsr from "@/hooks/useIsSsr"
import BrandLogo from "@/shared/BrandLogo"
import Button from "@/components/core/Button/Button"
import UtilityWidgets from "@/components/Shared/UtilityWidgets/v1/UtilityWidgets"
import { addNavTabDatalayer } from "./analytics"
import { getUtilityStoreDetails } from "@/utils/location"
import { getConfig } from "@/constants/config"
import _get from "lodash/get"

const LogoHeader = props => {
  const isSsr = useIsSsr()

  const { data, eventHandler } = props
  const [bpNumber, setBpNumber] = useState("")
  const [findStoreUtilityDetails, setFindStoreUtilityDetails] = useState(null)
  const shortendPagePath = process.env.NEXT_PUBLIC_INTERNALLINKPATH

  const getStoreUrl = (bpNumber, link) => {
    const shortUrl = shortendPagePath ?? ""
    const htmlRegExp = new RegExp(".html", "g")
    const shortUrlRegEx = new RegExp(shortUrl, "g")
    if (bpNumber && bpNumber !== "0") {
      const bpNumberProcessed = findStoreUtilityDetails[bpNumber]
      const htmlRemovedUrl = `${bpNumberProcessed?.replace(htmlRegExp, "")}`
      return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
    } else if (link) {
      const htmlRemovedUrl = `${link.replace(htmlRegExp, "")}`
      return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
    }
  }
  useEffect(() => {
    getConfig().then(config => {
      if (bpNumber) {
        const utilityStoreDetailsUrl = _get(
          config,
          "general.storeDetailsServletPath",
          ""
        )

        getUtilityStoreDetails({ utilityStoreDetailsUrl }).then(res => {
          if (res) {
            setFindStoreUtilityDetails(res)
          }
        })
      }
    })
  }, [bpNumber])

  useEffect(() => {
    setBpNumber(localStorage.getItem("myStoreId") ?? "")
  }, [])

  return (
    <>
      {data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        if (key.includes("image")) {
          const imageKey = `logo-${index}`
          return (
            <React.Fragment key={imageKey}>
              <BrandLogo
                data={item}
                columnClassNames={`${data.columnClassNames[key]}`}
                eventHandler={eventHandler}
              />
              {!isSsr && (
                <UtilityWidgets
                  data={item}
                  columnClassNames={`${data.columnClassNames[key]}`}
                  eventHandler={eventHandler}
                />
              )}
            </React.Fragment>
          )
        }
        if (key.includes("button")) {
          const { text, appliedCssClassNames, link } = item
          const buttonKey = `button-${index}`
          return (
            <div className={`${appliedCssClassNames} button`} key={buttonKey}>
              <Button
                label={text}
                className="cmp-button"
                tag="anchor"
                type={"-"}
                to={
                  text?.includes("Request") &&
                  bpNumber &&
                  bpNumber !== "0" &&
                  findStoreUtilityDetails
                    ? getStoreUrl(bpNumber, null)
                    : getStoreUrl(null, link)
                }
                size={"-"}
                useDefaultClass={false}
                onClick={() => {
                  addNavTabDatalayer({ title: text.toLowerCase(), url: link })
                }}
              />
            </div>
          )
        }
        if (key.includes("utilitywidgets")) {
          return (
            <React.Fragment key={`utilitywidgets-${index}`}>
              {!isSsr ? (
                <UtilityWidgets
                  data={item}
                  columnClassNames={`${data.columnClassNames[key]}`}
                  eventHandler={eventHandler}
                />
              ) : null}
            </React.Fragment>
          )
        }
        return null
      })}
    </>
  )
}

export default LogoHeader
