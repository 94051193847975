import React, { useState, useEffect } from "react"
import Button from '@/components/core/Button/Button'
import { loadSmartcrop, getShortenedUrl, sanitizeInnerHtml } from "@/utils/helper"


const PromoBanner = props => {
    const {
      col,
      imageId = "promoImage",
      promoData,
      promoInfo: data
    } = props;
    const [promoImg, setPromoImg] = useState(Boolean(window.s7responsiveImage))

    useEffect(() => {
        if (!promoImg) {
          loadSmartcrop(setPromoImg)
        }
    }, [promoImg])

    useEffect(() => {
        if (promoImg && data.imgUrl) {
            if(typeof window.s7responsiveImage === 'function'){
              window.s7responsiveImage(document.getElementById(imageId))
            }
        }
    }, [promoImg, data.imgUrl])
    const sanitizeText = text => text.replace(/<\/?[^>]+(>|$)/g, "")

    let eventInfo;
    if (window.location.pathname.includes("search")) {
      eventInfo = {
        eventAction: "search results:products:main content area:shop now",
        eventName: "search results:products:main content area:shop now",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: "shop now",
        internalLinkPosition: "search",
        internalLinkType: "search results:internal campaign",
        internalLinkZoneName: "search results:products:main content area",
        internalLinkURL: window.location.origin + data.ctaLink,
        clickInternalLinks: "true",
      };
    } else {
      eventInfo = {
        eventAction: "plp shop now",
        eventName: "plp shop now",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: "shop now",
        internalLinkPosition: "plp",
        internalLinkType: "plp:internal campaign",
        internalLinkZoneName: "plp:promo tiles",
        internalLinkURL: window.location.origin + data.ctaLink,
        clickInternalLinks: "true",
      };
    }
  
    
    const handlePromoBannerClick = () => {
        if (window.adobeDataLayer) {
            const { adobeDataLayer: { getState } = {} } = window;
            const page = (getState && getState("page")) || {};
            const eventDetails = {
              event: "cmp:click",
              eventInfo,
              page,
            };
            const { componentInfo } = promoData;
            if (componentInfo) {
              eventDetails.component = [
                {
                  componentInfo,
                },
              ];
            }
            window.adobeDataLayer.push(eventDetails);
        }
    }


    return (
        <div className={`kf-promo-banner ${col === 2 || col === 3 ? '--fixed-height' : ''}`}>
            <div className={`kf-promo-banner__layout --col-${col ?? 1} ${data.promostyle} ${data.gradientPosition} ${data.contentPosition} ${data.mobileGradientPosition}`}>
                <div className="kf-promo-banner__image">
                    <img id={imageId} className={`smart-crop-img`} src={data.imgUrl ? data.imgUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRA7"} data-src={data.imgUrl} data-mode="smartcrop" alt={data.desc ?? ''} />
                </div>
                <div className="kf-promo-banner__info">
                    <div className="kf-promo-banner__desc">
                      {sanitizeInnerHtml(data.desc)}
                    </div>
                  { data.ctaLink !== '' && 
                        <div>
                        <Button 
                            role="link"
                            customClass="kf-promo-banner__cta" 
                            type="ghost" 
                            size="small"
                            to={data.ctaLink}
                            label={data.ctaLabel}
                            ariaLabel={data.ctaAriaLabel} 
                            onClick={handlePromoBannerClick}
                            useDefaultClass = {true}
                        />
                    </div>
                  }  
                </div>
            </div>
        </div>
    )
}

export default PromoBanner