import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
 
const ListComponent = ({data={}}) => {
  const { siteMapHeading="", options=[]} = data 
  const [isOpen, setIsOpen] = useState(false);
   
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (".sitemap-container-list .cmp-container .list .list-inner .cmp-list__heading"?.includes("active")){
      document.querySelectorAll(".sitemap-container-list .cmp-container .list .list-inner .cmp-list").css("display","block");
    }
  };
 
  return (
    <div class="list">
      <div className="list-inner">
        <h3 className={`cmp-list__heading ${isOpen ? 'active' : ''}`} onClick={toggleAccordion}>{siteMapHeading}</h3>
        {options?.map((opt, index) => (
          <div key={`outer_${index}`}>
              {!isOpen ? (
              <ul className="cmp-list">
                {opt?.subHeaderItem?.map((subH, subIndex) => (
                  <li key={`inner_${subIndex}`} className="cmp-page-name">
                    <a
                      href={subH?.subPageLink}
                      target={subH?.isNewTab ? "_blank" : undefined}
                      rel={subH?.subPageLinkRef ? "noreferrer" : undefined}
                    >
                      {subH?.subDisplayName}
                    </a>
                  </li>
                ))}
              </ul>
              ) : (
                  <ul className={`cmp-list ${isMobile && isOpen ? "active-block" : ""}`}>
                    {opt?.subHeaderItem?.map((subH, subIndex) => (
                      <ul>
                        <li key={`inner_${subIndex}`} className="cmp-page-name">
                          <a
                            href={subH?.subPageLink}
                            target={subH?.isNewTab ? "_blank" : undefined}
                            rel={subH?.subPageLinkRef ? "noreferrer" : undefined}
                          >
                            {subH?.subDisplayName}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </ul>)
                }
          </div>
        ))}
      </div>
    </div>   
  )
}
export default ListComponent