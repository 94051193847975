import React, { useEffect, useRef, useState } from "react"
import cx from 'classnames'
import { withOrientationChange } from 'react-device-detect'
import carret from '@/public/icons/arrow-up.svg'
import CONSTANTS from '@/constants'

const Sort = ({ texts, data, page, selectedtab, tabIndex = 0, isSearch, selectedSort, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const sortClass = cx({ 'sort': true, 'sort--open': isOpen })
    const sortBtn = useRef(null)
    const pastServiceMenu = useRef(null)
    const sortByWrapper = useRef(null)

    const getAnalyticsData = (item, needJson = false) => {
        let analyticsData = '';
        if (page === 'search') {
            analyticsData = {
                eventAction: `search results:${selectedtab}:main content area:sort by`,
                eventName: `search results:${selectedtab}:main content area:sort by`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: item.name,
                internalLinkPosition: "search results",
                internalLinkType: "search results:sort by",
                internalLinkZoneName: `search results:${selectedtab}:main content area`,
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'technicalspecs') {
            analyticsData = {
                eventAction: "technical specs:main content area:sort by",
                eventName: "technical specs:main content area:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "tech specs",
                internalLinkType: "technical specs:sort by",
                internalLinkZoneName: "technical specs:main content area​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'favorites') {
            analyticsData = {
                eventAction: `my account:favorites:${selectedtab}:sort by`,
                eventName: `my account:favorites:${selectedtab}:sort by`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "favorites",
                internalLinkType: "my account:sort by",
                internalLinkZoneName: `my account:favorites:${selectedtab}`,
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'article') {
            analyticsData = {
                eventAction: "support:help and faq:article list​:sort by",
                eventName: "support:help and faq:article list​:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "knowledge articles",
                internalLinkType: "support:help and faq:sort by",
                internalLinkZoneName: "support:help and faq:article list​​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'active-services') {
            analyticsData = {
                eventAction: "my account:services:active services​:sort by",
                eventName: "my account:services:active services​:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "my services",
                internalLinkType: "my account:sort by",
                internalLinkZoneName: "my account:services:active services",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'past-services') {
            analyticsData = {
                eventAction: "my account:services:past services​:sort by",
                eventName: "my account:services:past services​:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "my services",
                internalLinkType: "my account:sort by",
                internalLinkZoneName: "my account:services:past services",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'literature') {
            analyticsData = {
                eventAction: "literature:sort by",
                eventName: "literature:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "literature",
                internalLinkType: "literature:sort by",
                internalLinkZoneName: "literature:main content area",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'press-release') {
            analyticsData = {
                eventAction: "press room:press releases:sort by",
                eventName: "press room:press releases:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "press room",
                internalLinkType: "press room:sort by",
                internalLinkZoneName: "press room:press releases:main content area",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'press-kits') {
            analyticsData = {
                eventAction: "press room:press kits:sort by",
                eventName: "press room:press kits:sort by",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "press room",
                internalLinkType: "press room:sort by",
                internalLinkZoneName: "press room:press kits:main content area",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (page === 'my products') {
            analyticsData = {
                eventAction: "my account:products:sort by filter",
                eventName: "my account:products:sort by filter",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "my account",
                internalLinkType: "my account:sort by",
                internalLinkZoneName: "my account:products",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else {
            analyticsData = {
                eventAction: "plp sort by filter",
                eventName: "plp sort by filter",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${item.name}`,
                internalLinkPosition: "plp",
                internalLinkType: "plp:sort by",
                internalLinkZoneName: "plp:sort by filter",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        }
        if(needJson) return analyticsData
        return JSON.stringify(analyticsData);
    }
    window.addEventListener('click', function (e) {
        if (isOpen && !sortByWrapper.current?.contains(e.target)) setIsOpen(false)

    })

    useEffect(() => {
        if (isOpen) {
        pastServiceMenu.current.firstElementChild.focus()        
        }
    }, [isOpen])

    const btnKeyDown = (e) => {
        const { KEYCODE } = CONSTANTS
        switch (e.keyCode) {
            case KEYCODE.ENTER:
                e.preventDefault()
                !isOpen ? pastServiceMenu.current.firstElementChild.focus() : ''
                setIsOpen(!isOpen)
                break
            default:
                break
        }
    }

    const handleKeyDown = (i, e) => {
        e.preventDefault()
        const { KEYCODE } = CONSTANTS
        const ele = pastServiceMenu.current.childNodes
        switch (e.keyCode) {
            case KEYCODE.ESC:
                setIsOpen(false)
                break
            case KEYCODE.UP:
                if (i > 0) ele[i - 1].focus()
                break
            case KEYCODE.DOWN:
                if (i < data.length - 1) ele[i + 1].focus()
                break
            case KEYCODE.SPACE:
            case KEYCODE.ENTER:
                ele[i].click()
                break
            default:
                break
        }
    }


    return (
        <div className={sortClass} ref={sortByWrapper} >
            <button ref={sortBtn} 
                className="sort__toggle" 
                tabIndex={tabIndex} 
                aria-label={isSearch ? (selectedSort ? (`${selectedSort.name} ${texts.selected}`) : texts.sortByAria) : (selectedSort ? `${texts.sort} : ${selectedSort.name} ${texts.selected}` : texts.sortByAria)} 
                onClick={() => setIsOpen(!isOpen)} 
                onKeyDown={btnKeyDown}>
               {`${texts.sort} ${selectedSort ? ': '+selectedSort.name : 'Featured'}`}
                <img src={carret?.src} alt="" 
                    className="sort__toggle-icon" 
                    aria-hidden="true" 
                />
            </button>
            <ul ref={pastServiceMenu }className="sort__drop-down" aria-label={isOpen ? texts.sortbyExpanded : texts.sortbyClosed} aria-hidden={!isOpen}>
                {
                    data.map((item, i) => {
                        return (<li 
                            key={i}
                            id={`select-options-${i}`}
                            role="option"
                            tabIndex="-1"
                            aria-selected={item?.name === selectedSort?.name} 
                            className={`sort__item gbh-data-layer ${(item && item.name) === (selectedSort && selectedSort?.name) ? 'active' : ""}`} 
                            data-gbh-data-layer={getAnalyticsData(item)}
                            onClick={() => {onChange(item);setIsOpen(false)}}
                            onKeyDown={(e) => handleKeyDown(i, e)}
                            >
                            {item.name}
                        </li>);
                    })
                }
            </ul>
        </div>
    )
}

export default withOrientationChange(Sort);