import { getShortenedUrl } from "@/utils/location"
export const addLogoDatalayer = (url, title) => {
  const brandName = title?.replace(" ", "")?.toLowerCase()
  const eventInfo = {
    eventAction: `header ${brandName} logo`,
    eventName: `header ${brandName} logo`,
    eventType: `click`,
    internalLinkName: `header ${brandName} logo`,
    internalLinkPosition: `global header`,
    internalLinkType: `global header:navigation`,
    internalLinkZoneName: `global header`,
    internalLinkURL: url || "n/a",
    clickInternalLinks: "true",
  }
  return JSON.stringify(eventInfo)
}

export const addNavTabDatalayer = async (params) => {
  const { title, url } = params
  const eventInfo = {
    eventAction: `global header:${title}`,
    eventName: `global header:${title}`,
    eventType: `navigation`,
    internalLinkName: `${title}`,
    internalLinkPosition: `global header`,
    internalLinkType: `global header:navigation`,
    internalLinkZoneName: `global header`,
    internalLinkURL:
      `${window.location.origin}${await getShortenedUrl(url)}` || "n/a",
    clickInternalLinks: "true",
  }
  const page = window.adobeDataLayer.getState("page")
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}
