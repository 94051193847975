import React from "react"

const PdpProgressiveLoader = () => {
  return (
    <div className="progressive-loader">
      <div className="progressive-loader__container">
        <div className="row progressive-loader__filter-row">
          <div className="col-12 progressive-loader__filter-block">
            <div className="progressive-loader__filter-heading"></div>
          </div>
        </div>
        <div className="row progressive-loader__filter-contents">
          <div className="col-12 progressive-loader__filter-contents-block">
            <div className="col-1 col-lg-1 col-md-1 progressive-loader__filter-details-block">
              <div className="progressive-loader__filter-contents-heading"></div>
              <div className="progressive-loader__filter-contents-share"></div>
            </div>
            <div className="col-6 col-lg-6 col-md-6 progressive-loader__plp-contents-block">
              <div className="progressive-loader__plp-products">
                <div className="progressive-loader__plp-products-image"></div>
              </div>
            </div>
            <div className="col-5 col-lg-5 col-md-5 progressive-loader__plp-details-block">
              <div className="progressive-loader__plp-products-details">
                <div className="progressive-loader__plp-products-details-image1"></div>
                <div className="progressive-loader__plp-products-details-image2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdpProgressiveLoader
