import React, { useState, useEffect } from "react"
import Modal from "@/components/core/Modal/Modal"
import ImageCarousel from "./ImageGalleryHorizontal"
import AsProductGalleryViewer from "./AsProductGalleryViewer"
import { preventBodyScroll } from "@/utils/helper"
import useWindowResize from "@/hooks/useWindowResize"

const AsProductGalleryView = props => {
  const [showModal, setShowModal] = useState(false)
  const [isMobileDevice, setIsMobileDevice] = useState(false)

  const [width] = useWindowResize()

  useEffect(() => {
    setIsMobileDevice(width < 840)
  }, [width])

  const handleModalPopup = () => {
    setShowModal(true)
    document.body.classList.add("image-gallery-modal-opened")
  }

  const onModalClose = () => {
    setShowModal(false)
    document.body.classList.remove("image-gallery-modal-opened")
    document.body.classList.remove("scroll-lock")
    preventBodyScroll(false)
  }

  return (
    <React.Fragment>
      {isMobileDevice ? (
        <div className="mobile-gallery" id="imageGallery">
          <ImageCarousel
            {...props}
            isPopup={false}
            handlePopup={handleModalPopup}
            galleryStackId="image-gallery__image-stack-gallery"
            showBadges={true}
            showPhotoCredit="1"
          />
        </div>
      ) : (
        <AsProductGalleryViewer {...props} handlePopup={handleModalPopup} />
      )}

      {showModal && (
        <Modal onModalClose={onModalClose} showModal={showModal}>
          <div className="image-gallery-container">
            <ImageCarousel
              {...props}
              isPopup={true}
              galleryStackId="image-gallery__image-stack-kohler"
            />
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default AsProductGalleryView
