import React, { useEffect, useState, useRef } from "react"
import cx from 'classnames'
import Checkbox from '@/components/core/Checkbox/Checkbox'
import Collapsible from 'react-collapsible'
import printIcon from '@/public/icons/print.svg'
import shareIcon from '@/public/icons/share.svg'
import filterIcon from '@/public/icons/filter.svg'
import closeIcon from '@/public/icons/close-filter.svg'
import { getShortenedUrl, pushAnalyticsObjToDataLayer, getAsUniqueFilter } from "@/utils/helper"
import RadioButton from '@/components/core/RadioButton/RadioButton'
import SocialShare from '@/components/SocialShare/v1/SocialShare'
import { isMobile } from 'react-device-detect'
import { withTranslation } from "react-i18next"
import cloneDeep from "lodash/cloneDeep"
import { forceVisible } from 'react-lazyload';
import  useWindowResize  from "@/hooks/useWindowResize"
import { entityToCharacter } from "@/components/FriendsAndFamily/v1/friendsAndFamilyHelper"

const Filter = props => {
    const {sort = {}, showSort = false, onSortChange, texts = {}, selectedSort} = props
    const [width] = useWindowResize()
    const [showMore, setShowMore] = useState(false)
    const [collapse, setCollapse] = useState({})
    const [appliedFilters, setAppliedFilters] = useState([])
    const plus = <section className="plus"><div className="line-1 line"></div><div className="line line-2"></div></section>
    const [showSocialLink, setShowSocialLink] = useState(false)
    const filterFacets = useRef();
    const sortBy = useRef();
    const shareImg = useRef(null)
    const filterKeyValueParam = ['Yes','No','True','False']

    const selectedTab = props.type || "products";
    
    const formatFacetName = (name, filter) => {
        let val = (props.facetLabels && props.facetLabels[name.replace(/^\*{2}/, '')])
            ? props.facetLabels[name.replace(/^\*{2}/, '')]
            : name.replace(/\./g, '/').replace(/_/g, ' ').replace(/^\*{2}/, '')
        if (filter) val += ` - ${filter}`
        return val
    }

    useEffect(() => {
        setAppliedFilters([])
    },[props.categoryKey])

    useEffect(() => {
        if (props.currFilters && props.currFilters.length > 0) {
            setAppliedFilters(props.currFilters);
        }
    }, [props.currFilters])
    useEffect(() => {
        if (width < 992) {
            props.showFilter ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
        }else{
            document.body.style.overflow = 'visible'
        }
        if(isMobile && width < 992 && props.showFilter && facets.length) {
            sortBy?.current?.firstElementChild?.focus()
        }
    }, [width, props.showFilter])

    useEffect(() => {
            window.wow?.sync()
    }, [])

    //delete unwanted facets
    let filterList = props.facets

    delete filterList["New_Products"]
    delete filterList["Hide_Discontinued"]
    delete filterList["Products"]
    delete filterList["Inspiration"]
    delete filterList["Technical"]
    delete filterList["Parts"]
    delete filterList["Help"]
    delete filterList["Resource"]

    if(props.hidePriceRange) {
        delete filterList["Price_Range"]
    }
    else if(filterList["Price_Range"]) {
        filterList = {'Price_Range': filterList[`Price_Range`], ...filterList}
    }

    filterList = getAsUniqueFilter(filterList)
    let facets = Object.entries(filterList).filter(facet => facet[1].length > 0)
    
    const getAppliedFiltersForAnalytics = appliedFilters => {
        let filterArray = [];
        appliedFilters.forEach(filter => {
            filter.display.forEach(filtervalue => {
                filterArray.push(`${formatFacetName(filter.facet)}:${filtervalue}`);
            });
        });
        return filterArray.length ? filterArray.join("|") : '';
    }
    
    const isSelected = obj => {
        let facet = appliedFilters.find(filter => filter.facet === obj.facet)
        if (!facet) return false
        return facet.display.includes(obj.display) ? true : false
    }

    const handleFilter = (checked, obj, facets, appliedFilters) => {
        let curFilters = cloneDeep(appliedFilters);
        let facet = curFilters.find(filter => filter.facet === obj.facet)
        if (checked) {
            if (facet) {
                facet.value.push(obj.value.replace(/"/g, '\\"'))
                facet.display.push(obj.display)
            }
            else curFilters.push({ facet: obj.facet, value: [obj.value.replace(/"/g, '\\"')], display: [obj.display] })
        }
        else {
            let index = facet.display.indexOf(obj.display)
            facet.value.splice(index, 1)
            facet.display.splice(index, 1)

            if (!facet.value.length) {
                curFilters = curFilters.filter(f => f.facet !== obj.facet)
            }
        }
        setAppliedFilters([...curFilters]);
        props.addToUrl && setUrlParams(curFilters);
        props.onFilter(curFilters);
        const eventInfo = getFilterOptionsAnalyticsData(obj, facets, appliedFilters)
        pushAnalyticsObjToDataLayer(JSON.parse(eventInfo))
        if (props.handleScroll) props.handleScroll();        
    }

    const getAnalyticsData = (isUnchecked) => {          
        const hideDiscontinuedEventObj = {
            eventAction: "search results:technical:filters​:hide discontinued products",
            eventName: "search results:technical:filters​:hide discontinued products",
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: `hide discontinued products:${isUnchecked ? 'unchecked' : 'checked'}`,
            internalLinkPosition: "search results",
            internalLinkType: "search results:checkbox",
            internalLinkZoneName: "search results:technical:filters",
            internalLinkURL: "n/a",
            clickInternalLinks: "true"
        };
        return JSON.stringify(hideDiscontinuedEventObj);
    }

    const setUrlParams = filters => {
        let query = ''
        if(filters.length>0){
            filters.forEach((filter, i) => {
                const values = [...filter.value]
                if (values.length !== 0 && filter.facet === 'Price_Range' ? true : props.facetLabels[filter.facet?.replace(/^\*{2}/, '')]) {
                    query += `${filter.facet === 'Price_Range' ? filter.facet : (props.facetLabels[filter.facet?.replace(/^\*{2}/, '')]?.replaceAll(' ', '_') ?? filter.facet)}=${filter.facet === 'Price_Range' ? filter.value : encodeURIComponent(values)}${i !== filters.length - 1 ? '&' : ''}`
                }
            })
            window.history.replaceState(window.location.pathname, document.title, query.toString().length === 0 ? window.location.pathname : `?${query.toString()}`)
            if (props.resetTitle) props.resetTitle();
        }else{
            clearAllFilters()
        }
    }

    const handleCollapse = (facet, state) => {
        setCollapse({ ...collapse, [facet]: state })
    }

    const clearAllFilters = () => {
        setAppliedFilters([])
        props.onFilter([])
        window.history.replaceState({}, document.title, window.location.pathname)
        if (props.resetTitle) props.resetTitle();
    }

    const handleShowMore = () => {
        if(showMore) {
            const newCollapseState = {}
            facets.forEach((facet, i) => {
                if(i > 4) newCollapseState[facet[0]] = false
            })
            setCollapse({ ...collapse, ...newCollapseState })
        }
        setShowMore(!showMore);
    }

    useEffect(() => {
        if (showMore) {
            filterFacets.current?.childNodes && filterFacets.current.childNodes[7]?.childNodes[0]?.focus()
        }
    }, [showMore])

    const formatPriceRange = filter => {
        let values = filter.match(/\d+/g);
        return (props?.isAnnsacksPlp) ? `$${Number(values[0])?.toLocaleString()} - $${Number(values[1])?.toLocaleString()}` : `$${values[0]} - $${values[1]}`;
    }

    
    useEffect(() => {
        if (isMobile && width < 992) {
            props.showFilter ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
        }else{
            document.body.style.overflow = 'visible'
        }
        if(isMobile && width < 992 && props.showFilter && facets.length) {
            const filterCollapse = filterFacets.current.firstElementChild
            filterCollapse?.firstElementChild?.firstElementChild?.focus()
        }
    }, [width, props.showFilter])

    const getAppliedFiltersForPlpAnalyticsData = (appliedFilters,facet,obj) =>{
        let dataLayerFilterArray =[];
        appliedFilters.forEach(filter => {
            filter.display.forEach(filtervalue => {
                dataLayerFilterArray.push(`${formatFacetName(filter.facet)}:${filtervalue}`);
            });
        });  
        let myIndex = dataLayerFilterArray.indexOf(`${formatFacetName(facet)}:${obj}`);
        if (myIndex !== -1) {
            dataLayerFilterArray.splice(myIndex, 1);
        }else{
            dataLayerFilterArray.push(`${formatFacetName(facet)}:${obj}`);
        }
        return dataLayerFilterArray.length ? dataLayerFilterArray.join("|")?.toLowerCase() : '';
    }
    const getFilterOptionsAnalyticsData = (obj, facet, appliedFilters) => {        
        let plpFilterArrayData = getAppliedFiltersForPlpAnalyticsData(appliedFilters,facet[0],obj.display);
        const facetFormat = formatFacetName(facet[0])?.toLowerCase()

        const appliedOrRemoved = isSelected(obj) ? "removed" : "applied";
         
        let analyticsData = '';
       
        if (props.page === 'search') {
            analyticsData = JSON.stringify({
                eventAction: `search results:${selectedTab}:filters ${appliedOrRemoved}`,
                eventName: `search results:${selectedTab}:filters​ ${appliedOrRemoved}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: obj.display,
                internalLinkPosition: "search results",
                internalLinkType: `search results:${facetFormat}`,
                internalLinkZoneName: `search results:${selectedTab}:filters​`,
                internalLinkURL: "n/a",
                clickInternalLinks: "true",
                filterArray: plpFilterArrayData
              })
        } else if (props.page === 'technicalspecs') {
            analyticsData = JSON.stringify({
              eventAction: `technical specs:filters​:${
                appliedOrRemoved
              }`,
              eventName: `technical specs:filters​:${
                appliedOrRemoved
              }`,
              eventType: "navigation",
              eventMsg: "n/a",
              eventStatus: "n/a",
              internalLinkName: `${obj.display}:${appliedOrRemoved}`,
              internalLinkPosition: "technical specs",
              internalLinkType: `technical specs:${facetFormat}`,
              internalLinkZoneName: "technical specs:filters",
              internalLinkURL: "n/a",
              clickInternalLinks: "true",
              filterArray: plpFilterArrayData,
            });
        } else if (props.page === 'article') {
            const appliedOrRemoved = isSelected(obj) ? "removed" : "applied"
            analyticsData = JSON.stringify({
                eventAction: `support:help and faq:filters​:filter ${appliedOrRemoved}`,
                eventName: `support:help and faq:filters​:filter ${appliedOrRemoved}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${obj.display}:${appliedOrRemoved}`,
                internalLinkPosition: "support:help and faq",
                internalLinkType: `support:help and faq:​${facetFormat}`,
                internalLinkZoneName: "support:help and faq:filters​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true",
                filterArray: plpFilterArrayData,
              })
        } else if (props.page === 'literature') {
            const appliedOrRemoved = isSelected(obj) ? "removed" : "applied"
            analyticsData = JSON.stringify({
                eventAction: `literature:filters:${appliedOrRemoved}`,
                eventName: `literature:filters​:${appliedOrRemoved}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `${obj.display}:${appliedOrRemoved}`,
                internalLinkPosition: "literature",
                internalLinkType: `literature:​${facetFormat}`,
                internalLinkZoneName: "literature:filters​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true",
                filterArray: plpFilterArrayData,
              })
        } else {
          analyticsData =
            '{"eventAction":"plp filter ' +
            (isSelected(obj) ? "removed" : "applied") +
            '","eventName":"plp filter ' +
            (isSelected(obj) ? "removed" : "applied") +
            '","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
            obj?.display?.toLowerCase() +
            '","internalLinkPosition":"plp","internalLinkType":"plp:' +
            facetFormat +
            '","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true","filterArray":"' +
            plpFilterArrayData +
            '"}'
        }

        return analyticsData;
    }

    const getShowMoreAnalyticsData = showMoreFlag => {
        let analyticsData = '';
        if (props.page === 'search') {
            const lessOrMore = showMoreFlag ? 'less' : 'more';
           
            analyticsData = JSON.stringify({
                eventAction: `search results:${selectedTab}:filters:show ${lessOrMore} filters`,
                eventName: `search results:${selectedTab}:filters:show ${lessOrMore} filters`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: `show ${lessOrMore} filters`,
                internalLinkPosition: "search results",
                internalLinkType: `search results:show ${lessOrMore}`,
                internalLinkZoneName: `search results:${selectedTab}:filters`,
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              });
        } else if (props.page === 'technicalspecs') {
            analyticsData = '{"eventAction":"'+(showMoreFlag ? 'technical specs:filters:show less filters​':'technical specs:filters:show more filters​')+'","eventName":"'+(showMoreFlag ? 'technical specs:filters:show less filters​':'technical specs:filters:show more filters​')+'","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"'+(showMoreFlag ? 'show less':'show more')+' filters","internalLinkPosition":"plp","internalLinkType":"technical specs:'+(showMoreFlag ? 'show less':'show more')+'","internalLinkZoneName":"technical specs:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        } else if (props.page === 'article') {
            analyticsData = '{"eventAction":"'+(showMoreFlag ? 'support:help and faq:filters​:show less filters':'support:help and faq:filters​:show more filters')+'","eventName":"'+(showMoreFlag ? 'support:help and faq:filters​:show less filters':'support:help and faq:filters​:show more filters')+'","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"'+(showMoreFlag ? 'show less':'show more')+' filters","internalLinkPosition":"support:help and faq","internalLinkType":"support:help and faq:navigation","internalLinkZoneName":"support:help and faq:filters​","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        } else {
            analyticsData = '{"eventAction":"'+(showMoreFlag ? 'plp show less filter':'plp filter show more')+'","eventName":"'+(showMoreFlag ? 'plp show less filter':'plp filter show more')+'","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"'+(showMoreFlag ? 'show less':'show more')+' filters","internalLinkPosition":"plp","internalLinkType":"plp:'+(showMoreFlag ? 'show less':'show more')+'","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        }

        return analyticsData;
    }

    const getPrintOrShareEventInfo = (printOrShare) => {
        let analyticsData = '';
       
        if (props.page === 'search') {
            analyticsData = {
              eventAction: `search results:${selectedTab}:filters:${printOrShare}`,
              eventName: `search results:${selectedTab}:filters:${printOrShare}`,
              eventType: "navigation",
              eventMsg: "n/a",
              eventStatus: "n/a",
              internalLinkName: printOrShare,
              internalLinkPosition: "search results",
              internalLinkType: `search results:${printOrShare}`,
              internalLinkZoneName: `search results:${selectedTab}:filters`,
              internalLinkURL: "n/a",
              clickInternalLinks: "true",
            };
        } else if (props.page === 'technicalspecs') {
            analyticsData = {
                eventAction: `technical specs:filters:${printOrShare}`,
                eventName: `technical specs:filters:${printOrShare}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: printOrShare,
                internalLinkPosition: "tech specs",
                internalLinkType: `technical specs​:${printOrShare}`,
                internalLinkZoneName: "technical specs:filters​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else if (props.page === 'literature') {
            analyticsData = {
                eventAction: `literature:filters:${printOrShare}`,
                eventName: `literature:filters:${printOrShare}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: printOrShare,
                internalLinkPosition: "literature",
                internalLinkType: `literature​:${printOrShare}`,
                internalLinkZoneName: "literature:filters​",
                internalLinkURL: "n/a",
                clickInternalLinks: "true"
              }
        } else {
            analyticsData = {
                eventAction: `plp ${printOrShare}`,
                eventName: `plp ${printOrShare}`,
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: printOrShare,
                internalLinkPosition: "plp",
                internalLinkType: `plp:${printOrShare}`,
                internalLinkZoneName: "plp:filters",
                internalLinkURL: "n/a",
                clickInternalLinks: "true",
              };
        }

        return JSON.stringify(analyticsData);
    }

    const getSocialShareAnalyticsData = () => {
        let analyticsData = '';
        if (props.page === 'technicalspecs') {
            analyticsData = {eventAction: 'technical specs:filters:share', eventName: 'technical specs:filters:share', internalLinkPosition: 'tech specs', internalLinkType: 'technical specs​:share', internalLinkZoneName: 'technical specs:filters'};
        } else if (props.page === 'search') {
            analyticsData = {
              eventAction: `search results:${selectedTab}:filters:share`,
              eventName:  `search results:${selectedTab}:filters:share`,
              internalLinkPosition: "search results",
              internalLinkType: "search results:share",
              internalLinkZoneName:  `search results:${selectedTab}:filters`,
            };
        } else if (props.page === 'literature') {
            analyticsData = {
                eventAction: `literature:filters:share`,
                eventName:  `literature:filters:share`,
                internalLinkPosition: "literature",
                internalLinkType: "literature​:share",
                internalLinkZoneName:  `literature:filters`,
            }
        } else {
            analyticsData = {eventAction: 'plp share', eventName: 'plp share', internalLinkPosition: 'plp', internalLinkType: 'plp:share', internalLinkZoneName: 'plp:filters​'};
        }

        return analyticsData;
    }    

    const getShowHideFilterOptionAnalyticsData = (collapse, facet) => {
        const isCollapseOrExpand = `${collapse[facet[0]] ? "expand" : "collapse"}`
        let analyticsData = {
          eventAction: `plp filter ${isCollapseOrExpand}`,
          eventName: `plp filter ${isCollapseOrExpand}`,
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: `${isCollapseOrExpand}`,
          internalLinkPosition: "plp",
          internalLinkType: "plp:" + formatFacetName(facet[0]),
          internalLinkZoneName: "plp:filters",
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        };
        if (props.page === "technicalspecs" || props.page === "article") {
          const type =
            props.page === "technicalspecs"
              ? "technical specs"
              : "support:help and faq";
          analyticsData = {
            eventAction: `${type}:filters:${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventName: `${type}:filters:${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: !collapse[facet[0]] ? "expand" : "collapse",
            internalLinkPosition: type,
            internalLinkType: type + ":" + formatFacetName(facet[0]),
            internalLinkZoneName: type + ":filters",
            internalLinkURL: "n/a",
            clickInternalLinks: "true",
          };
        }
        if (props.page === "search") {
          const sectionTab = props.type || "products";
          analyticsData = {
            eventAction: `search results:${sectionTab}:filters ${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventName: `search results:${sectionTab}:filters ${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: !collapse[facet[0]] ? "expand" : "collapse",
            internalLinkPosition: "search results",
            internalLinkType: "search results:" + formatFacetName(facet[0]),
            internalLinkZoneName: `search results:${sectionTab}:filters`,
            internalLinkURL: "n/a",
            clickInternalLinks: "true",
          };
        }
        if (props.page === "literature") {
          analyticsData = {
            eventAction: `literature:filters:${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventName: `literature:filters:${
              !collapse[facet[0]] ? "expand" : "collapse"
            }`,
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: !collapse[facet[0]] ? "expand" : "collapse",
            internalLinkPosition: "literature",
            internalLinkType: "literature:" + formatFacetName(facet[0]),
            internalLinkZoneName: "literature:filters",
            internalLinkURL: "n/a",
            clickInternalLinks: "true",
          };
        }

        return JSON.stringify(analyticsData);
    }
    
    const getFilterCloseAnalyticsData = () => {
        let analyticsData = '';
        if (props.page === 'article') {
            analyticsData = '{"eventAction":"support:help and faq:filters​:close","eventName":"support:help and faq:filters​:close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close(x)","internalLinkPosition":"support:help and faq","internalLinkType":"support:help and faq:close","internalLinkZoneName":"support:help and faq:filters​","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        } else {
            analyticsData = '{"eventAction":"plp filter close","eventName":"plp filter close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close","internalLinkPosition":"plp","internalLinkType":"plp:close","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        }

        return analyticsData;
    }

    const getFilterViewResultsAnalyticsData = () => {
        let analyticsData = '';
        if (props.page === 'article') {
            analyticsData = '{"eventAction":"support:help and faq:filters​:view results","eventName":"support:help and faq:filters​:view results","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view results","internalLinkPosition":"support:help and faq","internalLinkType":"support:help and faq:navigation","internalLinkZoneName":"support:help and faq:filters​","internalLinkURL":"n/a","clickInternalLinks":"true"}';
        } else {
            analyticsData = '{"eventAction":"plp filter view result","eventName":"plp filter view result","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view result","internalLinkPosition":"plp","internalLinkType":"plp:filters","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true","filterArray":"'+getAppliedFiltersForAnalytics(appliedFilters)+'"}';
        }

        return analyticsData;
    }

    const getFilterClearAllAnalyticsData = () => {
        const sectionTab = props.type || "products";
        let analyticsData = {
          eventAction: "plp clear all filter",
          eventName: "plp clear all filter",
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: "clear all",
          internalLinkPosition: "plp",
          internalLinkType: "plp:clear all",
          internalLinkZoneName: "plp:filters",
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        };
        if (props.page === "article") {
          analyticsData = {
            eventAction: "support:help and faq:filters​:clear all",
            eventName: "support:help and faq:filters​:clear all",
            eventType: "informative",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: "clear all",
            internalLinkPosition: "support:help and faq",
            internalLinkType: "support:help and faq:navigation",
            internalLinkZoneName: "support:help and faq:filters​​",
            internalLinkURL: "n/a",
            clickInternalLinks: "true",
          };
        }
        if (props.page === "search") {
          analyticsData = {
            clickInternalLinks: "true",
            eventAction: `search results:${sectionTab}:clear all`,
            eventName: `search results:${sectionTab}:clear all`,
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: "clear all",
            internalLinkPosition: "search results",
            internalLinkType: "search results:clear all",
            internalLinkURL: "n/a",
            internalLinkZoneName: `search results:${sectionTab}:filters`,
          };
        }

        if (props.page === "technicalspecs") {
          analyticsData = {
            eventAction: "technical specs:filters:clear all​",
            eventName: "technical specs:filters:clear all​",
            eventType: "navigation",
            eventMsg: "n/a",
            eventStatus: "n/a",
            internalLinkName: "clear all",
            internalLinkPosition: "technical specs",
            internalLinkType: "technical specs:clear all",
            internalLinkZoneName: "technical specs:filters",
            internalLinkURL: "n/a",
            clickInternalLinks: "true",
          };
        }

        if (props.page === "literature") {
            analyticsData = {
                eventAction: "literature:filters:clear all​",
                eventName: "literature:filters:clear all​",
                eventType: "navigation",
                eventMsg: "n/a",
                eventStatus: "n/a",
                internalLinkName: "clear all",
                internalLinkPosition: "literature",
                internalLinkType: "literature:clear all",
                internalLinkZoneName: "literature:filters",
                internalLinkURL: "n/a",
                clickInternalLinks: "true",
              };
        }

        return JSON.stringify(analyticsData);
    }
    
    const getEventInfo = (value,appliedFilters,facet,filter) => {
        let filterArrayList = getAppliedFiltersForPlpAnalyticsData(appliedFilters,facet.facet,filter);

        let type = "plp filter ";
        let internalLinkPosition = "plp";
        let internalValue = "plp";
        let internalLinkName = filter;

        if (props.page === "article") {
            type = "support:help and faq:filters:";
            internalLinkPosition = "support:help and faq";
            internalValue = "support:help and faq";
            internalLinkName = `${filter}:removed`;
        } else if (props.page === 'search') {
            type = `search results:${selectedTab}:filters `;
            internalLinkPosition = "search results";
            internalValue = `search results:${selectedTab}`;
        } else if (props.page === 'technicalspecs') {
            type = "technical specs:filters​:";
            internalLinkName = `${filter}:${value}`;
            internalLinkPosition = "technical specs";
            internalValue = "technical specs"
        } else if (props.page === "literature") {
            type = "literature:filters​​:";
            internalLinkName = `${filter}:${value}`;
            internalLinkPosition = "literature";
            internalValue = "literature"
        }
        
        return JSON.stringify({
          eventAction: `${type}${value}`,
          eventName: `${type}${value}`,
          eventType: "navigation",
          filterArray:filterArrayList,
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName,
          internalLinkPosition,
          internalLinkType: `${internalValue}:${formatFacetName(facet['facet'])}`,
          internalLinkZoneName: `${internalValue}:filters`,
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        });
    } 

    const printPlp = () => {
        forceVisible();
        setTimeout(() => {
            window.print()
        }, 1250)
    }

    return (
        <div className={cx("filter", !showMore && "filter--show-less")}>
            <div className="filter__inner-container">
                <div className="product-list__controls filter__mobile-header">
                    <div className="product-list__filter-toggle gbh-data-layer" data-gbh-data-layer={getFilterCloseAnalyticsData()}>
                    <img src={filterIcon?.src} className="product-list__filter-toggle-icon" alt="Toggle Filter" />
                        <span>{props.texts.filterSort} {props.selectedFilterCount > 0 && ` (${props.selectedFilterCount})`}</span>
                    </div>
                    <img tabIndex="0" role="button" src={closeIcon?.src} className="product-list__close-icon gbh-data-layer" data-gbh-data-layer={'{"eventAction":"plp filter close","eventName":"plp filter close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close","internalLinkPosition":"close","internalLinkType":"plp:close","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}'} alt="Close Filter" onClick={() => props.close(false)} />
                </div>

                <div className="filter__chips">
                    {
                        appliedFilters.map(facet => {
                            return facet.display.map(filter =>
                                <div className="filter__chips-tag" key={filter}>
                                    <span> {filterKeyValueParam.includes(filter) ? formatFacetName(facet['facet'], filter) : filter}</span>
                                    <img src={closeIcon?.src} tabIndex="0" role="button" aria-label={`Remove filter ${filterKeyValueParam.includes(filter) ? formatFacetName(facet['facet'], filter) : filter}`}
                                    className="filter__chips-close-icon gbh-data-layer" onClick={() => handleFilter(false, { facet: facet.facet, display: filter },[facet.facet,filter],appliedFilters)} data-gbh-data-layer= {getEventInfo('cancel',appliedFilters,facet,filter) } />
                                </div>
                            )
                        })
                    }
                    {appliedFilters.length > 0 && <div className="filter__chips-clear-all gbh-data-layer" tabIndex="0" role="button" aria-label={`${props.texts.clearAll} ${props.texts.filters}`} data-gbh-data-layer={getFilterClearAllAnalyticsData()} onClick={clearAllFilters}>{props.texts.clearAll}</div>}
                </div>
                {showSort && <div className="filter__mobile-sort">
                    <div role="radiogroup" aria-labelledby="sortBy-radio">
                        <h2 id="sortBy-radio" className="filter__mobile-sort-title" aria-label={selectedSort ? (`${selectedSort.name} ${texts.selected}`) : texts.sortByAria}>
                            {`${texts.sort} ${selectedSort ? ': '+selectedSort.name : ''}`}
                        </h2>
                        <div className="radio-wrapper" ref={sortBy}>
                        { sort.map((item, i) => {
                                return (
                                    <RadioButton
                                        key={i}
                                        id={`sort-by-${item.name}`}
                                        name={`sort-by`}
                                        value={item.name}
                                        checked={item.name === selectedSort?.name}
                                        onClick={() => onSortChange(item)}
                                    />
                                );
                            })
                        }
                        </div>
                    </div>
                </div>}
                <div ref={filterFacets} role="list">
                {
                    facets.map((facet) => {
                        let facetName = formatFacetName(facet[0])
                        let facetCount = facet[1].length/2
                        return (
                            <div className="collapsible-outer" role="listitem" key={facet}>
                            <Collapsible
                            tabIndex="0"
                            open={collapse[facet[0]]} 
                            onTriggerOpening={() => handleCollapse(facet[0], true)} 
                            onTriggerClosing={() => handleCollapse(facet[0], false)} 
                            openedClassName="is-open" 
                            trigger={
                                <div 
                                    aria-label={props.t("kf.plp.ariaLabel.filterTitle", {facetName, facetCount})}
                                    aria-expanded={collapse[facet[0]] ? true : false }
                                    className="gbh-data-layer"
                                    data-gbh-data-layer={getShowHideFilterOptionAnalyticsData(collapse, facet)}>
                                        <span aria-hidden="true">{facetName}</span> {plus}
                                </div>
                            }
                            transitionTime={400} easing="ease-in-out">
                            { collapse[facet[0]] && 
                            <React.Fragment>
                                <ul role="list" className="filter-list">
                                {facet[1].map((filter, i, arr) => {
                                    if (i % 2 === 0) {
                                        let displayText = null
                                        if (facet[0] === "Price_Range") {
                                            displayText = formatPriceRange(filter);
                                        }

                                        let obj = { facet: facet[0], value: filter, display: displayText ?? filter }
                                        return arr[i + 1] !== 0 ? (
                                                <li role="listitem" key={i} >
                                                    <Checkbox
                                                    role="checkbox" 
                                                    aria-checked="false"
                                                    key={filter}
                                                    id={facet[0] + filter}
                                                    disabled={props.loading}
                                                    checkBoxAria={`${obj.display} (${arr[i + 1]}) Selecting this checkbox will update the ${obj.display} ${facetName} products`}
                                                    tabIndex={collapse[facet[0]] ? 0 : -1}
                                                    value={`${obj.display} (${arr[i + 1]})`}
                                                    onChange={e => handleFilter(e.target.checked, obj, facet, appliedFilters)}
                                                    checked={isSelected(obj)}
                                                    swatch={(facet[0] === "ColorFinish_s" || facet[0] === 'Color.Finish' || facet[0] === 'Color' || facet[0] === 'Finish') ? props.colors[filter] : null}
                                                />
                                                </li>
                                        ) : null;
                                    }
                                })}
                                </ul>
                            </React.Fragment>}
                        </Collapsible>
                        </div>)
                    })
                }
                </div>

                {facets?.length > 5 ? <button className="filter__show-more gbh-data-layer" aria-expanded={showMore} data-gbh-data-layer={getShowMoreAnalyticsData(!showMore)} onClick={handleShowMore}>
                    {showMore ? props.texts.showLess : props.texts.showMore}
                </button> : ''}

                {props.discontinuedCheck && <div className="filter__hide-discontinued">
                    <div>
                        <Checkbox
                            id="discontinued"
                            onChange={e => handleFilter(e.target.checked, { facet: "Hide_Discontinued", value: "true", display: props.texts.discontinuedText })}
                            checked={isSelected({ facet: "Hide_Discontinued", value: "true", display: props.texts.discontinuedText })}
                            value={props.texts.hideDiscontinued}
                            analyticsData={getAnalyticsData(isSelected({ facet: "Hide_Discontinued", value: "true", display: props.texts.discontinuedText }))}
                        />
                    </div>
                </div>}
                
                { props.page !== 'article' && Boolean(props.isCurated) && (props.buyingGuideDescription || props.buyingGuideLink || props.buyingGuideFieldLabel) &&
                    <div className="filter__buying-guide d-none d-sm-none d-md-block">
                        <h3>{entityToCharacter(props.buyingGuideDescription)}</h3>
                        <a href={getShortenedUrl(props.buyingGuideLink)} className='gbh-data-layer' data-gbh-data-layer={'{"eventAction":"plp see the buying guide link","eventName":"plp see the buying guide link","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"see the buying guide","internalLinkPosition":"plp","internalLinkType":"plp:informative","internalLinkZoneName":"plp:filters","internalLinkURL":"'+props.buyingGuideLink+'","clickInternalLinks":"true"}'} target={props.newTab && "_blank"}>{props.buyingGuideFieldLabel}</a>
                    </div>
                }

                <div className="filter__print-and-share">
                    {((props.displayPrint !== undefined) ? props.displayPrint : true) && 
                        <span tabIndex="0" role="button" 
                        aria-label={props.t("kf.plp.label.aria.print", {val : `${props.page} page`})} 
                        className="gbh-data-layer" 
                        data-gbh-data-layer={getPrintOrShareEventInfo('print')} 
                        onClick={() => printPlp()}>
                            <img src={printIcon?.src}
                                alt="" role="presentation" /> 
                            {props.texts.print}
                        </span>}
                    {((props.displayShare !== undefined) ? props.displayShare : true) && 
                            <span tabIndex="0" role="button" 
                            aria-label={props.t("kf.plp.label.aria.share", {val : `${props.page} page`})} 
                            className="gbh-data-layer" 
                            data-gbh-data-layer={getPrintOrShareEventInfo('share')} 
                            onClick={() => setShowSocialLink(!showSocialLink)}
                            ref={shareImg}>
                                
                                <img src={shareIcon?.src}
                                    alt="" role="presentation" /> 
                                {props.texts.share}
                            </span>}
                </div>

                <SocialShare showModal={showSocialLink} id="kf-plp-filter" onClose={() => {
                    setShowSocialLink(false)
                    shareImg?.current.focus()}
                    } 
                    shareBtn={shareImg}
                    url={window?.location?.origin + window?.location?.pathname}
                    analyticData={getSocialShareAnalyticsData()} />
            </div>
            <div className="filter__buying-guide d-block d-sm-block d-md-none">
                {Boolean(props.buyingGuideFieldLabel) && <h3>{props.buyingGuideDescription}</h3>}
                {Boolean(props.buyingGuideDescription) && (<a href={getShortenedUrl(props.buyingGuideLink)} className='gbh-data-layer' data-gbh-data-layer={'{"eventAction":"plp see the buying guide link","eventName":"plp see the buying guide link","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"see the buying guide","internalLinkPosition":"plp","internalLinkType":"plp:informative","internalLinkZoneName":"plp:filters","internalLinkURL":"'+props.buyingGuideLink+'","clickInternalLinks":"true"}'} target={props.newTab && "_blank"}>{props.buyingGuideFieldLabel}</a>)}
            </div>
            <div className="filter__footer">
                <button className={cx("filter__apply gbh-data-layer")} data-gbh-data-layer={getFilterViewResultsAnalyticsData()} onClick={() => props.close(false)}>
                    {props.loading && <div className="filter__apply-loader"></div>}
                    {`${props.texts.view} ${props.texts.results} (${props.totalResults})`}
                </button>
            </div>
        </div>
    )
}

export default withTranslation()(Filter);