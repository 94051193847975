import React, { useEffect, useState, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import UtilityWidgets from "@/components/Shared/UtilityWidgets/v1/UtilityWidgets"
import {
  selectGenericState,
  openMenu,
  closeMenu,
} from "@/store/features/genericSlice"
import useIsSsr from "@/hooks/useIsSsr"
import Tab from "@/components/Default/Tab"
import Button from "@/components/core/Button/Button"
import { useRouter } from "next/router"
import throttle from "lodash/throttle"
import { alterClass } from "@/utils"
import { addNavTabDatalayer } from "./analytics"
import { isMobile, isTablet } from "react-device-detect"

const BrandNav = props => {
  const dispatch = useDispatch()
  const isSsr = useIsSsr()
  const {
    menu: { isOpen },
  } = useSelector(selectGenericState)

  const { data, eventHandler } = props

  const toggleMenu = () => {
    isOpen ? dispatch(closeMenu()) : dispatch(openMenu())
  }
  const [scrollDir, setScrollDir] = useState("scrolling down")
  const [lastScrollY, setLastScrollY] = useState(0)

  const router = useRouter()
  const { material } = router.query;


  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      if (!document.body.classList.contains("gbh-of-scroll"))
        setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up")
      lastScrollY = scrollY > 0 ? scrollY : 1
      ticking = false
      setLastScrollY(lastScrollY)
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    const onScrollThrottle = throttle(onScroll, 1000)
    if (router?.pathname !== "/checkout") {
      window.removeEventListener("scroll", onScrollThrottle)
      window.addEventListener("scroll", onScrollThrottle)
    }
    return () => window.removeEventListener("scroll", onScrollThrottle)
  }, [router])

  useEffect(() => {
    if (scrollDir === "scrolling down") {
      alterClass("ann-sacks-tab-bar", "nav-down", "nav-up")
      alterClass("sticky", "nav-down", "nav-up")
      if (window.innerWidth < 1200) {
        alterClass("ann-sacks-logobar", "nav-down", "nav-up")
      }


    } else if (scrollDir === "scrolling up") {
      alterClass("ann-sacks-tab-bar", "nav-up", "nav-down")
      alterClass("sticky", "nav-up", "nav-down")
      if (window.innerWidth < 1200) {
        alterClass("ann-sacks-logobar", "nav-up", "nav-down")
      }


    } else return
  }, [scrollDir, lastScrollY])

  useEffect(() => {
    if (lastScrollY < 200) {
      alterClass("ann-sacks-tab-bar", null, "nav-down")
      alterClass("ann-sacks-tab-bar", null, "nav-up")
      alterClass("sticky", null, "nav-up")
      alterClass("sticky", null, "nav-down")
      if (window.innerWidth < 1200) {
        alterClass("ann-sacks-logobar", null, "nav-up")
      alterClass("ann-sacks-logobar", null, "nav-down")
      }
    }
  }, [lastScrollY, router])

  useEffect(() => {
    const annsacksTabBarEles = document.querySelectorAll(
      ".ann-sacks-main-menu.ann-sacks-tab-bar"
    )
    const menuBtns = document.querySelectorAll(
      ".gbh-hamber-menu.icon-Hamburger"
    )
    const isOrderSamplePresent = document.querySelectorAll(
      ".order-button.button"
    )
    const isAppointmentPresent = document.querySelectorAll(
      ".appointment-button.button"
    )
    const gbhNavFindStoreEle = document.querySelector(".gbh-nav__findStore")

    const liElems = [].filter.call(menuBtns, function (el) {
      const style = window.getComputedStyle(el)
      return style.display !== "none"
    })

    if (
      liElems.length &&
      !isOpen &&
      annsacksTabBarEles.length &&
      isOrderSamplePresent.length &&
      isAppointmentPresent &&
      gbhNavFindStoreEle
    ) {
      annsacksTabBarEles[0].style.display = "none"
      isOrderSamplePresent[0].style.display = "none"
      isAppointmentPresent[0].style.display = "none"
      gbhNavFindStoreEle.style.display = "none"
      document.body.style.overflow = ""
    }
    if (
      isOpen &&
      annsacksTabBarEles.length &&
      isOrderSamplePresent.length &&
      isAppointmentPresent &&
      gbhNavFindStoreEle
    ) {
      document.body.style.overflow = "hidden"
      isOrderSamplePresent[0].style.display = "block"
      isAppointmentPresent[0].style.display = "block"
      annsacksTabBarEles[0].style.display = "block"
      gbhNavFindStoreEle.style.display = "block"
    }
    const isStickyEle = document.querySelector(".is-sticky-load")
    if (isOpen && isStickyEle) {
      isStickyEle.style.display = "none"
    }

    const socialShare = document.querySelector(".social-share")
    if (isOpen && socialShare) {
      socialShare.style.zIndex = 0
    } else if (!isOpen && socialShare) {
      socialShare.style.zIndex = 9
    }
  }, [isOpen])

  const addHeaderNavTabAnalytics = data => {
    const eventInfo = {
      eventAction: `global header:${data["cq:panelTitle"].toLowerCase()}`,
      eventName: `global header:${data["cq:panelTitle"].toLowerCase()}`,
      eventType: "tab",
      internalLinkName: data["cq:panelTitle"].toLowerCase(),
      internalLinkPosition: "global header",
      internalLinkType: "global header:navigation",
      internalLinkZoneName: "global header",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
    })
  }

  return (
    <>
      {data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]

        if (key.includes("button")) {
          const { text, appliedCssClassNames, link } = item
          const navRedirectLink = link
            ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
            .join("")
            .replace(".html", "")
          return (
            <Button
              label={text}
              parentClass={appliedCssClassNames}
              className={`cmp-button ${material?.toLowerCase().split(" ").join("") === text?.toLowerCase().split(" ").join("") ? "cmp-button-active" : ""}`}
              tag="link"
              type={"type"}
              to={navRedirectLink}
              useDefaultClass={false}
              key={`button-${index}`}
              onClick={() => {
                addNavTabDatalayer({
                  title: text.toLowerCase(),
                  url: navRedirectLink,
                })
              }}
            />
          )
        }
        if (key.includes("tabs")) {
          return (
            <Tab
              data={item}
              key={`tabs-${index}`}
              columnClassNames={`${data.columnClassNames[key]}`}
              isOpen={isOpen}
              toggleMenu={toggleMenu}
              eventHandler={eventHandler}
              addHeaderNavTabAnalytics={addHeaderNavTabAnalytics}
            />
          )
        }
        if (key === "utilitywidgets") {
          return (
            <React.Fragment key={`utilitywidgets-${index}`}>
              {!isSsr ? (
                <UtilityWidgets
                  data={item}
                  columnClassNames={`${data.columnClassNames[key]}`}
                  eventHandler={eventHandler}
                />
              ) : null}
            </React.Fragment>
          )
        }

        return null
      })}
    </>
  )
}

export default BrandNav
