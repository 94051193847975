import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import leftArrow from "@/public/icons/arrow-left.svg"
import rightArrow from "@/public/icons/arrow-right.svg"
import MissingImage from "@/public/images/image-missing.png"
import { getConfig } from "@/constants/config"
import useWindowResize from "@/hooks/useWindowResize"
import debounce from "lodash.debounce"
import { getPresetUrl } from "@/utils/helper"

const ImageGalleryHorizontal = props => {
  const [scene7AccountName, setScene7AccountName] = useState("")
  const [swatchUrl, setSwatchUrl] = useState("")
  let {
    productInfo,
    presetConfigObject = {},
    imageClickCounter,
    updateImageClickCounter,
  } = props
  let imageListLength = props.data.length
  const [select, setSelected] = useState(0)
  const viewMoreLink = imageListLength > 10 ? true : false
  const imageList = props.isPopup ? props.data : props.data.slice(0, 10)
  const showArrowFlag = imageListLength > 20 && !isMobile ? true : false
  const showSliderArrow = imageListLength > 3 && !isMobile ? true : false
  const [width] = useWindowResize()
  let resourceCredits =
    props &&
    props.productGalleryLabel &&
    props.productGalleryLabel.resourceCredit_s &&
    props.productGalleryLabel.resourceCredit_s.match("http") == null
      ? props.productGalleryLabel.resourceCredit_s
      : ""

  const handleChange = (element, id, type, resourceCredits = "") => {
    const getOverAllImage = document.querySelectorAll(
      `#${props.galleryStackId}`
    )
    const defImageOffset = getOverAllImage[0].childNodes[0].offsetLeft
    const eleOffset = getOverAllImage[0].childNodes[element].offsetLeft
    const scrollValue = eleOffset - defImageOffset
    let isAlreadyChecked = false
    if (select === element) {
      isAlreadyChecked = true
    }
    slideView(scrollValue, element)
    if (window.adobeDataLayer && !isAlreadyChecked) {
      if (!id && imageList[element]) {
        // find matching if from imageList
        id = imageList[element].assetId || imageList[element].url
      }
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      const eventInfo = getEventInfo(type, id)
      const pI = productInfo ? JSON.parse(productInfo).productInfo : null
      window.adobeDataLayer.push({
        event: "cmp:click",
        page,
        eventInfo,
        productInfo: pI,
      })
      updateImageClickCounter && updateImageClickCounter()
    }
    if (resourceCredits !== "") {
      let resourceCreditsTxt =
        resourceCredits && resourceCredits.match("http") == null
          ? resourceCredits
          : ""
      document.querySelector(".image-reel__photo-credit").innerHTML =
        resourceCreditsTxt
    }
  }

  const getEventInfo = (type, id) => {
    const eventInfo = {
      eventAction: `pdp:modal:gallery:${type || "image or video"}`,
      eventName: `pdp:modal:gallery:${type || "image or video"}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: type || id,
      internalLinkPosition: "pdp",
      internalLinkType: `pdp:${type || "navigation"}`,
      internalLinkZoneName: "pdp:modal:gallery",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    if (imageClickCounter && type !== "carousel") {
      eventInfo.imageSequence = imageClickCounter + 1
    }
    return eventInfo
  }

  function slideView(scrollValue, ele) {
    const getImageView = document.getElementById(`${props.galleryStackId}`)
    window.isCustomScroll = true
    getImageView.scrollTo({
      top: 0,
      left: `${scrollValue}`,
      behavior: "smooth",
    })
    setSelected(ele)
  }

  const onScroll = debounce(() => {
    if (!window.isCustomScroll) {
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      const eventInfo = getEventInfo("carousel")
      const pI = productInfo ? JSON.parse(productInfo).productInfo : null
      window.adobeDataLayer.push({
        event: "cmp:click",
        page,
        eventInfo,
        productInfo: pI,
      })
    } else {
      window.isCustomScroll = false
    }
  }, 2000)

  useEffect(() => {
    const getImageView = document.getElementById(`${props.galleryStackId}`)
    getImageView.removeEventListener("scroll", onScroll)
    getImageView.addEventListener("scroll", onScroll, { passive: true })
    getConfig().then(config => {
      if (config.general) {
        const {
          general: { scene7AccountName = "", swatchUrl = "" },
        } = config
        setScene7AccountName(scene7AccountName)
        setSwatchUrl(swatchUrl)
      }
    })
    return () => {
      delete window.isCustomScroll
      getImageView && getImageView.removeEventListener("scroll", onScroll)
    }
  }, [imageListLength])

  const handleImageUrl = imgUrl => {
    const asset = imgUrl?.split("/").pop()
    const assetId = asset?.split(".")
    const presetConfig = JSON.parse(props?.presetConfig)
    return getPresetUrl(
      width,
      swatchUrl,
      assetId[0],
      presetConfig,
      scene7AccountName
    )
  }

  return (
    <React.Fragment>
      <div className="image-gallery">
        <div className="image-gallery__stack">
          {props.badge && (
            <div className="product-gallery__badge">
              <p className="product-gallery__new">{props.badge}</p>
            </div>
          )}
          <ul
            className="image-gallery__image-stack-view"
            id={props.galleryStackId}
          >
            {imageList.map((element, i) => {
              return (
                <li id={i} key={i} className="image-gallery__image-stack-list">
                  <img
                    onError={e => (e.target.src = MissingImage)}
                    src={handleImageUrl(element.galleryImage)}
                    alt={element.altText}
                    onClick={() => {
                      handleChange(i, element.galleryImage)
                    }}
                  />
                </li>
              )
            })}
          </ul>
          {showSliderArrow && imageList.length > 1 && (
            <>
              <span
                className={
                  select === 0 ? "previous-image" : "previous-image-dark"
                }
                onClick={() => {
                  let pos = select > 0 ? select - 1 : select
                  handleChange(pos)
                }}
              ></span>
              <span
                className={
                  select === imageList.length - 1
                    ? "next-image"
                    : "next-image-dark"
                }
                onClick={() => {
                  let position =
                    imageList.length - 1 > select ? select + 1 : select
                  handleChange(position)
                }}
              ></span>
            </>
          )}
        </div>

        <div className="image-gallery__thumb">
          {showArrowFlag && (
            <span>
              <img
                src={leftArrow}
                alt="leftArrow"
                className="image-gallery__left-arrow"
              />
            </span>
          )}
          <ul
            className="image-gallery__image-thumb-view"
            id="image-gallery__image-thumb-view"
          >
            {imageList.map((element, i) => (
              <li
                key={i}
                className="image-gallery__image-thumb-list"
                id="image-gallery__image-thumb-list"
              >
                <div
                  className={`image-gallery_${
                    select === i ? "_image-thumbSelected" : "_image-thumb"
                  }`}
                  onClick={() => {
                    props?.enablePresets
                      ? handleChange(
                          i,
                          element.assetId || element.url,
                          "",
                          element?.resourceCredits
                        )
                      : handleChange(i, element.assetId || element.url)
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter")
                      props?.enablePresets
                        ? handleChange(
                            i,
                            element.assetId || element.url,
                            "",
                            element?.resourceCredits
                          )
                        : handleChange(i, element.assetId || element.url)
                  }}
                >
                  <img
                    role="button"
                    onError={e => (e.target.src = MissingImage)}
                    src={element.galleryImage}
                    alt={element.altText}
                    tabIndex={0}
                  />
                </div>
              </li>
            ))}

            {!props.isPopup && viewMoreLink && (
              <li className="image-gallery__image-thumb-list">
                <span
                  role="button"
                  tabIndex={0}
                  className="image-gallery__link-more viewmore-mobile"
                  onClick={() => props.handlePopup()}
                >
                  {props.staticTexts.viewMore}
                </span>
              </li>
            )}
          </ul>
          {showArrowFlag && (
            <span>
              <img
                src={rightArrow}
                alt="rightArrow"
                className="image-gallery__right-arrow"
              />
            </span>
          )}
        </div>
      </div>
      {resourceCredits && (
        <p className="image-reel__photo-credit">{resourceCredits}</p>
      )}
    </React.Fragment>
  )
}

ImageGalleryHorizontal.defaultProps = {
  data: [],
  staticTexts: {},
  productInfo: "",
  imageClickCounter: 0,
}

ImageGalleryHorizontal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  staticTexts: PropTypes.object,
  productInfo: PropTypes.string,
  imageClickCounter: PropTypes.number,
  updateImageClickCounter: PropTypes.func,
}

export default ImageGalleryHorizontal
