import Image from "@/components/Default/Image/v1/Image"
import React from "react"

const ArticleCard = ({
  data = {},
  eyebrowText = "",
  staticText = {},
  getLatestReadArticleAnalytics = () => {},
}) => {
  const {
    title_s = "",
    releaseDate_dt = "",
    thumbNailImage_s = "",
    url_s = "",
  } = data

  const formatDate = dt => {
    if (dt) {
      const date = new Date(dt)
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
      })} ${date.getFullYear()}`
    }
    return ""
  }

  return (
    <div className="articleCard">
      <div className="details">
        <p className="eyebrow">{eyebrowText}</p>
        <h2 className="title">{title_s}</h2>
        <p className="desc">{formatDate(releaseDate_dt)}</p>
      </div>
      <a
        href={url_s}
        className="cta"
        aria-label={`${staticText.readArticleText} ${title_s}`}
        onClick={getLatestReadArticleAnalytics}
      >
        <span className="ctaText">{staticText.readArticleText}</span>
      </a>
      <div className="article-image">
        <Image
          data={{
            src: thumbNailImage_s,
            alt: title_s,
            enableNoImage: !thumbNailImage_s,
          }}
        />
      </div>
    </div>
  )
}

export default ArticleCard
