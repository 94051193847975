import React from "react";
import PdpProgressiveLoader from "./PdpProgressiveLoader";
import ProductSummary from "./AsProductSummary";
import AsProductGalleryView from "./AsProductGallery.view";
import { useTranslation } from "next-i18next";

function ManualPdp(props) {
  const { t } = useTranslation("common");
  const staticTexts = {
    home: t("kf.global.label.home"),
    serviceParts: t("kf.pdp.label.serviceParts"),
    viewLink: t("kf.pdp.label.viewGallery"),
  };

  if (Object.keys(props.data).length === 0) {
    return <PdpProgressiveLoader />;
  }
  return (
    <React.Fragment>
      <div className="manualPdpSummary">
        <div class="page-container">
          <div>
            <div className="product-details">
              <div className="container kf-react-container">
                <div
                  className="print-overview product-details__overview-block product-info"
                  id="product-details__overview-block"
                >
                  <div className="product-img-block product-details__image-gallery print-overview-left">
                    <AsProductGalleryView
                      data={props.data.galleryImages}
                      badge={props.data.badge}
                      staticTexts={staticTexts}
                      presetConfig={props.data.presetConfigs}
                    />
                  </div>
                  <div className="product-info-block product-details__content-section print-overview-right">
                    <ProductSummary productSummaryDetails={props.data} />
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ManualPdp;