import React from 'react'

const PLPLoader = (props) => {
    return (<><div className="container product-list__container product-list__header p0">
    <div className="row mb-60">
        <div className="col-lg-8 col-xs-12">
            <h1 className="shimmer-loading__headerTitle shimmer"></h1>
        </div>
        <div className="col-lg-4 col-xs-12">
            <p className="shimmer-loading__headpara mb20 width-320 shimmer"></p>
            <p className="shimmer-loading__headpara mb20 width-full shimmer"></p>
            <p className="shimmer-loading__headpara width-320 shimmer"></p>
        </div>
    </div>
    <div className="">
        <h1 className="shimmer-loading__headpara shimmer width-200"></h1>

    </div>
    <div className="products__controls_shimmer">
        <h1 className="shimmer-loading__para30 shimmer width-240"></h1>
        <section className="d-flex">
            <div className="products-per-page width-240">
                <p className="shimmer-loading__para30 shimmer"></p>
            </div>
            <div className="sort width-90">
                <p className="shimmer-loading__para30 shimmer"></p>
            </div>
        </section>
    </div>
</div>
<div className="container product-list__container product-list__tiles p0">
    <div className="row">
        <div className="col">
            <div>
                <div className="product-list__filter borderNone">
                    <div  >
                        <div  >
                            <div className="filter__inner-container shimmer height-300">

                            </div>
                            <div className="filter__footer">
                                <div className="products-per-page width-240 mt60 mb20">
                                    <p className="shimmer-loading__headpara shimmer"></p>
                                </div>
                                <div className="products-per-page width-100">
                                    <p className="shimmer-loading__para shimmer"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-list__tile-col">
                    <div className="row">
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                        
                    </div> 
                    <div className="row">
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                        <div className="col px-1 product-list__tile">
                            <div className="lazyload-wrapper ">
                                <div className="product-tile">
                                    <div className="product-tile__image">
                                        <div className="shimmer-loading__image shimmer"></div>
                                    </div>
                                    <div className="product-tile__details">
                                        <div className="width-full">
                                            <p className="shimmer-loading__para shimmer width-170"></p>
                                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                            <p className="shimmer-loading__para shimmer width-100"></p>
                                        </div> 
                                    </div> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></>)};

export default PLPLoader