import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AemGrid from "@/components/AemGrid"
import Script from "next/script"
import {
  getPageLevelScriptsAndStyles,
  getScriptsAndStyles,
} from "@/utils/helper"
import {
  setGlobalStyles,
  setGlobalScripts,
  selectGenericState,
} from "@/store/features/genericSlice"
import { FRAGMENT_TYPES } from "@/constants/index"
const GlobalPageFooter = props => {
  const { footer, footerScripting } = props
  const [scripts, setScripts] = useState([])
  const dispatch = useDispatch()
  const { pageIsInteractive } = useSelector(selectGenericState)
  useEffect(() => {
    const pageLevelDetails = getPageLevelScriptsAndStyles(footerScripting)
    setScripts(pageLevelDetails["pageScripts"])
  }, [footerScripting])
  useEffect(() => {
    getScriptsAndStyles(footer)
  }, [footer])

  const eventHandler = {
    store: {},
    callbacks: {
      setGlobalScripts: item => {
        dispatch(setGlobalScripts(item))
      },
      setGlobalStyles: item => {
        dispatch(setGlobalStyles(item))
      },
    },
  }
  return (
    <>
      {pageIsInteractive &&
        scripts?.map((script, i) => {
          if (script.src)
            return (
              <Script
                key={`footerscript-${i}`}
                src={script.src}
                type={script.type}
                integrity={script.integrity}
                id={`footerscript-${i}`}
              />
            )
          if (!script.src && script.innerHTML)
            return (
              <Script key={`footerscript-${i}`} id={`footerscript-${i}`}>
                {`${script.innerHTML}`}
              </Script>
            )
        })}
      <AemGrid
        data={footer}
        count={0}
        eventHandler={eventHandler}
        fragment={FRAGMENT_TYPES.FOOTER}
      />
    </>
  )
}
export default GlobalPageFooter
