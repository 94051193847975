import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { pushAnalyticsObjToDataLayer } from "@/utils/helper"
import useIsSsr from "@/hooks/useIsSsr"
import {
  isEmbeddedElement,
  isEmbeddedStyles,
  getRandom,
  sanitizeTextForAnalytics,
} from "@/utils/helper"
import {
  selectGenericState,
  toggleSiteWideWarning,
} from "@/store/features/genericSlice"
import {
  EMBED_TYPE_HTML,
  EMBED_TYPE_SCRIPT,
  MATTERPORT_ARIA_LABEL,
  VISUALICON_ARIA_LABEL,
} from "@/constants/index"

const Embed = props => {

  const {
    data: {
      script = "",
      matterportUrl = "",
      visualIcon = false,
      html = "",
      etype = "",
    } = {},
  } = props
  

  const isSsr = useIsSsr()
  const dispatch = useDispatch()
  const [stylesVal, setStylesVal] = useState([])
  const [reloadStyles, setReloadStyles] = useState(false)
  const { pageIsInteractive } = useSelector(selectGenericState)
  const etypeUppercase = etype?.toUpperCase()
  const datalayerRef = useRef()

  const servicesClick = e => {
    const { target: { href = "", textContent = "" } = {} } = e
    datalayerForCookie(textContent, href)
    window.open(href)
  }

  const datalayerForCookie = (text, url) => {
    const textCont = sanitizeTextForAnalytics(text)
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `legal:cookie policy:${textCont}`,
      eventMsg: "n/a",
      eventName: `legal:cookie policy:${textCont}`,
      eventStatus: "n/a",
      eventType: "navigation",
      internalLinkName: `${textCont}`,
      internalLinkPosition: "kohler:legal:cookie policy",
      internalLinkType: "kohler:legal:cookie policy:one trust",
      internalLinkURL: url,
      internalLinkZoneName: `kohler:legal:cookie policy:${textCont}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }

  useEffect(()=>{
    let elements  =  document.querySelectorAll(".embeddedServiceHelpButton");
    elements.forEach((element,index)=>{if(index>0){element.style.display="none"}})
  })

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      const { styles = [] } = isEmbeddedStyles(script)
      setStylesVal(styles)
      if (!reloadStyles) {
        // This is to make sure any 3rdParty Component loaded to the page will get styles applied after loading
        setTimeout(function () {
          setReloadStyles(true)
          dispatch(toggleSiteWideWarning())
        }, 3000)
      }
    }
  }, [isSsr, pageIsInteractive, reloadStyles])
  useEffect(() => {
    if (datalayerRef?.current) {
      datalayerRef?.current?.querySelectorAll("a").forEach(item => {
        if (item?.getAttribute("listener") !== "true") {
          item?.addEventListener("click", e => {
            servicesClick(e)
          })
        }
      })
    }
    return () => {
      datalayerRef?.current?.querySelectorAll("a").forEach(item => {
        item?.removeEventListener("click", servicesClick)
      })
    }
  }, [isSsr])
  return (
    <div ref={datalayerRef}>
      { html && <div className={`embed`}>
        <div
          className="cmp-embed"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </div>
      }

      {
        script && <div className={`embed`}>
        <div
          className="cmp-embed"
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        />
      </div>
      }
      
      {!isSsr && visualIcon && (
        <div className="gbh-visual-icons">
          <span
            className="gbh-visual-icon-area"
            tabIndex="0"
            role="button"
            aria-label={VISUALICON_ARIA_LABEL}
          ></span>
        </div>
      )}
      

      {
        stylesVal.map((style, i) => {
          return (
            <React.Fragment key={`embedStyle-${i}-${getRandom()}`}>
              <style id={`embedStyle-${i}-${getRandom()}`}>{style}</style>
            </React.Fragment>
          )
        })}

      
        <div
          className="modal fade virtual-tour-popup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby={MATTERPORT_ARIA_LABEL}
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <iframe
                  src={matterportUrl}
                  title=""
                  style={{ width: "100%", height: "800px", border: "0" }}
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      
    </div>
  )
}

export default Embed
