import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"

import useIsSsr from "@/hooks/useIsSsr"
import CONSTANTS from "@/constants"
import { ConditionalWrapper } from "@/utils"
import {
  convertStringToBoolean,
  getRandom,
  getShortenedUrl,
  sanitizeInnerHtml,
  sanitizeTextArticle,
} from "@/utils/helper"
import {
  doubleFeatureContentCardSettings,
  singleSliderSettings,
} from "@/components/Default/CarouselSlick/v1/SlickSettings"

import ImageV1 from "@/components/Default/Image/v1/Image"
import Teaser from "@/components/Default/Teaser/v2/Teaser"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { getDataLayerValues } from "@/components/Default/CarouselSlick/v1/AnalyticCarouselSlick"
import ProductCard from "@/components/productCard/v1/ProductCard"

const CarouselSlick = props => {
  const isSsr = useIsSsr()
  const {
    data,
    data: {
      carouselComponentProperties: {
        id = "",
        autoplay = false,
        delay = CONSTANTS.SLICK.DEFAULT_DELAY,
        appliedCssClassNames = "",
        ":itemsOrder": itemsOrder = [],
        ":items": items = [],
        accessibilityLabel = "",
      } = {},
      pageName = "",
      datalayerTitleText = "",
      componentLinkType = "",
      link: linkUrl = "",
      componentPosition = "",
      linkAll = false,
      isNewTab = false,
      bodytitle = "",
      texttitle = "",
      description = "",
      actionsEnabled = false,
      text = "",
      showSlideIndicators = false,
      ctaLable = "",
    } = {},
    fullData = {},
  } = props

  const { winWidth } = useWindowDimensions()

  const sliderDelay = 1500
  const [slickSettings, setSlickSettings] = useState({})
  const [cardIndex, setCardIndex] = useState(0)
  const [sliderKey, setSliderKey] = useState("")
  const [cardCount, setCardCount] = useState(0)
  const [isDoubleFeatureContentCard, setIsDoubleFeatureContentCard] =
    useState(false)
  const [isSingleSlider, setIsSingleSlider] = useState(false)
  const [isSingleItemsSlider, setIsSingleItemsSlider] = useState(false)
  const [isSingleSlideCarousel, setIsSingleSlideCarousel] = useState(false)
  const [isFeatureContentSlider, setIsFeatureContentSlider] = useState(false)
  const [isInspiredThemeCarouselSlider, setIsInspiredThemeCarouselSlider] =
    useState(false)
  const [isContentThreeUp, setIsContentThreeUp] = useState(false)
  const [isContentFourUp, setIsContentFourUp] = useState(false)
  const [isContentFiveUp, setIsContentFiveUp] = useState(false)
  const [isProduct, setIsProduct] = useState(false)
  const [isProductcard, setIsProductCard] = useState(false)
  const [isProductSlider, setIsProductSlider] = useState(false)
  const [isLargeCollection, setIsLargeCollection] = useState(false)
  const [isStickySlider, setIsStickySlider] = useState(false)
  const [isFeatureContent, setIsFeatureContent] = useState(false)
  const [isFullbleedHover, setIsFullbleedHover] = useState(false)
  const [activeSlide, setActiveSlide] = useState(1)
  const [link, setLink] = useState(linkUrl)
  const carouselHeaderInfoRef = useRef(null)
  const sliderRef = useRef(null)
  const [topContentHeight, setTopContentHeight] = useState(0)
  const [sliderImageHeight, setSliderImageHeight] = useState(0)
  const [countHeight, setCountHeight] = useState(0)
  const [arrowHeight, setArrowHeight] = useState(0)
  const [sliderRerenderKey, setSliderRerenderKey] = useState("")
  let dynamicMediaTimer

  const getImageHeight = list => {
    if (dynamicMediaTimer) {
      clearTimeout(dynamicMediaTimer)
      dynamicMediaTimer = undefined
    }

    // using query selector as s7responsiveImage is invoking functions on real dom elements ans was unable to get updated data using useRef
    const teaserImages = list.querySelectorAll(
      ".slick-active .teaser .cmp-teaser__image .cmp-image"
    )
    const teaserImageComponent = teaserImages[0] ?? null
    let teaserImage = null
    if (teaserImageComponent) {
      teaserImage = teaserImageComponent.querySelector("img")
    }
    let imageUrl = null
    if (teaserImage) {
      imageUrl = teaserImage?.currentSrc
    }

    if (
      !window.s7responsiveImage ||
      !imageUrl ||
      (imageUrl && imageUrl.startsWith("data:"))
    ) {
      dynamicMediaTimer = setTimeout(() => getImageHeight(list), 200)
      return
    }

    const imageHeight = teaserImageComponent?.offsetHeight
    if (imageHeight) setSliderImageHeight(imageHeight)
  }

  useEffect(() => {
    const { current: { offsetHeight = 0 } = {} } = carouselHeaderInfoRef
    if (offsetHeight > 0) {
      setTopContentHeight(offsetHeight)
    }
  }, [carouselHeaderInfoRef])

  useEffect(() => {
    const { current: { innerSlider: { list } } = {} } = sliderRef
    if (list && sliderRerenderKey) {
      getImageHeight(list)
    }
  }, [sliderRef, sliderRerenderKey])

  useEffect(() => {
    const countHeight =
      parseInt(topContentHeight) +
      parseInt(sliderImageHeight) +
      CONSTANTS.SLICK.COUNT_OFFSET
    setCountHeight(countHeight)
    if (winWidth <= 1023) {
      const arrowHeight =
        parseInt(sliderImageHeight) + CONSTANTS.SLICK.ARROW_OFFSET
      setArrowHeight(arrowHeight)
    }
  }, [topContentHeight, sliderImageHeight])

  useEffect(() => {
    if (!isEmpty(fullData) && isDoubleFeatureContentCard) {
      const cardCount = Object.keys(fullData).length - 1
      setCardCount(cardCount)
      Object.keys(fullData).forEach((key, index) => {
        if (isEqual(fullData[key], data)) {
          setCardIndex(index + 1)
        }
      })
    }
  }, [fullData, isDoubleFeatureContentCard])

  useEffect(() => {
    let timer
    if (isDoubleFeatureContentCard) {
      if (cardIndex > 0 && cardCount > 0) {
        const delay = 1500 * (cardIndex - 1)
        timer = setTimeout(() => {
          setSlickSettings(() => ({
            ...doubleFeatureContentCardSettings,
            autoplay: true,
            speed: 2 * sliderDelay,
            autoplaySpeed: cardCount * sliderDelay,
          }))
          setSliderKey(cardIndex)
        }, delay)
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [cardIndex, cardCount, isDoubleFeatureContentCard])

  useEffect(() => {
    if (isSingleSlider) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isSingleSlider
          ? {
              infinite: false,
              arrows: false,
              centerMode: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
    if (isSingleSlideCarousel) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isSingleSlideCarousel
          ? {
              infinite: false,
              arrows: false,
              centerMode: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              centerPadding:0
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
    if (isSingleItemsSlider) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isSingleItemsSlider
          ? {
              infinite: false,
              centerPadding:
                winWidth > 1600
                  ? winWidth / 16
                  : 110 || winWidth < 640
                  ? 18
                  : 36,
              arrows: true,
              centerMode: false,
              dots: true,
              slidesToShow: winWidth > 1140 ? 1.2 : winWidth > 640 ? 2 : 1,
              slidesToScroll: 1,
              autoplay: false,
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }

    if (isFeatureContentSlider) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isFeatureContentSlider
          ? {
              infinite: false,
              centerPadding: 0,
              arrows: true,
              centerMode: true,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              adaptiveHeight: false,
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }

    if (isInspiredThemeCarouselSlider) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        autoplay: false,
        autoplaySpeed: 5000,
        dots: true,
        arrows: false,
        slidesToShow: winWidth > 1140 ? 3 : winWidth > 640 ? 2 : 1,
        slidesToScroll: 1,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }

    if (isContentThreeUp) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 3.4 : winWidth >= 561 ? 2.39 : 1.139,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }
    if (isContentFourUp) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 4.43 : winWidth >= 561 ? 2.48 : 1.139,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        centerMode: false,
        centerPadding: 0,
      })
      setSlickSettings(slickSettings())
    }
    if (isContentFiveUp) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 5.572 : winWidth >= 561 ? 3.25 : 1.34,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 4.43 : winWidth >= 561 ? 2.48 : 1.139,
        slidesToScroll: 1,
        arrow: false,
        dots: true,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }
    if (isProduct) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 3.94 : winWidth >= 561 ? 4.7 : 2.15,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }
    if (isProductcard) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 3.16 : winWidth >= 561 ? 4.7 : 2.15,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }
    if (isProductSlider) {
      const settings = singleSliderSettings()
      const slickSettings = () => ({
        ...settings,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: winWidth >= 1024 ? 3.94 : winWidth >= 561 ? 4.7 : 2.15,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        centerMode: false,
      })
      setSlickSettings(slickSettings())
    }
    if (isLargeCollection) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isLargeCollection
          ? {
              infinite: false,
              arrows: false,
              centerMode: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              centerPadding: winWidth > 1025
              ? winWidth / 12
              : 270 || winWidth < 640
              ? winWidth / 12 
              : 18	
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
    if (isStickySlider) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isStickySlider
          ? {
              infinite: false,
              centerPadding: 0,
              arrows: true,
              centerMode: true,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              adaptiveHeight: false,
              centerPadding: winWidth > 1025
              ? winWidth / 14
              : 270 || winWidth < 640
              ? winWidth / 1024 
              : 32
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
    if (isFeatureContent) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isFeatureContent
          ? {
              infinite: false,
              arrows: false,
              centerMode: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              centerPadding: winWidth > 1025
              ? winWidth / 7
              : 370 || winWidth < 640
              ? winWidth / 12 
              : 18
          }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
    if (isFullbleedHover) {
      const settings = singleSliderSettings(
        setActiveSlide,
        arrowHeight,
        autoplay,
        delay
      )
      const slickSettings = () => ({
        ...settings,
        ...(isFullbleedHover
          ? {
              infinite: false,
              arrows: false,
              centerMode: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              centerPadding: winWidth > 1025
              ? winWidth / 8
              : 270 || winWidth < 640
              ? winWidth / 16 
              : 18
            }
          : {}),
        centerMode: winWidth >= 640,
        dots: true,
      })
      setSlickSettings(slickSettings())
    }
  }, [
    isSingleSlider,
    isSingleSlideCarousel,
    isSingleItemsSlider,
    isFeatureContentSlider,
    isInspiredThemeCarouselSlider,
    isContentFourUp,
    arrowHeight,
    winWidth,
    isContentThreeUp,
    isContentFourUp,
    isContentFiveUp,
    isProduct,
    isProductSlider,
    isProductcard,
    isLargeCollection,
    isFeatureContent,
    isFullbleedHover
  ])

  

  useEffect(() => {
    if (appliedCssClassNames?.includes("two-column-layout")) {
      setIsDoubleFeatureContentCard(true)
    }
    if (
      appliedCssClassNames?.includes("feature-collection-carousel") ||
      appliedCssClassNames?.includes("room-inspiration-carousel")
    ) {
      setIsSingleSlider(true)
    }
    if (
      appliedCssClassNames?.includes("large-collection-photo-carousel")
    ) {
      setIsLargeCollection(true)
    }
    if (
      appliedCssClassNames?.includes("carousel-navigation-with-arrows")     ) {
      setIsSingleSlideCarousel(true)
    }

    if (
      appliedCssClassNames?.includes("carousel-text-left-overlap") ||
      appliedCssClassNames?.includes("full-bleed-image-hover")
    ) {
      setIsSingleItemsSlider(true)
    }

    if (appliedCssClassNames?.includes("featured-content-carousel")) {
      setIsFeatureContentSlider(true)
    }

    if (appliedCssClassNames?.includes("sticky-content-center")) {
      setIsStickySlider(true)
    }

    if (appliedCssClassNames?.includes("carousel-featured-content")) {
      setIsFeatureContent(true)
    }

    if (appliedCssClassNames?.includes("full-bleed-second-image")) {
      setIsFullbleedHover(true)
    }

    if (appliedCssClassNames?.includes("inspired-theme-carousel")) {
      setIsInspiredThemeCarouselSlider(true)
    }

    if (appliedCssClassNames?.includes("content-three-up")) {
      setIsContentThreeUp(true)
    }
    if (appliedCssClassNames?.includes("content-four-up")) {
      setIsContentFourUp(true)
    }
    if (appliedCssClassNames?.includes("content-five-up")) {
      setIsContentFiveUp(true)
    }
    if (
      appliedCssClassNames?.includes("inspired-theme-carousel") ||
      appliedCssClassNames?.includes("content-three-up")
    ) {
      setIsInspiredThemeCarouselSlider(true)
    }
    if (
      appliedCssClassNames?.includes("carousel-header-info") &&
      appliedCssClassNames?.includes("carousel-swipe-scroll") &&
      !appliedCssClassNames?.includes("manual-product-slider-carousel")) {
      setIsProduct(true)
    }
    if (
      appliedCssClassNames?.includes("carousel-swipe-scroll") &&
      appliedCssClassNames?.includes("carousel-header-content")) {
      setIsProductCard(true)
    }
    if (appliedCssClassNames?.includes("content-four-up") || 
    appliedCssClassNames?.includes("inspiration-module-carousel")) {
      setIsContentFourUp(true)
    }
    if (appliedCssClassNames?.includes("carousel-header-info") &&
    appliedCssClassNames?.includes("carousel-swipe-scroll") &&
    appliedCssClassNames?.includes("manual-product-slider-carousel")) {
      setIsProductSlider(true)
    }
  }, [data])

  useEffect(() => {
    if (linkUrl) {
      getShortenedUrl(linkUrl).then(res => {
        setLink(res)
      })
    }
  }, [linkUrl])

  useEffect(() => {
    let key = "slider-default"
    if (sliderKey) {
      key = `slider-${sliderKey}`
    }
    if (
      isSingleItemsSlider ||
      isSingleSlider ||
      isSingleSlideCarousel ||
      isFeatureContentSlider ||
      isInspiredThemeCarouselSlider ||
      isProductSlider ||
      isLargeCollection || 
      isStickySlider ||
      isFeatureContent ||
      isFullbleedHover
    ) {
      key = `slider-${id ? id : appliedCssClassNames}`
    }
    setSliderRerenderKey(key)
  }, [
    sliderKey,
    isSingleItemsSlider,
    isSingleSlider,
    isSingleSlideCarousel,
    isFeatureContentSlider,
    isInspiredThemeCarouselSlider,
    isProductSlider,
    isLargeCollection,
    isStickySlider,
    isFeatureContent,
    isFullbleedHover
  ])


  return (
    <>
      <div
        className="cmp-carousel cmp-carousel-slick"
        role="group"
        aria-label={accessibilityLabel || undefined}
        aria-roledescription="carousel"
        data-gbh-data-layer={`{"eventName":"${pageName}:carousel:interaction","eventAction":"${pageName}:carousel:interaction","internalLinkName":"carousel:${
          datalayerTitleText || "rm"
        }","internalLinkType":"${pageName}:${
          componentLinkType?.toLowerCase() || datalayerTitleText || "rm"
        }","internalLinkZoneName":"${pageName}:carousel","clickInternalLinks":"true","eventType":"carousel","internalLinkURL":"${link}","internalLinkPosition":"${pageName}:${
          componentPosition || "carousel"
        }"}`}
      >
        <ConditionalWrapper
          condition={convertStringToBoolean(linkAll)}
          wrapper={children => (
            <a
              href={link}
              data-gbh-data-layer={`{"eventName":"${pageName}:carousel:interaction","eventAction":"${pageName}:carousel:interaction","internalLinkName":"carousel:${
                datalayerTitleText || "rm"
              }","internalLinkType":"${pageName}:${
                componentLinkType?.toLowerCase() || datalayerTitleText || "rm"
              }","internalLinkZoneName":"${pageName}:carousel","clickInternalLinks":"true","eventType":"carousel","internalLinkURL":"${link}","internalLinkPosition":"${pageName}:${
                componentPosition || "carousel"
              }"}`}
              data-gbh-clickable="true"
              target={`${
                convertStringToBoolean(isNewTab) ? "_blank" : "_self"
              }`}
            >
              {children}
            </a>
          )}
        >
          <>
            <div className="carousel-header-info" ref={carouselHeaderInfoRef}>
              {bodytitle && (
                <div
                  className="cmp-carousel__pretitle"
                  dangerouslySetInnerHTML={{ __html: bodytitle }}
                />
              )}

              {texttitle && (
                <div
                  className="cmp-carousel__title"
                  dangerouslySetInnerHTML={{ __html: texttitle }}
                />
              )}
              {description && (
                <div
                  className="cmp-carousel__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {ctaLable && (
                <div className="cmp-carousel__action-container">
                  <a
                    dangerouslySetInnerHTML={{ __html: ctaLable }}
                    href={link}
                    className="cmp-carousel__action-link"
                    target={`${
                      convertStringToBoolean(isNewTab) ? "_blank" : "_self"
                    }`}
                  ></a>
                </div>
              )}
              {convertStringToBoolean(actionsEnabled) && (
                <div className="cmp-carousel__action-container">
                  {!convertStringToBoolean(linkAll) && link && (
                    <a
                      href={link}
                      className="cmp-carousel__action-link"
                      target={`${
                        convertStringToBoolean(isNewTab) ? "_blank" : "_self"
                      }`}
                      data-gbh-data-layer={getDataLayerValues(
                        isSsr,
                        pageName,
                        link,
                        sanitizeTextArticle(texttitle),
                        sanitizeTextArticle(text)
                      )}
                    >
                      {sanitizeInnerHtml(text)}
                    </a>
                  )}
                  {(convertStringToBoolean(linkAll) || !link) && (
                    <div
                      className="cmp-carousel__action-link"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(text),
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            <Slider
              key={`${sliderRerenderKey}`}
              {...slickSettings}
              className={`cmp-carousel-slick__content`}
              ref={sliderRef}
            >
              {itemsOrder &&
                itemsOrder?.map((key, index, arr) => {
                  const item = items[key]
                  return (
                    <div
                      key={
                        item?.id
                          ? item?.id
                          : `cpm-carousel-${index}-${getRandom()}`
                      }
                    >
                      {isSingleSlider ||
                      isLargeCollection ||
                      (isDoubleFeatureContentCard && !isSsr) ? (
                        <ImageV1 data={item} />
                      ) : null}
                      {isSingleItemsSlider ||
                      isSingleSlider ||
                      isSingleSlideCarousel ||
                      isFeatureContentSlider ||
                      isInspiredThemeCarouselSlider ||
                      isContentThreeUp ||
                      isContentFourUp ||
                      isContentFiveUp ||
                      isProductSlider ||
                      isStickySlider ||
                      isFeatureContent ||
                      isFullbleedHover? (
                        <Teaser
                          data={item}
                          showEmptyPlaceholder={showSlideIndicators}
                          maxHeight={sliderImageHeight}
                        />
                      ) : null}
                      {isProduct || isProductcard && !isProductSlider ? (<ProductCard
                      data={item}
                      showEmptyPlaceholder={showSlideIndicators}
                      maxHeight={sliderImageHeight}
                    />) : null}
                  </div>
                  )
                })}
            </Slider>
            {/* {showSlideIndicators && !isDoubleFeatureContentCard ? (
              <div
                className="slider__counter"
                style={{ top: countHeight + "px" }}
              >
                <span className="current-count">{activeSlide}</span>/
                <span className="total-count">
                  {data["carouselComponentProperties"] &&
                    data["carouselComponentProperties"][":itemsOrder"]?.length}
                </span>
              </div>
            ) : null} */}
          </>
        </ConditionalWrapper>
      </div>
      {/* <div className="hide">{showSlideIndicators}</div> */}
    </>
  )
}

export default CarouselSlick
