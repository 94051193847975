import React from 'react';
import cx from 'classnames';
import upArrow from '@/public/icons/back-top-arrow.svg';

const BackToTop=({ text = '', customClass = '', analyticsData = () => {} })=> {
  const handleBackToTop = (event) => {
    if (event.keyCode === 13) {
      document.getElementsByClassName('cmp-image--link')[1]?.focus()
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <button
      className={cx('back-to-top', 'gbh-data-layer', customClass ?? null)}
      onClick={handleBackToTop}
      onKeyDown={handleBackToTop}
      data-gbh-data-layer={analyticsData}
    >
      <span 
      className={cx('back-to-top-text')}
      >{text}</span>
      <img role="presentation" src={upArrow?.src} alt={text} className={cx("back-to-top-image")} />
    </button>
  );
}

export default BackToTop