import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import DOMPurify from "isomorphic-dompurify"
import styles from "@/components/Shared/index.module.scss"
const AdditionalFooter = props => {
  const { data, columnClassNames } = props
  const additionalFooterClassName = columnClassNames || ""
  const router = useRouter()
  const [location, setLocation] = useState("")
  const [docTitle, setDocTitle] = useState("")

  useEffect(() => {
    const sanitizedLocation = DOMPurify.sanitize(window.location.href)
    setLocation(sanitizedLocation)
    setDocTitle(document.title)
  }, [router.pathname])
  // To resolve hydration error added the below code
  // This will be true only on the client-side after hydration
  const isClient = typeof window !== 'undefined';
  return (
    <div
      className={`${additionalFooterClassName} ${styles.additionalFooterWrapper}`}
    >
      <div className="additional-footer">
        <footer>
          <section className="gbh-footer-add-nav">
            <section className="gbh-container">
              <section className="gbh-footer-add-panel">
                <ul className="gbh-footer-nav__links">
                  {data?.items &&
                    data?.items.map((item, index) => {
                      const additionalfooteritem = `additionalfooteritem-${index}`
                      return (
                        <li key={additionalfooteritem}>
                          <a
                            id={item?.idLabel}
                            className={isClient?item?.classLabel:''}
                            aria-label={item.linkLabel}
                            target={item.newTab ? "_blank" : "_self"}
                            href={item.link
                              ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
                              .join("")
                              .replace(".html", "")}
                            rel="noreferrer"
                            data-gbh-data-layer={`{"clickInternalLinks":"${
                              item?.newTab ? "false" : "true"
                            }","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${item?.link
                              ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
                              .join("")
                              .replace(
                                ".html",
                                ""
                              )}","internalLinkName":"${item?.linkLabel?.toLowerCase()}","eventName":"global footer:${item?.linkLabel?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer menu-2","eventAction":"global footer:${item?.linkLabel?.toLowerCase()}"}`}
                          >
                            {item.linkLabel}
                          </a>
                        </li>
                      )
                    })}
                </ul>
                <div className="social-link-wrapper">
                  <ul className="gbh-social__links">
                    {data?.email == "email" && (
                      <li className="gbh-social__email">
                        <a
                          target="_blank"
                          className="gbh-data-layer"
                          aria-label="Email corporate page link opens in a new tab"
                          href={`mailto:?body=${location}&subject=${docTitle}`}
                          rel="noreferrer noopener"
                          title={data.linkLabelEmail}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkEmail
                          }","internalLinkName":"social media:${data?.linkLabelEmail?.toLowerCase()}","eventName":"social media:${data?.linkLabelEmail?.toLowerCase()}","internalLinkType":"global footer:"navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelEmail?.toLowerCase()}"}`}
                        ></a>
                      </li>
                    )}
                    {data?.facebook == "facebook" && (
                      <li className="gbh-social__facebook">
                        <a
                          target="_blank"
                          aria-label="Facebook corporate page link opens in a new tab"
                          href={data.linkFacebook}
                          rel="noreferrer noopener"
                          title={data?.linkLabelFacebook}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkFacebook
                          }","internalLinkName":"social media:${data?.linkLabelFacebook?.toLowerCase()}","eventName":"social media:${data?.linkLabelFacebook?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelFacebook?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.instagram == "instagram" && (
                      <li className="gbh-social__insta">
                        <a
                          target="_blank"
                          aria-label="Instagram corporate page link opens in a new tab"
                          href={data.linkInstagram}
                          rel="noreferrer noopener"
                          title={data.linkLabelInstagram}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkInstagram
                          }","internalLinkName":"social media:${data?.linkLabelInstagram?.toLowerCase()}","eventName":"social media:${data?.linkLabelInstagram?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelInstagram?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.twitter == "twitter" && (
                      <li className="gbh-social__twitter">
                        <a
                          target="_blank"
                          aria-label="Twitter corporate page link opens in a new tab"
                          href={data.linkTwitter}
                          rel="noreferrer noopener"
                          title={data.linkLabelTwitter}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkTwitter
                          }","internalLinkName":"social media:${data?.linkLabelTwitter?.toLowerCase()}","eventName":"social media:${data?.linkLabelTwitter?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelTwitter?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.pinterest == "pinterest" && (
                      <li className="gbh-social__pintrest">
                        <a
                          target="_blank"
                          aria-label="Pinterest corporate page link opens in a new tab"
                          href={data.linkPinterest}
                          rel="noreferrer noopener"
                          title={data.linkLabelPinterest}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkPinterest
                          }","internalLinkName":"social media:${data?.linkLabelPinterest?.toLowerCase()}","eventName":"social media:${data?.linkLabelPinterest?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelPinterest?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.youtube == "youtube" && (
                      <li className="gbh-social__youtube">
                        <a
                          target="_blank"
                          aria-label="Youtube corporate page link opens in a new tab"
                          href={data.linkYoutube}
                          rel="noreferrer noopener"
                          title={data.linkLabelYoutube}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkYoutube
                          }","internalLinkName":"social media:${data?.linkLabelYoutube?.toLowerCase()}","eventName":"social media:${data?.linkLabelYoutube?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelYoutube?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.houzz == "houzz" && (
                      <li className="gbh-social__houzz">
                        <a
                          target="_blank"
                          aria-label="Houzz corporate page link opens in a new tab"
                          href={data.linkHouzz}
                          rel="noreferrer noopener"
                          title={data.linkLabelHouzz}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkHouzz
                          }","internalLinkName":"social media:${data?.linkLabelHouzz?.toLowerCase()}","eventName":"social media:${data?.linkLabelHouzz?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelHouzz?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.linkedin == "linkedin" && (
                      <li className="gbh-social__linkedin">
                        <a
                          target="_blank"
                          aria-label="Linkedin corporate page link opens in a new tab"
                          href={data.linkLinkedin}
                          rel="noreferrer noopener"
                          title={data.linkLabelLinkedin}
                          data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                            data?.linkLinkedin
                          }","internalLinkName":"social media:${data?.linkLabelLinkedin?.toLowerCase()}","eventName":"social media:${data?.linkLabelLinkedin?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelLinkedin?.toLowerCase()}"}`}
                          className="gbh-data-layer"
                        ></a>
                      </li>
                    )}
                    {data?.tiktok == "tiktok" && (
                       <li className="gbh-social__tiktok">
                       <a
                         target="_blank"
                         aria-label="Tiktok corporate page link opens in a new tab"
                         href={data.linkTiktok}
                         rel="noreferrer noopener"
                        title={data.linkLabelTiktok}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkTiktok
                        }","internalLinkName":"social media:${data?.linkLabelTiktok?.toLowerCase()}","eventName":"social media:${data?.linkLabelTiktok?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media:${data?.linkLabelTiktok?.toLowerCase()}"}`}
                        className="gbh-data-layer"
                        ></a>
                      </li>
                      )}
                  </ul>
                  <p className="gbh-copy__rights">{data.copyright}</p>
                </div>
              </section>
            </section>
          </section>
        </footer>
      </div>
    </div>
  )
}

export default AdditionalFooter
