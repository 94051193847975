import React, { useState,useRef } from "react";
import { withTranslation } from "react-i18next";
import htmlParser from "html-react-parser";

const PDPDetailsTab = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const refsArray  =  props.data.tabDetails.map(() => useRef(null));
  const tabNavRef = useRef(null);
  const onTab = (index) => {
    setActiveIndex(index);
    const element = refsArray[index]
    tabNavRef.current.scrollTo({left:element.current.offsetLeft - 18,behavior: 'smooth'})
  };
  if (props.data.tabDetails && props.data.tabDetails.length > 0) {
    return (
      <div className="container responsivegrid">
        <div className="cmp-container">
          <div className="manualPdpTabs">
            <div className="asproducttabdetail">
              <div className="page-container">
                <div
                  className="wrapper"
                  id="product-details-tab-details"
                  total={props.data.tabDetails.length}
                >
                  <div className="wrapper__tab-devider"></div>
                  <div className="tabWrapper">
                    <div className="tabNav">
                      <ul ref={tabNavRef}>
                        {props.data.tabDetails.map((item, index) => {
                          let active = activeIndex === index;
                          return (
                            <li
                              ref={refsArray[index]}
                              key={"li-2-" + index}
                              className={active ? "active" : ""}
                              onClick={() => onTab(index)}
                              tabIndex="0"
                            >
                              {item.tabTitle}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="tabContent">
                      <div className="width100">
                        <div>
                          {htmlParser(
                            props.data.tabDetails[activeIndex][
                              "tabDescription"
                            ],
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default withTranslation()(PDPDetailsTab);
