import React, { Fragment } from "react"
import Button from "@/components/core/Button/Button"
import htmlParser from "html-react-parser"

const ProductSummary = props => {
  const productSummaryData = props.productSummaryDetails
  const navigateCTA = (url, newTab) => {
    if (newTab) {
      window.open(url, "_blank", "noopener,noreferrer")
    } else {
      window.location = url
    }
  }
  return (
    <Fragment>
      <div className="col-12 product-summary__product-summary-block p-0">
        <div className="product-summary" id="product-summary-wrapper">
          <div className="product-summary__title">
            {productSummaryData?.eyebrowText}
          </div>
          <div className="product-summary__desc">
            <div className="product-summary__desc">
              {htmlParser(productSummaryData.title)}
            </div>
          </div>
          <div className="product-summary__details">
            {htmlParser(productSummaryData?.description)}
          </div>
          {/* Add Contact us */}
          <Button
            onClick={() =>
              navigateCTA(productSummaryData.ctaLink, productSummaryData.newTab)
            }
            type="ghost"
            role="button"
            size="small"
            label={productSummaryData?.ctaLabel}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ProductSummary
