import React, { useEffect, useState } from "react"
import { getShortenedUrl, sanitizeInnerHtml } from "@/utils/helper"
const Text = props => {
  const [dataVal, setDataVal] = useState("")

  const {
    data: {
      text = "",
      appliedCssClassNames = "",
      richText = false,
      id = "",
    } = {},
  } = props
  const removeHtmlExtension = async () => {
    const newData = await getShortenedUrl(text)
    setDataVal(newData)
  }
  useEffect(() => {
    if (richText) {
      removeHtmlExtension()
    }
  }, [richText])

  return (
    <div className={`text ${appliedCssClassNames ? appliedCssClassNames : ""}`}>
      {richText ? (
        <div id={id} className="cmp-text">
          {sanitizeInnerHtml(dataVal)}
        </div>
      ) : (
        <div className="cmp-text">
          <p className="cmp-text__paragraph" aria-label={text}>
            {text}
          </p>
        </div>
      )}
    </div>
  )
}

export default Text
