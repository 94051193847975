import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { isMobile } from "react-device-detect"
import { selectGenericState } from "@/store/features/genericSlice"
import HeaderNavTabs from "@/shared/HeaderNavTabs"
import Teaser from "@/components/Default/Teaser/v2/Teaser"
import AemGrid from "../AemGrid"
import { staticClasses } from "@/constants"
import {
  ConditionalWrapper,
  isContainer,
  isTab
} from "@/utils"

const Tab = props => {
  const {
    menu: { isOpen },
  } = useSelector(selectGenericState)
  const { data = {}, itemKey = "", ...extraProps } = props
  const [selectedTab, setTab] = useState(isOpen && "")
  const [isHeaderTab, setisHeaderTab] = useState(false)
  const tabElement = useRef()
  const onClickTab = data => {
    props?.addHeaderNavTabAnalytics(data)
    if (selectedTab !== data["cq:panelTitle"]) {
      setTab(data["cq:panelTitle"])
    } else setTab("")
    const buttonStyle1 = document.querySelector(".button-format.cmp-container")
    if (buttonStyle1) {
      buttonStyle1.style.position = "unset"
      buttonStyle1.style.padding = "unset"
    }
    document
      .querySelector(
        ".ann-sacks-main-menu.ann-sacks-tab-bar >.cmp-container:first-child"
      )
    if (
      document.querySelector(".gbh-pro-menu__inner.icon-Close-Medium") ===
        null &&
      window.matchMedia("(max-width:1200px)").matches
    ) {
      document.getElementById("main-scroll-close").style.display = "none"
    }
  }
  useEffect(()=>{
    if(props.data.appliedCssClassNames==="ann-main-nav"){
      setisHeaderTab(true);
    }
  })
  useEffect(()=> {
    if( props.data.appliedCssClassNames === "vertical-tabs" && data[":itemsOrder"] && data[":itemsOrder"][0]){
      let tabData = data[":items"][data[":itemsOrder"][0]]
      if (selectedTab !== tabData["cq:panelTitle"]) {
        setTab(tabData["cq:panelTitle"])
      } else setTab("")
    }
      
  },[data])
  if(isHeaderTab){
  return (
    <div className={`tabs ${props.data.appliedCssClassNames ?? ""} ${isOpen ? "open" : ""} `}>
      <div className="cmp-tabs">
        <ol
          role="tablist"
          className="cmp-tabs__tablist"
          aria-multiselectable="false"
          ref={tabElement}
        >
          {data[":itemsOrder"] &&
            data[":itemsOrder"].map((key, index) => {
              const item = data[":items"][key]
              return (
                <li
                  role="tab"
                  className={`cmp-tabs__tab ${
                    selectedTab == item["cq:panelTitle"]
                      ? "cmp-tabs__tab--active"
                      : ""
                  }`}
                  tabIndex="0"
                  key={item["cq:panelTitle"]}
                  id={item["cq:panelTitle"]}
                  onClick={() => onClickTab(item)}
                >
                  {item["cq:panelTitle"]}
                </li>
              )
            })}
        </ol>
        {data[":itemsOrder"] &&
          (selectedTab?.length > 0 || !isMobile) &&
          data[":itemsOrder"].map((key, index) => {
            const item = data[":items"][key]
            return (
              <div
                role="tabpanel"
                className={`cmp-tabs__tabpanel ${
                  selectedTab == item["cq:panelTitle"]
                    ? "cmp-tabs__tabpanel--active"
                    : ""
                }`}
                aria-label={item["cq:panelTitle"]}
                key={`navItem-${index}`}
              >
                  <HeaderNavTabs
                    selectedTab={selectedTab}
                    setTab={setTab}
                    data={item}
                    isTabOpen={selectedTab == item["cq:panelTitle"]}
                    toggleMenu={() => {}}
                    eventHandler={{
                      store: {
                        isOpen,
                      },
                    }}/>
              </div>
            )
          })}
      </div>
    </div>
  )}
  else{
    return (
      <div className={`tabs ${props.data.appliedCssClassNames ?? ""}`}>
        <div className="cmp-tabs">
          <ol
            role="tablist"
            className="cmp-tabs__tablist"
            aria-multiselectable="false"
            ref={tabElement}
          >
            {data[":itemsOrder"] &&
              data[":itemsOrder"].map((key, index) => {
                const item = data[":items"][key]
                const { id = "" } = item
                return (
                  <li
                    role="tab"
                    className={`cmp-tabs__tab ${
                      selectedTab == item["cq:panelTitle"]
                        ? "cmp-tabs__tab--active"
                        : ""
                    }`}
                    tabIndex="0"
                    key={item["cq:panelTitle"]}
                    id={item["cq:panelTitle"]}
                    onClick={() => onClickTab(item)}
                  >
                    {item["cq:panelTitle"]}
                  </li>
                )
              })}
          </ol>
          {data[":itemsOrder"] &&
            (selectedTab?.length > 0 || !isMobile) &&
            data[":itemsOrder"].map((key, index) => {
              const item = data[":items"][key]
              const { id = "" } = item
              // set for rerendering child component when nested tabs are present for recalculating the active indicator
              const hasNestedTab = item[":itemsOrder"]?.some(childKey => {
                const obj = item[":items"][childKey]
                return isTab(obj)
              })
              return (
                <div
                  role="tabpanel"
                  className={`cmp-tabs__tabpanel ${
                    selectedTab == item["cq:panelTitle"]
                      ? "cmp-tabs__tabpanel--active"
                      : ""
                  }`}
                  aria-label={item["cq:panelTitle"]}
                  key={`navItem-${index}`}
                >
                  <ConditionalWrapper
                      condition={isContainer(item)}
                      wrapper={children => (
                        <div
                          className={`${isMobile ? `${
                    selectedTab == item["cq:panelTitle"]
                      ? "cmp-tabs__tabpanel--active"
                      : ""
                  }`: `${
                            isContainer(item) ? staticClasses?.CONTAINER : ""
                          } ${item?.appliedCssClassNames}` } `}
                        >
                          <div className="cmp-container">
                            {item?.gridClassNames ? (
                              <div className={item?.gridClassNames}>
                                {children}
                              </div>
                            ) : (
                              { children }
                            )}
                          </div>
                        </div>
                      )}
                    >
                      <AemGrid
                        {...(hasNestedTab ? { key: selectedTab } : {})}
                        data={item}
                        containerId={id}
                        itemKey={key ? key : itemKey}
                        {...extraProps}
                      />
                    </ConditionalWrapper>
                  {/* <AemGrid
                        {...(hasNestedTab ? { key: selectedTab } : {})}
                        data={item}
                        containerId={id}
                        itemKey={key ? key : itemKey}
                        {...extraProps}
                      /> */}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

export default Tab
