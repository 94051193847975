import React, { useCallback, useEffect, useRef, useState } from "react"
import { playVideoDatalayer } from "@/components/Default/Video/videoAnalytics"
import DOMPurify from "isomorphic-dompurify"
import useScreenOrientation from "@/hooks/useScreenOrientation"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import {
  convertStringToBoolean,
  createIdFromString,
  getRandom,
  sanitizeInnerHtml,
} from "@/utils/helper"
import Action from "@/components/Default/Teaser/v1/Action"
import Description from "@/components/Default/Teaser/v1/Description"
import { useSelector } from "react-redux"
import { selectGenericState } from "@/store/features/genericSlice"
import { useInView } from "react-intersection-observer"
import { VIDEO_EVENTS } from "@/constants/index"
import { getConfig } from "@/constants/config"
import {
  onClickHeroVideo,
  onFavVideo,
  onPlayVideoInModal,
  onShareVideo,
  onPlayVideo,
  onVideoInteraction,
  onVolumeChange,
  onVideoPopupClose,
  onVideoMute,
} from "@/utils/videoHelper"
import SocialShare from "@/components/cart/OrderSummary/SocialShare"
import { getShortenedUrl } from "@/utils/location"

const Video = props => {
  const {
    data: {
      videoTitle = "",
      videoMobile = "",
      videoMobileLandscape = "",
      videoMobilePoster = "",
      videoDesktopPoster = "",
      videoDesktop = "",
      muteEnableAutoPlay = "false",
      hideEnableAutoPlay = "false",
      alt = "",
      appliedCssClassNames = "",
      items = [],
      teaserProperties: {
        title = "",
        description = "",
        linkURLRef = false,
        linkCTA = "",
        linkNewTab = false,
        videoPosterMobileLandscape = "",
        controlType = "",
        pageType = "",
        componentPosition = "",
        componentZone = "",
        componentLinkType = "",
        playsAboveFold = "false",
        eventName = "",
        dynamicTabName = "",
        eventAction = "",
        caption = "",
        pretitle = "",
        actionsEnabled = false,
        actions = [],
        linkAll = false,
        dataLayerLinkType = "",
        mainTitle = "",
        enableFav = false,
        enableShare = false,
        linkDescription = false,
        propertiesPageType = "",
      } = {},
    } = {},
  } = props

  const {
    eventPageType,
    toaster: { content },
  } = useSelector(selectGenericState)

  const { winWidth } = useWindowDimensions()
  const screenOrientation = useScreenOrientation()
  const [video, setVideo] = useState("")
  const [dynamicId, setDynamicId] = useState("")
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [showSocialShare, setShowSocialShare] = useState(false)
  const [interactionState, setInteractionState] = useState("")

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
  })

  const videoContainerRef = useRef(null)
  const videoRef = useRef(null)
  const muteIconRef = useRef(null)
  const favEle = useRef(null)
  const [general, setGeneral] = useState(null)
  const [toasterContent, setToasterContent] = useState({
    successMessage: "",
    unfavoriteSuccessMessage: "",
  })
  const [brandName, setBrandName] = useState(null)

  const [flagState, setFlagState] = useState({
    flag25: false,
    flag75: false,
    flag50: false,
  })

  const getVideoId = video => {
    return decodeURI(video)?.split("PAWEB/")[1]?.toLowerCase()
  }
  useEffect(() => {
    getConfig().then(config => {
      setGeneral(config?.general)
      setBrandName(config?.general?.lwAppName)
    })
  }, [])
  useEffect(() => {
    if (videoTitle) {
      const dynamicId = createIdFromString(videoTitle)
      setDynamicId(dynamicId)
    }
  }, [videoTitle])

  useEffect(() => {
    if (content) {
      setToasterMessage()
    }
  }, [content])

  const onTimeUpdate = event => {
    const videoDuration = videoRef.current.duration
    const percent25 = Math.floor(videoDuration * 0.25)
    const percent50 = Math.floor(videoDuration * 0.5)
    const percent75 = Math.floor(videoDuration * 0.75)
    const progressTime = Math.floor(videoRef.current.currentTime)

    if (percent75 <= progressTime && flagState.flag75 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag75: true,
      }))
      addVideoDataLayer(75)
    } else if (percent50 <= progressTime && flagState.flag50 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag50: true,
      }))

      addVideoDataLayer(50)
    } else if (percent25 <= progressTime && flagState.flag25 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag25: true,
      }))

      addVideoDataLayer(25)
    }
  }
  const addVideoDataLayer = percentViewed => {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${eventPageType?.toLowerCase()}:${percentViewed}% video viewed`,
      eventMsg: "n/a",
      eventName: `${eventPageType?.toLowerCase()}:${percentViewed}% video viewed`,
      eventStatus: "n/a",
      eventType: "navigation",
      internalLinkName: `${percentViewed}% video viewed`,
      internalLinkPosition: `${eventPageType?.toLowerCase()}:${
        componentPosition || "teaser"
      }`,
      internalLinkType: `${eventPageType?.toLowerCase()}:${
        componentPosition || "teaser"
      }`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${eventPageType?.toLowerCase()}`,
      videoId: `${getVideoId(video)}`,
      videoName: `${
        videoTitle || componentLinkType.toLowerCase() || "hero video"
      }`,
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
    })
  }
  const setToasterMessage = async () => {
    const { successMessage, unfavoriteSuccessMessage } = content
    setToasterContent({
      successMessage: await getShortenedUrl(successMessage),
      unfavoriteSuccessMessage: await getShortenedUrl(unfavoriteSuccessMessage),
    })
  }

  const setVideoSource = useCallback(() => {
    if (winWidth > 1023) {
      setVideo(videoDesktop)
    } else {
      if (screenOrientation?.includes("landscape")) {
        setVideo(videoMobileLandscape)
      } else {
        setVideo(videoMobile)
      }
    }
  }, [
    videoMobile,
    videoMobileLandscape,
    videoDesktop,
    screenOrientation,
    winWidth,
  ])

  useEffect(() => {
    if (inView) {
      setVideoSource()
      if (
        convertStringToBoolean(muteEnableAutoPlay) ||
        (videoPlayed && interactionState === VIDEO_EVENTS.PLAY)
      ) {
        videoRef?.current?.play()
      }
    } else {
      videoRef?.current?.pause()
    }
  }, [setVideoSource, inView])

  useEffect(() => {
    if (isVideoPlaying) setVideoPlayed(true)
  }, [isVideoPlaying])

  const eventActionTabName = dynamicTabName || "zoneForVideo"

  const eventActionName = `${dynamicTabName || "rm"}:video:rm`

  const [favVideoId, setFavVideoId] = useState("")

  const analyticEventData = {
    clickInternalLinks: true,
    internalLinkPosition: `${eventPageType?.toLowerCase()}:${
      componentPosition || "teaser"
    }`,
    internalLinkZoneName: `${eventPageType?.toLowerCase()}:${
      componentZone || "teaser"
    }`,
    internalLinkURL: "n/a",
    internalLinkName: "n/a",
    eventName: `${eventPageType?.toLowerCase()}:share`,
    internalLinkType: `${eventPageType?.toLowerCase()}:share`,
    eventType: "informative",
    eventAction: `${eventPageType?.toLowerCase()}:share`,
    videoName: `${
      videoTitle || componentLinkType.toLowerCase() || "hero video"
    }`,
    videoId: `${getVideoId(video)}`,
  }
  const checkVideoTitle =
    videoTitle || componentLinkType.toLowerCase() || "hero video"
  return (
    <section className={`video teaser ${appliedCssClassNames}`} ref={inViewRef}>
      <div
        className={`cmp-teaser${
          controlType == "customControl" ? " gbh-custom-control" : ""
        }${propertiesPageType == "modalWindow" ? " gbh-model-window" : ""}${
          convertStringToBoolean(hideEnableAutoPlay) ? " gbh-hide-mute" : ""
        }`}
        ref={videoContainerRef}
      >
        <div
          className={`cmp-teaser__image video_poster_height${
            videoPlayed ? " video-played" : ""
          }`}
          data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${eventPageType?.toLowerCase()}:${
            componentPosition || "teaser"
          }","internalLinkZoneName":"${eventPageType?.toLowerCase()}:'hero banner' ","internalLinkURL":"n/a","internalLinkName":"video play","eventName":"${eventPageType?.toLowerCase()}:${eventActionTabName}",
             "internalLinkType":"${eventPageType?.toLowerCase()}:${
            videoTitle
              ? videoTitle
              : componentLinkType.toLowerCase() || "teaser"
          }","eventType":"navigation","eventAction":"${eventPageType?.toLowerCase()}:${eventActionTabName}","videoName":"${
            videoTitle || componentLinkType.toLowerCase() || "hero video"
          }"}`}
          style={showSocialShare ? { overflow: "visible" } : {}}
        >
          <span
            className={`gbh-play-video desktop${
              isVideoPlaying ? " active" : ""
            }`}
            onClick={event =>
              onPlayVideoInModal(
                event,
                propertiesPageType,
                videoContainerRef?.current,
                videoRef?.current
              )
            }
          >
            <button
              className="playinside desktop"
              id={`videoinnerbtn-${dynamicId}`}
              aria-label="play video"
              onClick={() =>
                onPlayVideo(
                  videoRef?.current,
                  res => {
                    setIsVideoPlaying(res)
                    setInteractionState(
                      res ? VIDEO_EVENTS.PAUSE : VIDEO_EVENTS.PLAY
                    )
                  },
                  videoDesktopPoster,
                  videoMobilePoster,
                  video,
                  isVideoPlaying
                )
              }
            />
          </span>

          <picture className="poster-img">
            <source media="(min-width:1025)" srcSet={`${videoDesktopPoster}`} />
            <source
              media="(min-width:640px) and (max-width:1024px)"
              srcSet={`${videoPosterMobileLandscape}`}
            />
            <source media="(max-width:639px)" srcSet={`${videoMobilePoster}`} />
            <img
              src={`${videoDesktopPoster}`}
              alt=""
              className="desktop-poster"
            />
          </picture>

          {video && (
            <video
              ref={videoRef}
              id={`video-desktop-${dynamicId}`}
              width="100%"
              muted={convertStringToBoolean(muteEnableAutoPlay)}
              autoPlay={convertStringToBoolean(muteEnableAutoPlay)}
              loop={convertStringToBoolean(muteEnableAutoPlay)}
              playsInline
              preload="metadata"
              onLoadedData={() => {
                convertStringToBoolean(muteEnableAutoPlay)
                  ? playVideoDatalayer(videoRef?.current, video, id =>
                      setFavVideoId(id)
                    )
                  : {}
              }}
              className={`hero-video videoelement${
                convertStringToBoolean(muteEnableAutoPlay) ? " muted" : ""
              }${convertStringToBoolean(playsAboveFold) ? "" : " lazy-video"}`}
              onVolumeChange={() => onVolumeChange(videoRef?.current)}
              onClick={event => onClickHeroVideo(event, videoRef?.current)}
              {...(controlType === "defaultControl" &&
              !convertStringToBoolean(hideEnableAutoPlay)
                ? { controls: true }
                : {})}
              onPlay={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.PLAY,
                  () => setVideoPlayed(true),
                  res => setIsVideoPlaying(res),
                  videoRef?.current,
                  video,
                  convertStringToBoolean(muteEnableAutoPlay),
                  id => setFavVideoId(id)
                )
              }
              onPause={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.PAUSE,
                  () => setVideoPlayed(true),
                  res =>setIsVideoPlaying(brandName === "annsacks" ? true : res),
                  videoRef?.current,
                  video,
                  convertStringToBoolean(muteEnableAutoPlay)
                )
              }
              onEnded={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.ENDED,
                  () => setVideoPlayed(true),
                  res => setIsVideoPlaying(res),
                  videoRef?.current,
                  video,
                  convertStringToBoolean(muteEnableAutoPlay)
                )
              }
              onTimeUpdate={onTimeUpdate}
            >
              <source src={video} type="video/mp4" />
              {items.map((transcript, index) => (
                <track
                  key={`video-track-${dynamicId}-${index}`}
                  label={transcript?.languageLabel}
                  kind="subtitles"
                  srcLang={transcript?.languageCode}
                  src={transcript?.transcriptSrcPath}
                  default={items?.length ? true : false}
                />
              ))}
            </video>
          )}

          <div
            className="cmp-img-caption"
            data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${eventPageType?.toLowerCase()}:${
              componentPosition || "teaser"
            }","internalLinkZoneName":"${eventPageType?.toLowerCase()}:${
              componentZone || "teaser"
            }","internalLinkURL":"n/a","internalLinkName":"n/a","eventName":"${eventPageType?.toLowerCase()}:${
              eventName || eventActionName
            }","internalLinkType":"${eventPageType?.toLowerCase()}:${
              videoTitle || componentLinkType || "teaser"
            }","eventType":"navigation","eventAction":"${eventPageType?.toLowerCase()}:${
              eventAction || eventActionName
            }"}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(caption),
            }}
          />
          <div
            className="vidio_popup_close_button"
            onClick={() =>
              onVideoPopupClose(
                videoContainerRef?.current,
                videoRef?.current,
                video
              )
            }
          >
            <button role="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <span
            ref={muteIconRef}
            className="hero-mute-icon"
            onClick={() => onVideoMute(muteIconRef?.current, videoRef?.current)}
          >
            {videoRef?.current?.muted ? (
              <i className="icon-Un-Mute"></i>
            ) : (
              <i className="icon-Mute"></i>
            )}
          </span>
        </div>

        <div className="cmp-teaser__content">
          {pretitle && (
            <div className="cmp-teaser__pretitle">
              {sanitizeInnerHtml(pretitle)}
            </div>
          )}
          {title && (
            <div className="cmp-teaser__title">{sanitizeInnerHtml(title)}</div>
          )}
          {description && (
            <Description
              general={general}
              linkURL={linkCTA}
              linkURLRef={linkURLRef}
              linkNewTab={linkNewTab}
              description={description}
              mainTitle={mainTitle}
              componentPosition={componentPosition}
              componentLinkType={componentLinkType}
              pageType={pageType}
              linkDescription={linkDescription}
            />
          )}
          {actionsEnabled && (
            <div className="cmp-teaser__action-container">
              {actions.map((action, index) => {
                const {
                  playVideoCTA = false,
                  primaryCTA = false,
                  secondaryCTA = false,
                  url = "",
                  title = "",
                  newTab = "",
                  urlRef = "",
                } = action
                return (
                  <Action
                    key={`video-action-${index + 1}-${getRandom()}`}
                    primaryCTA={convertStringToBoolean(primaryCTA)}
                    secondaryCTA={convertStringToBoolean(secondaryCTA)}
                    playVideoCTA={convertStringToBoolean(playVideoCTA)}
                    link={url}
                    text={title}
                    newTab={convertStringToBoolean(newTab)}
                    urlRef={urlRef}
                    id={dynamicId}
                    pageType={pageType}
                    videoTitle={videoTitle}
                    linkAll={convertStringToBoolean(linkAll)}
                    mainTitle={mainTitle}
                    dataLayerLinkType={dataLayerLinkType}
                    eventName={eventName}
                    eventAction={eventAction}
                    componentPosition={componentPosition}
                    propertiesPageType={propertiesPageType}
                    playVideoCTAcb={event =>
                      onPlayVideoInModal(
                        event,
                        propertiesPageType,
                        videoContainerRef?.current,
                        videoRef?.current
                      )
                    }
                  />
                )
              })}
            </div>
          )}
          {/* Enable favorite and share button on video */}
          {(enableFav || enableShare) && (
            <div className="cmp-teaser__icons">
              {enableFav && (
                <div
                  ref={favEle}
                  className="favorite fav-video-trigger"
                  tabIndex="0"
                  data-asset={videoDesktop}
                  data-url={videoDesktop}
                  onClick={event =>
                    onFavVideo(
                      event,
                      videoDesktop,
                      alt,
                      componentLinkType || caption,
                      videoTitle ||
                        componentLinkType.toLowerCase() ||
                        "hero video",
                      checkVideoTitle,
                      favVideoId,
                      favEle,
                      toasterContent
                    )
                  }
                />
              )}
              {enableShare && (
                <div
                  className="share share-video-trigger"
                  tabIndex="0"
                  aria-label="share"
                  onClick={e =>
                    onShareVideo(
                      e,
                      () => setShowSocialShare(!showSocialShare),
                      eventPageType,
                      componentZone,
                      componentLinkType,
                      videoTitle
                    )
                  }
                >
                  {showSocialShare && (
                    <div className="share-cmp" style={{ display: "block" }}>
                      <SocialShare
                        analyticEventData={analyticEventData}
                        componentProp={{ assetUrl: videoDesktop }}
                        enableDownload={false}
                        videoComp={true}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default React.memo(Video)
