import { useEffect, useCallback, useState } from "react"
import ProductsNavTab from "@/shared/ProductsNavTab"
import { isMobile } from "react-device-detect"
import { useSelector } from "react-redux"
import { selectGenericState } from "@/store/features/genericSlice"

const HeaderNavTabs = props => {
  const {
    data,
    isTabOpen,
    eventHandler,
    eventHandler: {
      store: { isOpen },
    },
    selectedTab,
    setTab,
  } = props

  const { pageIsInteractive, datalayerPageName } =
    useSelector(selectGenericState)

  const [productNavigationItems, setProductNavigationItems] = useState(null)
  const [hideMobImages, setHideMobImages] = useState(false)
  const [navOpen, setNavOpen] = useState("")

  const [showMobileImages, setShowMobileImages] = useState(true)
  const dataRecursion = useCallback(data => {
    const { mainNavigation, categoryItems, hideMobileImages } = data
    if (data && mainNavigation === "Products") {
      setProductNavigationItems(categoryItems)
      setHideMobImages(hideMobileImages)
    }
    if (data && data[":itemsOrder"]) {
      data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        return dataRecursion(item)
      })
    }
  }, [])

  useEffect(() => {
    if (isMobile && hideMobImages) {
      setShowMobileImages(false)
    } else {
      setShowMobileImages(true)
    }
  }, [hideMobImages, isMobile])

  useEffect(() => {
    dataRecursion(data)
  }, [data, dataRecursion])

  return (
    <>
      {pageIsInteractive && (
        <ProductsNavTab
          productNavigationItems={productNavigationItems}
          navOpen={navOpen}
          setNavOpen={data => setNavOpen(data)}
          isOpen={isOpen}
          eventHandler={eventHandler}
          datalayerPageName={datalayerPageName}
          selectedTab={selectedTab}
          hideMobImages={hideMobImages}
          showMobileImages={showMobileImages}
          data={data}
          isTabOpen={isTabOpen}
          setTab={setTab}
        />
      )}
    </>
  )
}

export default HeaderNavTabs
