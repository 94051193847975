import { sanitizeTextForAnalytics } from "@/utils/helper"

const bloglistingEventInfo = (category = '', name = '', linkUrl = '', linkName = '', type = '') => {
  category = category ? sanitizeTextForAnalytics(category) : '';
  name = name ? sanitizeTextForAnalytics(name) : '';
  linkName = linkName ? sanitizeTextForAnalytics(linkName) : '';
  type = type ? sanitizeTextForAnalytics(type) : '';
  let blogCategory = category && category === 'how tos' ? "how to's" : category;
  const eventInfo = {
    clickInternalLinks: 'true',
    eventAction: `blog listing:${blogCategory}:main content area:${name}`,
    eventName: `blog listing:${blogCategory}:main content area:${name}`,
    eventType: 'navigation',
    internalLinkName: linkName ? linkName : name,
    internalLinkPosition: 'blogs listing',
    internalLinkType: `blog listing:${type ? `${blogCategory}:${type}` : 'navigation'}`,
    internalLinkZoneName: `blog listing:${blogCategory}:main content area`,
    internalLinkURL: linkUrl ? (linkUrl.indexOf('http') === -1 ? window.location.origin + linkUrl : linkUrl) : 'n/a',
  };
  return JSON.stringify(eventInfo);
};
const blogListingAnalytics = (category = '', name = '', linkUrl = '', type = '') => {
  const { adobeDataLayer: { getState } = {} } = window;
  const page = (getState && getState('page')) || {};
  const eventInfo = bloglistingEventInfo(category, name, linkUrl, '', type);
  window.adobeDataLayer.push({
    event: 'cmp:click',
    eventInfo: JSON.parse(eventInfo),
    page,
  });
};

const blogListingBtnAnalytics = (category = '', name = '') => {
  return bloglistingEventInfo(category, name);
};

const blogListingPaginationAnalytics = (limit = '', textAll, all = false, pos = '', selectedTab = '') => {
  limit = limit + '';
  let pageCount = !all ? limit : textAll;
  return bloglistingEventInfo(selectedTab, 'pagination', '', `view ${pageCount}`);
};

export { blogListingAnalytics, blogListingBtnAnalytics, blogListingPaginationAnalytics };