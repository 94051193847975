import React, { useEffect, useState, useRef } from "react"
import cx from "classnames"
import useWindowResize from "@/hooks/useWindowResize"
import { getArticles, fl } from "@/components/Blog/BlogList/v1/utils"
import BlogPageLoader from "@/components/Blog/BlogList/v1/BlogPageLoader"
import ArticleCard from "@/components/Blog/BlogList/v1/articleCard/ArticleCard"
import ProductsPerPage from "@/components/ProductList/v2/ProductListView/ProductsPerPage"
import BackToTop from "@/components/Blog/BlogList/v1/backToTop/BackToTop"
import useBlogPosti18n from "@/i18n/useBlogPosti18n"
import Button from "@/components/core/Button/Button"
import { PRODUCTS_PER_PAGE } from "@/constants/index"
import {
  blogListingAnalytics,
  blogListingBtnAnalytics,
} from "@/components/Blog/BlogList/v1/analytics"

const BlogListing = ({ data: authorData }) => {
  const [articlesList, setArticlesList] = useState([])
  const [totalResult, setTotalResult] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const prevPaginationState = useRef()
  const [paginationState, setPaginationState] = useState({
    rows: 30,
    start: 0,
  })
  const { rows, start } = paginationState
  const { all, backToTop, loadMore, readArticleText, view, items } =
    useBlogPosti18n()
  const [width] = useWindowResize()

  const { tagSelect } = authorData

  const staticText = {
    all,
    backToTop,
    loadMore,
    readArticleText,
    view,
    items,
  }

  useEffect(() => {
    setIsInitialLoad(true)
    fetchArticles(false, true)
  }, [])

  useEffect(() => {
    if (prevPaginationState?.current) {
      if (paginationState.start > prevPaginationState?.current?.start) {
        fetchArticles(true)
      } else {
        fetchArticles()
      }
    }
    prevPaginationState.current = paginationState
  }, [paginationState])

  const fetchArticles = async (update, isSsr = false) => {
    setIsLoading(true)
    const query = {
      query: {
        rows: paginationState.rows,
        start: paginationState.start,
      },
      fls: fl,
      category: tagSelect,
    }
    const res = await getArticles(query)
    setArticlesList(update ? [...articlesList, ...res.data] : res.data)
    setTotalResult(res.total)
    setIsLoading(false)
    setIsInitialLoad(false)
  }

  const handleRows = rows => {
    setPaginationState({ rows, start: 0 })
    setRowsSession(rows)
    setIsInitialLoad(true)
  }

  const setRowsSession = rows => {
    if (sessionStorage.blogRows) {
      const rowStorage = JSON.parse(sessionStorage.blogRows)
      rowStorage[window.location.pathname] = rows
      sessionStorage.blogRows = JSON.stringify(rowStorage)
    } else {
      sessionStorage.blogRows = JSON.stringify({
        [window.location.pathname]: rows,
      })
    }
  }

  const handleLoadMore = () => {
    setRowsSession(start + rows + rows)
    setPaginationState(state => ({ ...state, start: start + rows }))
  }

  if (isInitialLoad) {
    return <BlogPageLoader />
  }

  return (
    <main
      className={cx("blog-listing mira-react-container", {
        "is-blog-loading": isLoading,
      })}
    >
      <ul className="blog-listing-inner-content" role="listbox">
        {articlesList?.map((item, i) => {
          return (
            <li key={i} className="article-card-wrapper" role="listitem">
              <ArticleCard
                no={i}
                data={item}
                eyebrowText={tagSelect}
                staticText={staticText}
                getLatestReadArticleAnalytics={() =>
                  blogListingAnalytics(
                    tagSelect,
                    staticText.readArticleText,
                    item.url_s,
                    item.title_s
                  )
                }
              />
            </li>
          )
        })}
      </ul>

      <div className="blog-listing-bottom-content">
        {totalResult > articlesList?.length && (
          <Button
            customClass="blog-listing-load-more gbh-data-layer"
            type="secondary"
            label={staticText.loadMore}
            onClick={handleLoadMore}
            analyticsData={blogListingBtnAnalytics(
              tagSelect,
              staticText.loadMore
            )}
            role="button"
            flexible
          />
        )}
        <div className="blog-listing-pagination-container">
          <div className="blog-listing-pagination">
            {totalResult > 30 &&
              totalResult > articlesList?.length &&
              width > 1023 && (
                <>
                  <ProductsPerPage
                    page="blog"
                    curRows={rows + start}
                    data={PRODUCTS_PER_PAGE}
                    totalResults={totalResult}
                    texts={staticText}
                    onChange={handleRows}
                    selectedTab={tagSelect}
                  />
                </>
              )}
          </div>
          {articlesList?.length > 3 ? (
            <BackToTop
              customClass="gbh-data-layer"
              analyticsData={blogListingBtnAnalytics(
                tagSelect,
                staticText.backToTop
              )}
              text={staticText.backToTop}
            />
          ) : null}
        </div>
      </div>
    </main>
  )
}

export default BlogListing
