import { useEffect, useRef, useState } from "react"
import {
  initOverlayScrollbar,
  addScrollCss,
  sanitizeText,
  getShortenedUrlwithGeneral,
} from "@/utils/helper"
import { useTranslation } from "react-i18next"
import { getArticles } from "@/components/Blog/BlogList/v1/utils"
import ArticleCard from "@/components/Blog/BlogList/v1/articleCard/ArticleCard"
import useWindowResize from "@/hooks/useWindowResize"
import { blogCarouselAnalytics } from "@/components/Blog/BlogCarousel/v1/analytics"
import { getConfig } from "@/constants/config"
import useBlogPosti18n from "@/i18n/useBlogPosti18n"
import Link from "next/link"

const BlogCarousel = ({ data }) => {
  const {
    latestTitle,
    newTab,
    sectionTitle,
    tagSelect,
    type,
    ctaLink,
    ctaText,
    maxArticle,
    popularTitle,
  } = data
  const [general, setGeneral] = useState()
  const carouselRef = useRef(null)
  const [width] = useWindowResize()
  const { t } = useTranslation()

  useEffect(() => {
    if (type !== "latestPopular") {
      initOverlayScrollbar(carouselRef)
      addScrollCss(carouselRef, ".os-scrollbar-visible", "os-host-overflow-x")
    }
  }, [])
  useEffect(() => {
    if (type !== "latestPopular") {
      addScrollCss(carouselRef, ".os-scrollbar-visible", "os-host-overflow-x")
    }
  }, [width])
  useEffect(() => {
    const configFn = async () => {
      const { general } = await getConfig()
      setGeneral(general)
    }
    configFn()
  }, [])

  const [popularData, setPopularData] = useState()
  const [articleList, setArticlesList] = useState([])

  const { readArticleText } = useBlogPosti18n()

  const staticText = {
    backToTop: t("kf.blog.listing.backToTop"),
    readArticleText,
  }
  const requestQuery = {
    query: {
      rows: maxArticle,
    },
    fls: ["id", "url_s", "thumbNailImage_s", "title_s", "releaseDate_dt"],
    category: tagSelect,
  }

  const latestQuery = {
    query: {
      rows: 3,
    },
    fls: [
      "id",
      "url_s",
      "thumbNailImage_s",
      "title_s",
      "releaseDate_dt",
      "category_ss",
    ],
  }

  const popularQuery = {
    query: {
      rows: 5,
    },
    fls: ["url_s", "title_s"],
    category: "Popular",
  }

  const fetchArticles = async () => {
    const res = await getArticles(
      type === "latestPopular" ? latestQuery : requestQuery
    )
    if (type === "latestPopular") {
      const res = await getArticles(popularQuery)

      setPopularData(res.data || [])
    }
    const limit = type === "latestPopular" ? maxArticle ?? 2 : maxArticle ?? 10
    setArticlesList(res.data?.slice(0, Number(limit)) || [])
  }

  useEffect(() => {
    fetchArticles()
  }, [])

  return (
    <>
      <main className="blog-carousel">
        {type === "latestPopular" && window.innerWidth < 1024 ? (
          <div className="most-popular-section top">
            <h3 id="popular-title-mobile" className="most-popular-title">
              {popularTitle ? sanitizeText(popularTitle) : ""}
            </h3>
            <ul
              role="listbox"
              aria-labelledby="popular-title-mobile"
              className="blog-listing-inner-content"
            >
              {popularData?.map(item => {
                return (
                  <li className="links-section" key={item.id} role="listitem">
                    <a
                      href={item.url_s}
                      className="most-popular-link"
                      onClick={() =>
                        blogCarouselAnalytics(
                          popularTitle,
                          "blog navigation",
                          item.url_s,
                          item.title_s
                        )
                      }
                    >
                      {item.title_s}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}

        <div className="blog-carousel__header blog-react-container">
          <h2 className="blog-carousel__title">
            {sanitizeText(
              type === "latestPopular" ? latestTitle : sectionTitle
            )}
          </h2>
          {type !== "latestPopular" && width > 767 ? (
            <Link
              className="blog-carousel__view-all"
              href={getShortenedUrlwithGeneral(ctaLink, general)}
              target={newTab ? "_blank" : "_self"}
              onClick={() =>
                blogCarouselAnalytics(sectionTitle, ctaText, ctaLink)
              }
              aria-label={`${
                type === "latestPopular" ? latestTitle : sectionTitle
              } ${ctaText}`}
              rel="noreferrer"
            >
              {ctaText}
            </Link>
          ) : null}
        </div>
        <div
          className={`blog-carousel__content ${
            type === "latestPopular" ? "blog-react-container latestPopular" : ""
          }`}
          ref={carouselRef}
        >
          <ul className="blog-carousel__slider" role="listbox">
            {articleList?.map(item => {
              return (
                <li
                  key={item.id}
                  className={`article-card-wrapper ${
                    type === "latestPopular" ? "" : "remove-mr"
                  }`}
                  role="listitem"
                >
                  <ArticleCard
                    data={item}
                    eyebrowText={item["category_ss"]?.[0] ?? tagSelect}
                    staticText={staticText}
                    getLatestReadArticleAnalytics={() =>
                      blogCarouselAnalytics(
                        type === "latestPopular" ? latestTitle : sectionTitle,
                        staticText.readArticleText,
                        item.url_s,
                        "",
                        `${item["category_ss"]?.[0] || tagSelect}:${
                          item.title_s
                        }`
                      )
                    }
                  />
                </li>
              )
            })}
          </ul>
          {type === "latestPopular" && window.innerWidth > 1023 ? (
            <div className="most-popular-section">
              <h3 id="popular-title-desktop" className="most-popular-title">
                {popularTitle ? sanitizeText(popularTitle) : ""}
              </h3>
              <ul role="listbox" aria-labelledby="popular-title-desktop">
                {popularData?.map(item => {
                  return (
                    <li role="listitem" className="links-section" key={item.id}>
                      <a
                        href={item.url_s}
                        onClick={() =>
                          blogCarouselAnalytics(
                            popularTitle,
                            "blog navigation",
                            item.url_s,
                            item.title_s
                          )
                        }
                        className="most-popular-link"
                      >
                        {item.title_s}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : null}
        </div>
        {type !== "latestPopular" && width < 767 ? (
          <div className="blog-carousel__view-all-center">
            <Link
              href={getShortenedUrlwithGeneral(ctaLink, general)}
              target={newTab ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={() =>
                blogCarouselAnalytics(sectionTitle, ctaText, ctaLink)
              }
            >
              {ctaText}
            </Link>
          </div>
        ) : null}
      </main>
    </>
  )
}

export default BlogCarousel
