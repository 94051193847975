import { authorBaseUrl as baseUrl } from "@/constants"
import Link from "next/link"
import { useEffect } from "react"
import brandLogo from "@/public/icons/BLOK_LOGO.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  closeMenu,
  openMenu,
  selectGenericState,
} from "@/store/features/genericSlice"
import { addLogoDatalayer } from "./analytics"
const BrandLogo = props => {
  const {
    data,
    columnClassNames,
    eventHandler: {
      store: { brandLogoImg = "" } = {},
      callbacks: { setBrandLogoImg = () => console.log("Event Not Defined") },
    },
  } = props
  const {
    menu: { isOpen },
  } = useSelector(selectGenericState)
  const dispatch = useDispatch()
  const toggleMenu = () => {
    isOpen
      ? (dispatch(closeMenu()),
      document.querySelector(".page", ".basicpage")?.classList?.remove("gbh-of-scroll"),
      document.querySelector(".ann-sacks-main-menu.header-container.ann-sacks-tab-bar")?.classList?.remove("ann-sacks-tab-menu", "button-format"))
      : (dispatch(openMenu()),
      document
          .querySelector(
            ".ann-sacks-main-menu.ann-sacks-tab-bar > div:first-child"
          )
          ?.classList?.add(
            "icon-close-medium",
            "icon-Close-Medium",
            "icon-close"),
      document.querySelector(".page", ".basicpage")?.classList?.add("gbh-of-scroll"),
      document.querySelector(".ann-sacks-main-menu.header-container.ann-sacks-tab-bar")?.classList?.add("ann-sacks-tab-menu", "button-format"))
  }

  useEffect(() => {
    if (data?.dataLayer[data?.id] && data?.dataLayer[data?.id]?.image) {
      const brandLogoPath = data?.dataLayer[data?.id]?.image["repo:path"]
      brandLogoPath && setBrandLogoImg(baseUrl + brandLogoPath)
    }
  }, [data])

  const logoUrlPath =
    data?.link
      ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
      .join("")
      .replace(".html", "") || process.env.NEXT_PUBLIC_AUTHORBASEURL

  return (
    <>
      <span
        id="main-scroll-close"
        className={`gbh-hamber-menu icon-Hamburger ${
          isOpen ? "icon-close-medium icon-close" : ""
        }`}
        tabIndex="0"
        aria-label="Hamburger-menu"
        role="button"
        onClick={toggleMenu}
      ></span>
      <div
        className={`cmp cmp-image ${
          data?.appliedCssClassNames ? data?.appliedCssClassNames : ""
        } ${columnClassNames ? columnClassNames : ""}`}
      >
        <a
          href={logoUrlPath}
          title={data?.alt}
          className="cmp-image--link gbh-data-layer"
          data-gbh-data-layer={addLogoDatalayer(logoUrlPath, data?.alt)}
        >
          <section>
            {brandLogoImg && (
              <img
                src={`${brandLogoImg}`}
                height={brandLogo.height}
                width={brandLogo.width}
                alt={data?.alt}
              />
            )}
          </section>
        </a>
      </div>
    </>
  )
}

export default BrandLogo
