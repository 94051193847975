import React, { useEffect, useState, useRef } from "react"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import Modal from "@/components/core/Modal/Modal"
import ProductSocialShare from "@/components/Shared/ProductSocialShare/v1/ProductSocialShare"
import { getConfig } from "@/constants/config"
import {
  getPDPUrlWithConfig,
  getShortenedUrl,
  getUserPersona,
  preventBodyScroll,
} from "@/utils/helper"
import { getExtention } from "@/utils"
import { searchPlp } from "@/store/features/genericSlice"

const PartsViewer = ({
  page = "",
  i18n = {},
  productId = "",
  partId = "",
  analyticsCustomData = null,
  onClose,
  showModal = false,
}) => {
  const dispatch = useDispatch()
  const iframeRef = useRef()
  const [svgViewerPath, setSvgViewerPath] = useState("")
  const [currencySign, setCurrencySign] = useState("")
  const [general, setGeneral] = useState(null)
  const [plpServletPath, setPlpServletPath] = useState(null)
  const [salesChannelConfig, setSalesChannelConfig] = useState({})

  useEffect(() => {
    getConfig().then(async config => {
      const svgViewerPath = await getShortenedUrl(
        _get(config, "general.svgViewerPath", "")
      )
      setSalesChannelConfig(config?.salesChannelConfig?.map ?? {})
      const currencySign = _get(config, "internationalization.currencySign", "")
      setCurrencySign(currencySign)
      setSvgViewerPath(svgViewerPath)
      setGeneral(config.general)
      setPlpServletPath(config.plpServletPath)
    })
  }, [])

  useEffect(() => {
    if (showModal) {
      preventBodyScroll(true)
    }
  }, [showModal])

  const persona = getUserPersona()
  const [showShare, setShowShare] = useState(false)
  const getPDPUrlForViewer = (category, slug) => {
    const pdpdUrl = getPDPUrlWithConfig(category, slug, plpServletPath, general)
    return pdpdUrl
  }

  const addToCartTrigger = async data => {
    const actions = []
    data.forEach(product => {
      const fields = {
        salesChannel: salesChannelConfig[product?.brand] ?? "KOUS",
      }
      actions.push({
        action: product.isBundle ? "addbundle" : "addLineItem",
        sku: product.sku,
        quantity: product.quantity,
        custom: {
          type: { key: "lineItemShipping" },
          fields: fields,
        },
      })
    })
    const reqData = {}
    reqData["actions"] = actions
    const cartObj =
      dispatch(searchPlp(reqData))
        .unwrap()
        .then(res => {
          const respVal = {}
          respVal.status = 200
          return respVal
        })
        .catch(err => {
          const respVal = {}
          respVal.status = 500
          return respVal
        }) || {}
    return cartObj
  }

  useEffect(() => {
    if (
      currencySign !== "" &&
      persona &&
      plpServletPath &&
      !_isEmpty(general)
    ) {
      window.partViewer = {
        atc: addToCartTrigger,
        getPDPUrl: getPDPUrlForViewer,
        persona,
        currencySign,
        setShowShare,
      }
      iframeRef.current?.focus()
      return () => preventBodyScroll(false)
    }
  }, [currencySign, persona, general])

  const filename = getExtention(partId) ? partId : partId + ".svg"

  const closeModal = () => {
    preventBodyScroll(false)
    onClose()
  }

  const getPartDiagramCloseEventInfo = JSON.stringify({
    eventAction: page + ":modal:interactive parts diagram:close",
    eventName: page + ":modal:interactive parts diagram:close",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "close",
    internalLinkPosition: page,
    internalLinkType: page + ":close",
    internalLinkZoneName: page + ":modal:interactive parts diagram",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })

  return (
    <Modal
      showModal={showModal}
      onModalClose={closeModal}
      closeButtonAnalytics={getPartDiagramCloseEventInfo}
      analyticsCustomData={analyticsCustomData || null}
    >
      {svgViewerPath && (
        <iframe
          id="svg-viewer-iframe"
          ref={iframeRef}
          allowFullScreen={true}
          scrolling="auto"
          src={`${svgViewerPath}?productId=${productId}&filename=${filename}&diagram=0&origin=${window.location.href}`}
        />
      )}
      {showShare && (
        <PartSocialShare
          showShare={showShare}
          setShowShare={setShowShare}
          iframeId="svg-viewer-iframe"
          i18n={i18n}
          iframeRef={iframeRef.current}
        />
      )}
    </Modal>
  )
}

const PartSocialShare = ({
  showShare,
  setShowShare,
  iframeId,
  i18n,
  iframeRef,
}) => {
  return ReactDOM.createPortal(
    <ProductSocialShare
      showModal={showShare}
      id="social-share-iframe"
      onClose={() => setShowShare(false)}
      iframeId={iframeId}
      iframeRef={iframeRef}
      disableInstagram
      mailSubject={i18n.mailSubject}
      mailBody={i18n.mailBody}
    />,
    iframeRef.contentDocument.getElementById("social-share-gbh")
  )
}

export default PartsViewer
