import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import qs from "qs"

import { inPageSearchEndPoint } from "@/constants"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"

import InPageSearchView from "@/components/InPageSearch/v1/InPageSearch.view"
import { debounce } from "@/utils/helper"

const InPageSearch = props => {
  const { data: searchProps = {} } = props
  const [lwAppName, setLwAppName] = useState("")
  const [language, setLanguage] = useState("en")
  const [searchString, setSearchString] = useState("")
  const [{ loading, partId, productId }, setState] = useState({
    loading: false,
    partId: "",
    productId: "",
  })
  const [isSkuSearch, setIsSkuSearch] = useState(false)
  const [pdfPartsDiagram, setPdfPartsDiagram] = useState("")

  useEffect(() => {
    getConfig().then(config => {
      const {
        general: { lwAppName = "" } = {},
        internationalization: { language = "en" } = {},
      } = config ?? {}
      setLwAppName(lwAppName)
      setLanguage(language)
    })
  }, [])

  const handleChange = debounce(searchTerm => {
    setSearchString(searchTerm.trim())
  })

  useEffect(() => {
    if (lwAppName && searchString) {
      getData(searchString)
    }
  }, [searchString, lwAppName])

  const getData = searchString => {
    if (searchString.length >= 3) {
      setState(prevState => ({ ...prevState, loading: true }))
      const fl =
        "id,sku_s,slug_s,ProductResource.InteractiveSVG.resourceName_ss,ProductProductNo_s,ProductResource.InteractiveSVG.resourceTitle_ss,ProductResource.PartsPDFFileName.resourceFullWebURL_ss"
      apim
        .get(inPageSearchEndPoint, {
          params: {
            q: searchString,
            fl,
            fq: ['Products:("ct")', `language_s:("${language}")`],
            collections: lwAppName.toLowerCase(),
            profilename: `profile_${lwAppName.toLowerCase()}_Search`,
          },
          paramsSerializer: params => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            })
          },
        })
        .then(res => {
          const {
            data: {
              response: { numFound = "", docs = [] } = {},
              fusion: { exactMatch = "" } = {},
            } = {},
          } = res
          if (numFound === 1 && exactMatch) {
            const mResources = _get(
              docs[0],
              "ProductResource.InteractiveSVG.resourceName_ss",
              ""
            )
            const pdfPartsDiagram = _get(
              docs[0],
              "ProductResource.PartsPDFFileName.resourceFullWebURL_ss",
              ""
            )
            const partId = Array.isArray(mResources)
              ? mResources[0]
              : mResources

            const productId = _get(docs[0], "ProductProductNo_s", "")
            setState(prevState => ({
              ...prevState,
              partId: partId ?? "",
              productId,
              loading: false,
            }))
            setPdfPartsDiagram(pdfPartsDiagram)
            const isSkuSearch =
              (Boolean(partId) && Boolean(productId)) ||
              (Boolean(pdfPartsDiagram) && Boolean(pdfPartsDiagram[0]))
            setIsSkuSearch(isSkuSearch)
          } else {
            setIsSkuSearch(false)
            setState(prevState => ({
              ...prevState,
              loading: false,
            }))
          }
        })
    }
  }

  return (
    <InPageSearchView
      loading={loading}
      partId={partId}
      isSkuSearch={isSkuSearch}
      productId={productId}
      searchString={searchString}
      pdfPartsDiagram={pdfPartsDiagram}
      onChange={handleChange}
      lwAppName={lwAppName}
      language={language}
      {...searchProps}
    />
  )
}

export default InPageSearch
