export const getEventInfo = (
  linkUrl = "",
  status = "",
  type = "",
  searchString = ""
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const title = page.category?.subCategoryID?.replaceAll(" ", "-")
  const findAServicePart = title === "find-a-service-part"
  const eventAction =
    (findAServicePart
      ? `${page.category.pageType}:${title}:`
      : `support${type}:`) + "main content area:search"
  const internalLinkPosition = findAServicePart
    ? `${page.category.pageType}:${title}`
    : `support${type}`
  const internalLinkType =
    (findAServicePart
      ? `${page.category.pageType}:${title}:`
      : `support${type}:`) + `${searchString}`
  const internalLinkZoneName =
    (findAServicePart
      ? `${page.category.pageType}:${title}:`
      : `support${type}:`) + "main content area"
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction,
    eventName: eventAction,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: status || "n/a",
    internalLinkName: "search",
    internalLinkPosition,
    internalLinkType,
    internalLinkURL: linkUrl || "n/a",
    internalLinkZoneName,
    searchTerm: searchString,
  })
}
