import { useTranslation } from "next-i18next"

const useBlogPosti18n = () => {
  const { t } = useTranslation()
  const i18n = {
    backToTop: t("kf.plp.label.backToTop"),
    readArticleText: t("kf.plp.label.readArticle"),
    loadMore: t("kf.plp.label.loadMore"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    items: t("kf.plp.label.items"),
    mostPopular:t("kf.plp.label.mostPopular")
  }

  return i18n
}

export default useBlogPosti18n
