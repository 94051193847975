import { authorBaseUrl as baseUrl } from "@/constants"
import Link from "next/link"
import { useEffect } from "react"
import brandLogo from "@/public/icons/BLOK_LOGO.svg"

const HeaderNavLogo = props => {
  const {
    data = {},
    columnClassNames = "",
    eventHandler: {
      store: { brandLogoImg = "" } = {},
      callbacks: {
        setBrandLogoImg = () => console.log("Event Not Defined"),
        toggleMenu = () => console.log("Event Not Defined"),
      },
    },
  } = props

  const { dataLayer = {}, id = "" } = data
  const dataLayerImage = dataLayer[id]?.image

  useEffect(() => {
    if (dataLayerImage) {
      const brandLogoPath = dataLayerImage["repo:path"]
      brandLogoPath && setBrandLogoImg(baseUrl + brandLogoPath)
    }
  }, [dataLayerImage])

  useEffect(() => {
    setBrandLogoImg(brandLogo?.src)
  }, [])

  return (
    <>
      <span
        className="gbh-hamber-menu icon-Hamburger"
        tabIndex="0"
        aria-label="Hamburger-menu"
        role="button"
        onClick={toggleMenu}
      ></span>
      <div
        className={`cmp cmp-image ${
          data?.appliedCssClassNames ? data?.appliedCssClassNames : ""
        } ${columnClassNames ? columnClassNames : ""}`}
      >
        <Link
          href={data?.link
            ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
            .join("")
            .replace(".html", "")}
          title={data?.alt}
          className="cmp-image--link"
        >
          <section>
            {brandLogoImg && <img src={`${brandLogoImg}`} alt={data?.alt} />}
          </section>
        </Link>
      </div>
    </>
  )
}

export default HeaderNavLogo
