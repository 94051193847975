import DynamicImage from "./DynamicImage"
import noImage from "@/public/images/image-missing.png"

const Image = props => {
  const {
    data: {
      id: uniqueId = "",
      alt = "",
      src: imgUrl = "",
      imageComponentProperties = {
        src: "",
        alt: "",
      },
      enableNoImage = false,
      title,
      appliedCssClassNames
    } = {},
  } = props

  return (
    <div className={`image ${appliedCssClassNames ? appliedCssClassNames : ""}`} id="image">
      <div className="eaem-bounding-div">
        <div className="cmp-image">
          {!enableNoImage ? (
            <DynamicImage
              {...{
                uniqueId,
                imgUrl: imgUrl || imageComponentProperties.src,
                alt: alt || imageComponentProperties.alt,
                className: "cmp-image__image transition",
              }}
            />
          ) : (
            <img
              src={noImage?.src}
              alt="image coming soon"
              className="no-image"
            />
          )}
          {title ? (<span className="cmp-image__title"><p>{title}</p></span>) : ""}
        </div>
      </div>
    </div>
  )
}

export default Image
