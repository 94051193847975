import React, { useEffect, useRef, useState } from "react"

import useSmartCrop from "@/hooks/useSmartCrop"
import useIsSsr from "@/hooks/useIsSsr"
const DynamicImage = ({
  imgUrl = "",
  alt = "",
  className = "",
  uniqueId = "",
  urlModifiers = "",
  ...restProps
}) => {
  const smartCrop = useSmartCrop()
  const isSsr = useIsSsr()
  const cropedImage = useRef(null)
  const imgPlaceholder =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  const isScene7Img = imgUrl?.includes("scene7")
  const [bgColorImage, setBgColorImage] = useState("")

  useEffect(() => {
    if (
      !isSsr &&
      smartCrop.scriptStatus &&
      isScene7Img &&
      window.s7responsiveImage
    ) {
      smartCrop.applySmartCrop(cropedImage.current)
    }
  }, [
    smartCrop.scriptStatus,
    imgUrl,
    uniqueId,
    isSsr,
  ])

  useEffect(() => {
    if (urlModifiers && imgUrl) {
      setBgColorImage(
        imgUrl?.includes("?")
          ? `${imgUrl}&${urlModifiers}`
          : `${imgUrl}?${urlModifiers}`
      )
    }
  }, [imgUrl, urlModifiers])

  return (
    <img
      ref={cropedImage}
      src={imgPlaceholder}
      data-src={urlModifiers ? bgColorImage : imgUrl}
      data-mode="smartcrop"
      alt={alt}
      className={className}
      key={uniqueId}
      {...restProps}
    />
  )
}

export default DynamicImage
