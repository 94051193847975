import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import cx from "classnames"
import _isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { getConfig } from "@/constants/config"
import { isMobile } from "react-device-detect"
import Link from "next/link"

import {
  getMyLocation,
  sanitizeInnerHtml,
  formatPhoneNumber,
} from "@/utils/helper"
import { validateField, getShortenedUrl } from "@/utils/location"
import {
  locationState,
  setFindAStoreActive,
  setStoreAddress,
  setUtilityLocation,
  setNearestStoreAddress,
} from "@/store/features/locationSlice"

import { getCityList } from "@/utils/helper"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import closeWhite from "@/public/icons/close-white.svg"
import leftArrow from "@/public/icons/left-arrow-white.svg"
import styles from "@/components/FindStoreUtilNav/v1/index.module.scss"
import ZipCodeInfoModal from "@/components/core/Modal/ZipCodeInfoModal"
import AutoSuggestion from "@/components/core/AutoSuggestion/v1/AutoSuggestion"
import {
  addFindStoreAnalytics,
  headerFindStoreUseLocationAnalytics,
} from "@/components/FindStoreUtilNav/analytics"
import { getFindAStoresResult } from "@/components/FindStore/v1/api"
import Button from "@/components/core/Button/Button"

const configInitialState = {
  browseAllStoreUrl: "",
  brand: "",
  findStores: "",
}

const FindStoreUtilNavView = props => {
  const { findAStoreActive } = useSelector(locationState)
  const [zipCodeValue, setZipcodeValue] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)
  const [zipCodeError, setZipCodeError] = useState({ show: false, message: "" })
  const [configState, setConfigState] = useState(configInitialState)
  const [browseAllStoreUrl, setbrowseAllStoreUrl] = useState("")
  const [myStoreUrl, setMyStoreUrl] = useState("/")
  const [optionList, setOptionList] = useState([])
  const {
    utilityStoreDetails,
    utilityData,
    nearestUtilityData,
    myStoreId,
    eventHandler: {
      callbacks: { toggleFindAStore = () => {}, closeMenu = () => {} },
    },
  } = props
  const [infoModalErrror, setInfoModalErrror] = useState({})
  const [infoModal, setInfoModal] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    getConfig()
      .then(async config => {
        const { general } = config
        const url = await getShortenedUrl(general.allStoresPagePath ?? "/")
        const findStores = get(config, "apiEndpoints.findStores", "")
        const brand = get(config, "general.siteName", "")
        setConfigState({
          browseAllStoreUrl: url,
          findStores,
          brand,
        })
        localStorage.getItem("currentZipCode") &&
          setZipcodeValue(localStorage.getItem("currentZipCode"))
        setbrowseAllStoreUrl(url)
      })
      .catch(err => console.log(err))
    props.getUtilityGeolocation()
  }, [])

  useEffect(() => {
    window.wow?.sync()
    if (isSubmit === true) {
      props.redirect(zipCodeValue)
    }
  }, [isSubmit])

  useEffect(() => {
    if (zipCodeValue.length > 2) {
      fetchListOfCities()
    }
  }, [zipCodeValue])

  useEffect(() => {
    if (myStoreId === '0') {
      nearestStoreAddress()
    } else {
      dispatch(setStoreAddress(utilityData))
    }
  }, [zipCodeValue, myStoreId,configState])

  const nearestStoreAddress = async () => {
    const { findStores, brand } = configState
    if(findStores && brand){ 
    const nearestStore = await getFindAStoresResult(
      findStores,
      zipCodeValue,
      brand,
      100,
      1,
      "kohler store",
      1
    )
    if (nearestStore !== undefined) {
      const nearestStoreData = nearestStore?.data?.info[0] ?? {}
      const utilityData = {
        ...nearestStoreData,
        EntityID: nearestStoreData?.BPnumber ?? null,
      }
      dispatch(setNearestStoreAddress(utilityData))
    } else {
      utilityData = {}
      dispatch(setNearestStoreAddress(utilityData)) }
    }
  }

  const fetchListOfCities = async () => {
    const { findStores, brand } = configState
    const responseData = await getCityList(findStores, brand, zipCodeValue)
    const { data: { d: { results = [] } = {} } = {} } = responseData
    const cities = results.map(city => city.Locality)
    setOptionList([...new Set(cities)])
  }

  useEffect(() => {
    if (!_isEmpty(utilityStoreDetails) && utilityData?.BPnumber) {
      getShortenedUrl(utilityStoreDetails[utilityData.BPnumber]).then(
        myStoreUrl => {
          setMyStoreUrl(myStoreUrl)
        }
      )
    }
  }, [utilityStoreDetails, utilityData])

  const handleChange = value => {
    setZipcodeValue(value)
  }

  const getGeoLocation = () => {
    getMyLocation()
      .then(getLocation => {
        setZipcodeValue(getLocation.zipcode ?? "")
        headerFindStoreUseLocationAnalytics({ eventStatus: "success" })
      })
      .catch(({ cause }) => {
        setInfoModalErrror(cause ?? {})
        setInfoModal(true)
        headerFindStoreUseLocationAnalytics({
          errorMessage: cause?.message ?? "",
          staticTexts,
          eventStatus: "failure",
        })
      })
  }

  const onSubmit = async () => {
    let valid = true
    const zipCodeErrObj = {
      show: false,
      message: "",
    }

    const {
      staticTexts: { validZipcode },
    } = props

    const isValid = await validateField("zipCodeNumeric", zipCodeValue)

    if (isValid && zipCodeValue.length < 5) {
      dispatch(
        setUtilityLocation({
          utilityLocation: zipCodeValue,
        })
      )
      zipCodeErrObj.show = true
      zipCodeErrObj.message = validZipcode
      valid = false
    }

    if (valid) {
      setIsSubmit(true)
      toggleFindAStore()
      closeMenu()
    }
    if (findAStoreActive) {
      dispatch(setFindAStoreActive(false))
    }
    setZipCodeError({ ...zipCodeErrObj })
    addFindStoreAnalytics(zipCodeValue, zipCodeErrObj)
  }

  const getStoreAnalytics = action => {
    const href =
      utilityStoreDetails && utilityStoreDetails[utilityData?.BPnumber]
        ? utilityStoreDetails[utilityData?.BPnumber]
        : "/"
    const internalLinkName =
      action === "unset as my store"
        ? "unset as preferred store"
        : "store details"
    return JSON.stringify({
      eventAction: "find a store:utility nav​:" + action,
      eventName: "find a store:utility nav​:" + action,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName,
      internalLinkPosition: "find a store",
      internalLinkType:
        "find a store:" +
        (
          utilityData?.LocationName +
          ", " +
          utilityData?.AddressLine +
          (!_isEmpty(utilityData?.AddressLine2)
            ? ", " + utilityData?.AddressLine2
            : "") +
          ", " +
          formatPhoneNumber(utilityData?.Phone)
        )?.toLowerCase(),
      internalLinkZoneName: "find a store:utility nav",
      internalLinkURL:
        action === "view store details"
          ? href.indexOf("http") === -1
            ? window.location.origin + href
            : href
          : "n/a",
      clickInternalLinks: "true",
    })
  }

  const viewStoreAnalytics = getStoreAnalytics("view store details")
  const unsetMyStoreAnalytics = getStoreAnalytics("unset as my store")
  const { staticTexts } = props
  const locationName = utilityData?.LocationName?utilityData?.LocationName:nearestUtilityData?.LocationName
  const openUntil = utilityData?.OpenUntil?utilityData?.OpenUntil:nearestUtilityData?.OpenUntil
  const addressLine = utilityData?.AddressLine?utilityData?.AddressLine:nearestUtilityData?.AddressLine
  const addressLine2 = utilityData?.AddressLine2?utilityData?.AddressLine2:nearestUtilityData?.AddressLine2
  const locality = utilityData?.Locality?utilityData?.Locality:nearestUtilityData?.Locality
  const adminDistrict = utilityData?.AdminDistrict?utilityData?.AdminDistrict:nearestUtilityData?.AdminDistrict
  const postalCode =utilityData?.PostalCode?utilityData?.PostalCode:nearestUtilityData?.PostalCode
  const phone = utilityData?.Phone?utilityData?.Phone:nearestUtilityData?.Phone


  return (
    <div className={styles.findAStoreUtilNavWrapper}>
      <div className={`gbh-find-a-store ${props.active ? "active" : ""}`}>
        <div className={`findstore-util-nav${isSubmit ? "__activeMap" : ""}`}>
          <ZipCodeInfoModal
            handleModalClose={() => {
              setInfoModal(false)
            }}
            show={infoModal}
            data={infoModalErrror}
            labelledBy="Geo-Location-error-message"
            className="message-modal"
            staticTexts={staticTexts}
            dataLayerPosition="global header"
          />
          <React.Fragment>
            <div className="findstore-util-nav__container findstore-util-nav__show-container">
              {isMobile && (
                <div className="findstore-util-nav__mobile-container">
                  <img
                    role="button"
                    tabIndex="0"
                    src={closeWhite.src}
                    alt={staticTexts.close}
                    className="findstore-util-nav__close"
                    onClick={() => {
                      toggleFindAStore(), closeMenu()
                    }}
                  />
                  <div className="findstore-util-nav__menu">
                    <img src={leftArrow.src} alt={staticTexts.leftArrow} />
                    <span role="button" onClick={() => toggleFindAStore()}>
                      {staticTexts.menu}
                    </span>
                  </div>
                </div>
              )}
              <div
                className={`findstore-util-nav__content ${
                  !_isEmpty(utilityData) && "findstore-util-nav__content-text"
                }`}
              >
                <div className="container kf-react-container">
                  <div className="row ml-0 mr-0">
                    {(((!_isEmpty(utilityData) && !_isEmpty(utilityData.EntityID)) )|| ((!_isEmpty(nearestUtilityData) && !_isEmpty(nearestUtilityData.EntityID)) )) ? (
                      <div className="col-12 col-lg-7 findstore-util-nav__access-utility-container">
                        <div className="row">
                          <div className="col-12 col-lg-7 findstore-util-nav__supply-section">
                            <span className="findstore-util-nav__store">
                              {myStoreId !== '0'
                                ? staticTexts.myStoreText
                                : staticTexts.closestStore}
                            </span>
                            <span className="findstore-util-nav__signStore">
                              {locationName}
                            </span>
                          </div>
                          <div className="col-12 col-lg-5 findstore-util-nav__address-container">
                            <p>{openUntil}</p>
                            <p>
                              <span>{addressLine}</span>
                              {!_isEmpty(addressLine2) ? (
                                <span>{addressLine2}</span>
                              ) : (
                                ""
                              )}
                              <span>
                                {locality +
                                  ", " +
                                  adminDistrict +
                                  " " +
                                  postalCode}
                              </span>
                            </p>
                            <p>{formatPhoneNumber(phone)}</p>
                            {myStoreUrl && (
                              <a
                                href={myStoreUrl}
                                className="findstore-util-nav__view-store-detail gbh-data-layer"
                                data-gbh-data-layer={viewStoreAnalytics}
                                onClick={() => {
                                  isMobile ? closeMenu() : ""
                                }}
                              >
                                {isMobile
                                  ? staticTexts.storeDetail
                                  : staticTexts.viewStoreDetail}
                              </a>
                            )}
                            <Checkbox
                              analyticsData={unsetMyStoreAnalytics}
                              checked={
                                myStoreId === utilityData.EntityID
                                  ? true
                                  : false
                              }
                              value={
                                myStoreId === utilityData.EntityID
                                  ? staticTexts.myStore
                                  : staticTexts.setAsMyStore
                              }
                              id="findstore-util-nav__my-store"
                              onChange={e =>
                                props.handleUtilityStoreCheck(
                                  e.target.checked ? utilityData.EntityID : 0
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 col-lg-6 findstore-util-nav__search-utility-container">
                        <div className="findstore-util-nav__location-content">
                          {sanitizeInnerHtml(staticTexts.locationDescription)}
                        </div>
                        <div className="findstore-util-nav__zipcode-content">
                          {sanitizeInnerHtml(staticTexts.zipCodeContent)}
                        </div>
                      </div>
                    )}
                    <div
                      className={`col-12 findstore-util-nav__loc-search-container ${
                        (!_isEmpty(utilityData) || !_isEmpty(nearestUtilityData))  ? "col-lg-5" : "col-lg-6"
                      }`}
                    >
                      {(!_isEmpty(utilityData) || !_isEmpty(nearestUtilityData))  && (
                        <span className="findstore-util-nav__not-your-store">
                          {staticTexts.NotYourStore + "?"}
                        </span>
                      )}
                      <div
                        className={cx({
                          "findstore-util-nav__input-container": true,
                          "findstore-util-nav__input-container-text":
                          (!_isEmpty(utilityData) || !_isEmpty(nearestUtilityData)) ,
                          "findstore-util-nav__input-disabled-state":
                            !zipCodeValue,
                        })}
                      >
                        <AutoSuggestion
                          placeholderLabel={staticTexts.enterCityStateZip}
                          selectedValue={zipCodeValue}
                          handleChange={selectedVal =>
                            handleChange(selectedVal)
                          }
                          handleSelect={selectedVal =>
                            handleChange(selectedVal)
                          }
                          optionList={optionList}
                          borderValue={"bottom"}
                        />
                        <button
                          className="findstore-util-nav__submit"
                          onClick={() => onSubmit()}
                          disabled={zipCodeValue.length < 3}
                          aria-label={staticTexts?.ariaLabel?.submit}
                        >
                          {staticTexts.submit}
                        </button>
                      </div>
                      {zipCodeError.show && (
                        <div className="findstore-util-nav__zipcode-error">
                          {zipCodeError.message}
                        </div>
                      )}
                      <div
                        className={`findstore-util-nav__link-container ${
                          (!_isEmpty(utilityData) || !_isEmpty(nearestUtilityData))  &&
                          "findstore-util-nav__link-container-text"
                        }`}
                      >
                        <div className="findstore-util-nav__location-link-container">
                          <span
                            className="findstore-util-nav__location-link"
                            aria-label={staticTexts.useLocation}
                            role="button"
                            tabIndex="0"
                            onClick={getGeoLocation}
                          >
                            {staticTexts.useLocation}
                          </span>
                        </div>
                        <div className="findstore-util-nav__view-link-container">
                          <Button
                            label={staticTexts.viewAllStore}
                            size="large"
                            type="ghost"
                            role="link"
                            tag="anchor"
                            target={staticTexts.isNewTab && "_blank"}
                            rel={staticTexts.isNewTab ? "noopener" : ""}
                            aria-label={staticTexts.viewAllStore}
                            className={`findstore-util-nav__view-store-link ${
                              isMobile ? "view-all-stores-mobile" : ""
                            } gbh-data-layer`}
                            useDefaultClass={true}
                            data-gbh-data-layer={`{"eventAction":"find a store:utility nav:view all stores","eventName":"find a store:utility nav:view all stores","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view all stores","internalLinkPosition":"find a store","internalLinkType":"find a store:navigation","internalLinkZoneName":"find a store:utility nav","internalLinkURL":"${browseAllStoreUrl}","clickInternalLinks":"true"}`}
                            to={browseAllStoreUrl}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}

FindStoreUtilNavView.defaultProps = {
  staticTexts: {},
}

FindStoreUtilNavView.propTypes = {
  staticTexts: PropTypes.object,
}

export default FindStoreUtilNavView
