export const addAnalyticsOnClose = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "main dropdown menu:products:close",
    eventMsg: "n/a",
    eventName: "main dropdown menu:products:close",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "products > close",
    internalLinkPosition: "global header:products",
    internalLinkType: "global header:navigation",
    internalLinkURL: `${window.origin}${location.pathname}`,
    internalLinkZoneName: "main dropdown menu:products",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}
