import { sanitizeTextForAnalytics } from "@/utils/helper"

const blogCarouselEventInfo = (
  category = "",
  name = "",
  linkUrl = "",
  linkName = "",
  type = ""
) => {
  category = category ? sanitizeTextForAnalytics(category) : ""
  name = name ? sanitizeTextForAnalytics(name) : ""
  linkName = linkName ? sanitizeTextForAnalytics(linkName) : ""
  type = type ? sanitizeTextForAnalytics(type) : ""
  let blogCategory = category && category === "how tos" ? "how to's" : category
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `blog landing:${blogCategory}:${name}`,
    eventName: `blog landing:${blogCategory}:${name}`,
    eventType: "navigation",
    internalLinkName: linkName ? linkName : name,
    internalLinkPosition: "blogs landing",
    internalLinkType: `blog landing:${type ? type : "navigation"}`,
    internalLinkZoneName: `blog landing:${blogCategory}`,
    internalLinkURL: linkUrl
      ? linkUrl.indexOf("http") === -1
        ? window.location.origin + linkUrl
        : linkUrl
      : "n/a",
  }
  return eventInfo
}

const blogCarouselAnalytics = (
  category = "",
  name = "",
  linkUrl = "",
  linkName = "",
  type = ""
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const eventInfo = blogCarouselEventInfo(
    category,
    name,
    linkUrl,
    linkName,
    type
  )
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export { blogCarouselAnalytics }
