import React from 'react';

const BlogPageLoader = () => {
  return (
    <div className="blog-list blog-react-container">
      <div className="blog-list-header shimmer shimmer-loading__headerTitle" />
      <div className="blog-list-wrapper">
        <div className="blog-list-content height-full">
          <div className="blog-list-tile-content">
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
            <div className="blog-list-tile-card">
              <div className="blog-list-tile shimmer height-300" />
              <p className="shimmer shimmer-loading__para width-half" />
              <p className="shimmer shimmer-loading__para width-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPageLoader;
