import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"

export const getArticles = async ({ query, fls, category, title }) => {
  const CONFIG = getConfig()
  const brandName = CONFIG.general?.siteName
  const { rows = 30, start = 0 } = query
  try {
    const params = {
      fl: fls.join(","),
      q: "*:*",
      rows: rows,
      start: start,
      collections: "annsacks",
      profilename: `profile_annsacks_PLP_Blogs`,
    }
    if (category) {
      params.fq = `category_ss:("${category.trim()}")`
    }
    if (title) {
      params.fq = `title_t:("${title}")`
    }

    const { data } = await apim.get(`/search/plp`, {
      params,
    })

    const payload = {}
    if (data.response) {
      payload.data = data.response.docs
      payload.total = data.response.numFound
    }
    return payload
  } catch (err) {
    console.error(err)
    return err
  }
}

export const fl = [
  "id",
  "url_s",
  "thumbNailImage_s",
  "title_s",
  "releaseDate_dt",
]
