import { useEffect, useState, useLayoutEffect } from "react"
import { COMPONENT_TYPES, staticClasses } from "@/constants"
import { isTeaser, recursiveDataFunction } from "../../utils"
import Teaser from "@/components/Default/Teaser/v1/Teaser"
import { removeAndCleanLink } from "@/utils/helper"
import { useDispatch } from "react-redux"
import { closeMenu } from "@/store/features/genericSlice"
const ProductsNavTab = props => {
  const {
    navOpen,
    selectedTab,
    setTab,
    productNavigationItems,
    eventHandler: {
      store: { isOpen },
    },
    datalayerPageName,
    hideMobImages,
    data,
    isTabOpen,
  } = props

  const [isMainTeaserOpen, setIsMainTeaserOpen] = useState(isTabOpen)
  const [isMenuNavOpen, setIsMenuNavOpen] = useState(isTabOpen)
  const handleTeaserOpen = () => {
    setIsMainTeaserOpen(false)
    setTab("")
    document.body.style.overflow = ""
  }
  useEffect(() => {
    setIsMainTeaserOpen(isTabOpen)
    setIsMenuNavOpen(isTabOpen)
    if (isTabOpen) {
      document.body.style.overflow = "hidden"
      document.body.classList.add("gbh-of-scroll");
    } else {
      document.body.style.overflow = ""
      document.body.classList.remove("gbh-of-scroll");
    }
    return () => {
      document.body.style.overflow = ""
    }
  }, [isTabOpen])
  useEffect(() => {
    if (!isOpen) {
      setTab("")
    }
  }, [isOpen])
  const [activeMainCategory, setActiveMainCategory] = useState("")
  const dispatch = useDispatch()
  const toggleMenu = () => {
    setIsMenuNavOpen(false)
    setTab("")
    document.getElementById("main-scroll-close").style.display = "block"
    document.getElementById("scroll-close").style.display = "none"
  }
  const onHoverMainCategory = name => {
    setActiveMainCategory(name)
  }
  const categoryNodeNameCheck = (data, categoryNodeName, categoryName = "") => {
    if (data) {
      const itemsOrder = data[":itemsOrder"]
      return itemsOrder?.map((itemOrder, idx) => {
        const items = data[":items"][itemOrder]
        if (itemOrder === categoryNodeName) {
          const key = `teaser-categoryNodeNameCheck-${idx}`
          const results = recursiveDataFunction(items, COMPONENT_TYPES.TEASER)
          if (isTeaser(results[0])) {
            return (
              <Teaser data={results[0]} key={key} categoryName={categoryName} />
            )
          } else
            return categoryNodeNameCheck(items, categoryNodeName, categoryName)
        } else
          return categoryNodeNameCheck(items, categoryNodeName, categoryName)
      })
    }
  }
  useEffect(() => {
    const isOrderSamplePresent = document.querySelectorAll(
      ".order-button.button"
    )
    const isAppointmentPresent = document.querySelectorAll(
      ".appointment-button.button"
    )
    const gbhNavFindStoreEle = document.querySelector(".gbh-nav__findStore")
    const cmpTabsTabListEles = document.querySelectorAll(".cmp-tabs__tab")
    if (isOpen && cmpTabsTabListEles.length) {
      cmpTabsTabListEles.forEach(el =>
        el.classList.remove("cmp-tabs__tabpanel--active")
      )
    }
    const buttonMenuLinkEles = document.querySelectorAll(
      ".kf-react-button.button.cmp-button.large"
    )
    const tabEles = document.querySelectorAll(".cmp-tabs__tab")
    if (
      buttonMenuLinkEles.length &&
      tabEles.length &&
      isOpen &&
      isMenuNavOpen
    ) {
      buttonMenuLinkEles.forEach(buttonMenuLinkEle => {
        buttonMenuLinkEle.style.display = "block"
      })
      tabEles.forEach(tabEle => {
        tabEle.style.display = "block"
      })
      isOrderSamplePresent[0]
      ? (isOrderSamplePresent[0].style.display = "none")
      : null
    isAppointmentPresent[0]
      ? (isAppointmentPresent[0].style.display = "none")
      : null
    }
    const hideAuthWidgetEle = document.querySelector(
      ".utility-widgets.utility-widgets__brand-switcher"
    )
    const isStickyEle = document.querySelector(".is-sticky-load")
    if (!isMenuNavOpen && isOpen) {
      buttonMenuLinkEles.forEach(buttonMenuLinkEle => {
        buttonMenuLinkEle.style.display = "block"
      })
      tabEles.forEach(tabEle => {
        tabEle.style.display = "block"
      })
      hideAuthWidgetEle.style.display = "flex"
      gbhNavFindStoreEle.style.display = "block"
    }
    if (isOpen && isMenuNavOpen) {
      hideAuthWidgetEle.style.display = "none"
      gbhNavFindStoreEle.style.display = "none"
    }

    if (isMenuNavOpen && isStickyEle) {
      isStickyEle.style.display = "none"
    }
  }, [isOpen, isMenuNavOpen])
  const closeToggleMenu = () => {
    document
      .querySelectorAll(".ann-sacks-tab-bar, .auth-widget__menu")
      .forEach(a => (a.style.display = "none"))
    document.getElementById("main-scroll-close").style.display = "block"
    dispatch(closeMenu())
  }
  return (
    <div className={`${staticClasses.CONTAINER}`}>
      <div className="cmp-container">
        <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
          <div className="globalnavigation aem-GridColumn aem-GridColumn--default--12">
            <div
              className={`gbh-product-menu
               ${hideMobImages ? "hide-mobile-images" : ""} 
              ${isMainTeaserOpen && isMenuNavOpen ? "open" : ""}`}
            >
              <div className="gbh-pro-menu__inner">
                <span
                  id="scroll-close"
                  className="icon-close-medium icon-close "
                  onClick={closeToggleMenu}
                ></span>
                <ul
                  className="flex-column gbh-pro-menu__list nav"
                  role="tablist"
                  aria-label="list of 6"
                  tabIndex="0"
                >
                  <button className="gbh-mob-back-btn" onClick={toggleMenu}>
                    Menu
                  </button>
                  <p className="global-menu-selected">{selectedTab}</p>
                  {productNavigationItems &&
                    productNavigationItems.map((item, index) => {
                      const key = `productNavigation-${index}`
                      return (
                        <li
                          key={key}
                          className="nav-item"
                          data-tab={item?.categoryName}
                          role="tab"
                        >
                          <a
                            onMouseOver={() =>
                              onHoverMainCategory(item?.categoryName)
                            }
                            className={`gbh-data-layer nav-link ${
                              item?.categoryName === activeMainCategory
                                ? "link-active"
                                : ""
                            }`}
                            aria-hidden="false"
                            aria-label={item?.categoryName}
                            data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"${datalayerPageName}${
                              datalayerPageName ? ":" : ""
                            }global header","internalLinkURL":"","internalLinkName":"products > ${item?.categoryName.toLowerCase()}","eventName":"main dropdown menu","internalLinkType":"global header:navigation","eventType":"global navigation","eventAction":"main dropdown menu"}`}
                            data-hover="tab"
                            data-target={`#submenu${item?.categoryName
                              .toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            aria-controls={`submenu${item?.categoryName
                              .toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            rel="noreferrer"
                            role="tab"
                            tabIndex="0"
                            href={
                              !item?.categoryLinkRef && item?.categoryLink
                                ? removeAndCleanLink(item?.categoryLink)
                                : null
                            }
                            aria-selected={item?.categoryLinkRef ? "true" : ""}
                            aria-expanded={item?.categoryLinkRef ? "true" : ""}
                          >
                            {item?.categoryName}
                          </a>
                        </li>
                      )
                    })}
                </ul>
                <div className="tab-content gbh-pro-menu__cont">
                  {productNavigationItems &&
                    productNavigationItems.map((mainCategory, i) => {
                      const key = `mainCategory-${i}`
                      return (
                        <div
                          key={key}
                          className={`tab-pane fade ${
                            mainCategory?.categoryName === activeMainCategory
                              ? "active show"
                              : ""
                          }`}
                          role="tabpanel"
                          aria-labelledby={mainCategory?.categoryName}
                        >
                          {data &&
                            categoryNodeNameCheck(
                              data,
                              mainCategory?.categoryNodeName,
                              mainCategory?.categoryName
                            )}
                        </div>
                      )
                    })}
                </div>
                <span
                  className="gbh-promenu-close-btn icon-Close-Large"
                  aria-label="close"
                  role="button"
                  tabIndex="0"
                  onClick={() => handleTeaserOpen()}
                ></span>
              </div>
            </div>
            <div
              className={`productbg ${navOpen === "Products" ? "open" : ""}`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsNavTab
