import DOMPurify from "isomorphic-dompurify"
import React, { useState } from "react"
import styles from "@/components/Shared/index.module.scss"
const MainFooter = props => {
  const { data, columnClassNames } = props
  const mainFooterClassName = columnClassNames || ""
  const [gbhExpand, setGbhExpand] = useState([])

  const handleGbhExpand = key => {
    if (!gbhExpand.includes(key)) {
      const newGbhExpand = [...gbhExpand]
      newGbhExpand.push(key)
      setGbhExpand(newGbhExpand)
    } else {
      const newGbhExpand = [...gbhExpand]
      const idx = gbhExpand.indexOf(key)
      newGbhExpand.splice(idx, 1)
      setGbhExpand(newGbhExpand)
    }
  }

  return (
    <div className={`${mainFooterClassName} ${styles.mainFooterWrapper}`}>
      <footer>
        <section
          className="gbh-footer-main-nav main-footer"
          aria-label="Footer"
        >
          <section className="gbh-container">
            <div className="row">
              <div
                className="col"
                aria-label={data?.quadrantTitleOne}
                role="list"
              >
                {data?.radioNavigationOne == "descriptionOne" && (
                  <>
                    <h2>{data?.quadrantTitleOne}</h2>
                    <div
                      className="gbh-find-store"
                      role="listitem"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescOne),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationOne == "linksOne" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${
                          gbhExpand.includes("linksOne") ? "gbh-expand" : ""
                        }`}
                        role="heading"
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksOne")}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleOne),
                          }}
                        />

                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !gbhExpand.includes("linksOne")
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {data?.items.map((item, index) => {
                          const gbhFooterMainNavLinkKey = `gbh-footer-main-nav-link-${index}`
                          return (
                            <React.Fragment key={gbhFooterMainNavLinkKey}>
                              <li>
                                <a
                                  className="gbh-data-layer"
                                  href={item?.brandLinkOne
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", "")}
                                  aria-label={`${item?.brandNameOne}${
                                    item?.newTabOne
                                      ? " external link opens in a new tab"
                                      : ""
                                  }`}
                                  target={`${
                                    item?.newTabOne ? "_blank" : "_self"
                                  }`}
                                  rel="noreferrer"
                                  data-gbh-data-layer={`{"clickInternalLinks":"${
                                    item?.newTabOne ? "false" : "true"
                                  }","internalLinkPosition":"${data?.quadrantTitleOne.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkOne
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(
                                      ".html",
                                      ""
                                    )}","internalLinkName":"${item?.brandNameOne?.toLowerCase()}","eventName":"footer menu:${item?.brandNameOne?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameOne?.toLowerCase()}"}`}
                                >
                                  {item?.brandNameOne}
                                </a>
                              </li>
                            </React.Fragment>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleTwo}>
                {data?.radioNavigationTwo == "descriptionTwo" && (
                  <>
                    <div
                      className="footer-inner-head"
                      role="heading"
                      aria-level="2"
                    >
                      {data?.quadrantTitleTwo}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescTwo),
                      }}
                    />
                  </>
                )}
                {data.radioNavigationTwo == "linksTwo" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${
                          gbhExpand.includes("linksTwo") ? "gbh-expand" : ""
                        }`}
                        role="heading"
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksTwo")}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleTwo),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !gbhExpand.includes("linksTwo")
                            ? { display: "none" }
                            : {}
                        }
                        className={data?.itemsOne.length > 4 ? "odd-even" : ""}
                      >
                        {data?.itemsOne.map((item, index) => {
                          const linksOneKey = `linksOne-${index}`
                          return (
                            <React.Fragment key={linksOneKey}>
                              <li>
                                <a
                                  className="gbh-data-layer"
                                  href={item?.brandLinkTwo
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", "")}
                                  target={item?.newTabTwo ? "_blank" : ""}
                                  rel="noreferrer"
                                  aria-label={`${item?.brandNameTwo}${
                                    item?.newTabTwo
                                      ? " external link opens in a new tab"
                                      : ""
                                  }`}
                                  data-gbh-data-layer={`{"clickInternalLinks":"${
                                    item?.newTabTwo ? "false" : "true"
                                  }","internalLinkPosition":"${data?.quadrantTitleTwo.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkTwo
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(
                                      ".html",
                                      ""
                                    )}","internalLinkName":"${item?.brandNameTwo?.toLowerCase()}", "eventName":"footer menu:${item?.brandNameTwo?.toLowerCase()}", "internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameTwo?.toLowerCase()}"}`}
                                >
                                  {item?.brandNameTwo}
                                </a>
                              </li>
                            </React.Fragment>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleThree}>
                {data?.descriptionThree == "descriptionThree" && (
                  <>
                    <h2>{data?.quadrantTitleThree}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescThree),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationThree == "linksThree" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${
                          gbhExpand.includes("linksThree") ? "gbh-expand" : ""
                        }`}
                        role="heading"
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksThree")}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              data?.quadrantTitleThree
                            ),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !gbhExpand.includes("linksThree")
                            ? { display: "none" }
                            : {}
                        }
                        className={data?.itemsTwo.length > 4 ? "odd-even" : ""}
                      >
                        {data?.itemsTwo.map((item, index) => (
                          <React.Fragment key={`linksTwo-${index}`}>
                            <li>
                              <a
                                className="gbh-data-layer"
                                href={item?.brandLinkThree
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(".html", "")}
                                target={item?.newTabThree ? "_blank" : ""}
                                rel="noreferrer"
                                aria-label={`${item?.brandNameThree}${
                                  item?.newTabThree
                                    ? " external link opens in a new tab"
                                    : ""
                                }`}
                                data-gbh-data-layer={`{"clickInternalLinks":"${
                                  item?.newTabThree ? "false" : "true"
                                }","internalLinkPosition":"${data?.quadrantTitleThree.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkThree
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(
                                    ".html",
                                    ""
                                  )}","internalLinkName":"${item?.brandNameThree?.toLowerCase()}","eventName":"footer menu:${item?.brandNameThree?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameThree?.toLowerCase()}"}`}
                              >
                                {item?.brandNameThree}
                              </a>
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleFour}>
                {data?.radioNavigationFour == "descriptionFour" && (
                  <>
                    <h2>{data?.quadrantTitleFour}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescFour),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationFour == "linksFour" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${
                          gbhExpand.includes("linksFour") ? "gbh-expand" : ""
                        }`}
                        role="heading"
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksFour")}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleFour),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !gbhExpand.includes("linksFour")
                            ? { display: "none" }
                            : {}
                        }
                        className={
                          data?.itemsThree.length > 4 ? "odd-even" : ""
                        }
                      >
                        {data?.itemsThree.map((item, index) => {
                          const linksThreeKey = `linksThree-${index}`
                          return (
                            <li key={linksThreeKey}>
                              <a
                                className="gbh-data-layer"
                                href={item?.brandLinkFour
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(".html", "")}
                                target={item?.newTabFour ? "_blank" : ""}
                                rel="noreferrer"
                                aria-label={`${item?.brandNameFour}${
                                  item?.newTabFour
                                    ? " external link opens in a new tab"
                                    : ""
                                }`}
                                data-gbh-data-layer={`{"clickInternalLinks":"${
                                  item?.newTabFour ? "false" : "true"
                                }","internalLinkPosition":"${data?.quadrantTitleFour.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkFour
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(
                                    ".html",
                                    ""
                                  )}","internalLinkName":"${item?.brandNameFour?.toLowerCase()}","eventName":"footer menu:${item?.brandNameFour?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameFour?.toLowerCase()}"}`}
                              >
                                {item?.brandNameFour}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>

              {/* 5 th column */}
              <div className="col" aria-label={data?.quadrantTitleFive}>
                {data?.radioNavigationFive == "descriptionFive" && (
                  <>
                    <h2>{data?.quadrantTitleFive}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantTitleFive),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationFive == "linksFive" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${
                          gbhExpand.includes("linksFive") ? "gbh-expand" : ""
                        }`}
                        role="heading"
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksFive")}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleFive),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !gbhExpand.includes("linksFive")
                            ? { display: "none" }
                            : {}
                        }
                        className={data?.itemsFour.length > 4 ? "odd-even" : ""}
                      >
                        {data?.itemsFour.map((item, index) => {
                          const linksFourKey = `linksFour-${index}`
                          return (
                            <li key={linksFourKey}>
                              <a
                                className="gbh-data-layer"
                                href={item?.brandLinkFive
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(".html", "")}
                                target={item?.newTabFive ? "_blank" : ""}
                                rel="noreferrer"
                                aria-label={`${item?.brandNameFive}${
                                  item?.newTabFive
                                    ? " external link opens in a new tab"
                                    : ""
                                }`}
                                data-gbh-data-layer={`{"clickInternalLinks":"${
                                  item?.newTabFive ? "false" : "true"
                                }","internalLinkPosition":"${data?.quadrantTitleFive.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkFive
                                  ?.split(
                                    process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                  )
                                  .join("")
                                  .replace(
                                    ".html",
                                    ""
                                  )}","internalLinkName":"${item?.brandNameFive?.toLowerCase()}","eventName":"footer menu:${item?.brandNameFive?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameFive?.toLowerCase()}"}`}
                              >
                                {item?.brandNameFive}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </section>
      </footer>
    </div>
  )
}

export default MainFooter
