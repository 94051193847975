import React, { useEffect, useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"
import downArrow from "@/public/icons/down-arrow.svg"
import MissingImage from "@/public/images/image-missing.png"
import useWindowResize from "@/hooks/useWindowResize"

import { getPresetUrl } from "@/utils/helper"
import { getConfig } from "@/constants/config"
const AsProductGalleryViewer = props => {
  const [width] = useWindowResize()
  let imageListLength = props?.data?.length
  const viewMoreLink = imageListLength > 3 ? true : false
  const [selectedItem, setSelectedItem] = useState(0)
  const [swatchUrl, setSwatchUrl] = useState("")
  const [scene7AccountName, setScene7AccountName] = useState("")

  useEffect(() => {
    getConfig().then(config => {
      if (config.general) {
        const {
          general: { scene7AccountName = "", swatchUrl = "" },
        } = config
        setScene7AccountName(scene7AccountName)
        setSwatchUrl(swatchUrl)
      }
    })
  }, [imageListLength])

  const handleImageUrl = () => {
    const presetConfig = JSON.parse(props?.presetConfig)
    const asset = props.data[selectedItem].galleryImage?.split("/").pop()
    const assetId = asset?.split(".")
    if (assetId?.length > 1) {
      return getPresetUrl(
        width,
        swatchUrl,
        assetId[0],
        presetConfig,
        scene7AccountName
      )
    } else {
      return getPresetUrl(
        width,
        swatchUrl,
        asset,
        presetConfig,
        scene7AccountName
      )
    }
  }
  useLayoutEffect(() => {
    handleImageUrl()
  }, [width])
  const handleChange = (index, resourceCredits = "") => {
    setSelectedItem(index)
  }

  return (
    <React.Fragment>
      <div className="image-reel row">
        <div className="image-reel__image-thumb-view col-2 no-print">
          <ul className="pl-0 ml-0 image-reel__image-thumb-view-ul">
            {props.data &&
              props.data.slice(0, 5).map((el, i) => {
                return (
                  <li key={i} className="image-reel__image-list">
                    <div
                      className={`image-reel_${
                        selectedItem === i ? "_thumbSelected" : "_image-thumb"
                      }`}
                      onClick={() => {
                        handleChange(i)
                      }}
                      tabIndex="0"
                    >
                      <img
                        onError={e => (e.target.src = MissingImage)}
                        src={el.galleryImage}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </li>
                )
              })}
            {viewMoreLink && (
              <div
                className="image-reel__image-view"
                onClick={() => props.handlePopup()}
                tabIndex="0"
              >
                <span
                  className="image-reel__link-more gbh-data-layer"
                  data-gbh-data-layer='{"eventAction":"pdp:product details:view gallery","eventName":"pdp:product details:view gallery","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view gallery","internalLinkPosition":"pdp","internalLinkType":"pdp:navigation","internalLinkZoneName":"pdp:product details","internalLinkURL":"n/a","clickInternalLinks":"true"}'
                >
                  {props.staticTexts.viewLink}
                </span>
                <img src={downArrow?.src} alt="Images. Press enter key to view the gallery modal popup"/>
              </div>
            )}
          </ul>
        </div>
        <div className="image-reel__image-stack-view col-10">
          <div className="image-reel__image-stack-view-block">
            {props.badge && (
              <div className="product-gallery__badge">
                <p className="product-gallery__new">{props.badge}</p>
              </div>
            )}
            <ul className="pl-0 list-inline image-reel__image-stack-list">
              {props.data && props.data.length > 0 && (
                <li>
                  <img
                    onError={e => (e.target.src = MissingImage)}
                    src={handleImageUrl()}
                    alt=""
                    className={`${
                      selectedItem === 0
                        ? "image-reel__stack-list-image print"
                        : "image-reel__stack-list-image no-print"
                    }`}
                    tabIndex="0"
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

AsProductGalleryViewer.defaultProps = {
  data: [],
  staticTexts: {},
}

AsProductGalleryViewer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  staticTexts: PropTypes.object,
}

export default AsProductGalleryViewer
