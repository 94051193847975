import React, { useEffect, useRef, useState } from "react";
import _get from "lodash/get";
import { useTranslation } from "next-i18next"
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { getConfig } from "@/constants/config"
import { apim } from "@/constants/api"
import ProductListView from "./ProductList.view";
import {
  getFacetsFromUrl,
  getUserPersona,
  isShowAnalyticsEventLoaded,
  getUserAnalyticsObject,
  getPriceSortQuery,
  sanitizeTextForAnalytics,
  sanitizeDOMString,
  getAsUniqueFilter
} from "@/utils/helper";
import {
  selectAuthState
} from "@/store/features/authSlice"
import { useSelector,useDispatch } from "react-redux"
import { getProducts, selectPlpState } from "@/store/features/asPlpSlice";
import { compareState } from "@/store/features/compareSlice";
import { addToCompareProduct, removeFromCompareProduct } from "@/utils/helper"
import { useRouter } from "next/router";
import { startCase } from "lodash";
import { selectFavoritesState } from "@/store/features/favoritesSlice";
const ProductList = (props)=> {
 const {data:componentProps={}}=props??{}
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isAuth } = useSelector(selectAuthState)
    const {plp}=useSelector(selectPlpState)
    const {favorites}=useSelector(selectFavoritesState)
    const compare = useSelector(compareState)
    const favoritesState = favorites?.lineItems??[]
    const { isEnable } = compare??{}
    const [rows, setRows] = useState(isMobile ? 30 : Number(sessionStorage.getItem("rows")) || 30);
    const [start, setStart] = useState(0);
    const [selectedSort, setSelectedSort] = useState({ 
      name: t("kf.plp.label.featured"), 
      query: `` 
    });
    const [curFilterQueries, setCurFilterQueries] = useState([]);
    const [productsPerPage] = useState([30, 60, 90]);
    const [xfHtml, setXfHtml] = useState("");
    const [urlTitle, setUrlTitle] = useState("");
    const [promoDataVal, setPromoData] = useState([]);
    const [position,setPosition]=useState(null)
    // Refs to store the previous values
    const prevSortRef = useRef({ 
      name: t("kf.plp.label.featured"), 
      query: `` 
    });
    const prevStartRef = useRef(0);
    const prevRowsRef = useRef(isMobile ? 30 : Number(sessionStorage.getItem("rows")) || 30);
    const [currentEvent,setCurrentEvent]=useState("")
    const [prevEvent,setPrevEvent]=useState("")
    const router = useRouter()
  const { material } = router.query;
  const [locale,setLocale]=useState("")
  const [currencySign,setCurrencySign]=useState("")
  const [currencyCode,setCurrencyCode]=useState("")
  const [brandName,setBrandName]=useState("")
  const [lwAppName,setLwAppName]=useState("")
  const [language,setLanguage]=useState("")

  useEffect(() => {
    const configFn = async () => {
      const { general={},internationalization={} } = await getConfig()
      setLocale(internationalization?.locale??"")
      setCurrencySign(internationalization?.currencySign??"")
      setCurrencyCode(internationalization?.currencyCode??"")
      setBrandName(general?.siteName??"")
      setLwAppName(general?.lwAppName??"")
      setLanguage(internationalization?.language??"en")
    }
    configFn()
  }, [])

  


useEffect(()=>{
  const onLoadFn = async ()=>{
    if(lwAppName &&language){
    //to scrollup the product list
    window.scrollTo(0, 0);


    let currFilters = [];
    try {
  
       
          //get xF
          if (componentProps.xfLink) {
            setXfHtml(componentProps.xfLink)
          } else console.log("Promo banner xF is not configured");
          //set props and fetch product data
          fetchTitleFromUrl();

          //Get filters from url params
          const urlFilters = getFacetsFromUrl();

          if (urlFilters.length > 0) {
            const resp = await getFacets(componentProps.categoryName);
            const facetLabels = getAsUniqueFilter(resp?.data?.fusion?.facet_labels ?? {});
            const facetFields = getAsUniqueFilter(resp?.data?.fusion["facet.field"] ?? []);
            if (
              resp?.data?.fusion?.isCollapsePLP === "true" ||
              resp?.data?.fusion?.isCollapsePLP === true
            ) {
              setGroupedPLP(true);
            }
            urlFilters.forEach((f) => {
              let facetQuery =
                f.facet === "Price_Range"
                  ? "Price_Range"
                  : Object.keys(facetLabels).find(
                      (key) => facetLabels[key] === f.facet.replaceAll("_", " ")
                    );
              if (facetFields.find((f) => f === facetQuery)) {
                facetQuery = `**${facetQuery}`;
              }
              if (facetQuery) {
                f.facet = facetQuery;
              }
            });
          }

          //Set pre-selected filters
          if (
            urlFilters?.length === 0 &&
            componentProps.attributeName !== "" &&
            componentProps.attributevalue !== ""
          ) {
            const resp = await getFacets(componentProps.categoryName);
            const facetFields = getAsUniqueFilter(resp?.data?.fusion["facet.field"] ?? []);
            if (
              resp?.data?.fusion?.isCollapsePLP === "true" ||
              resp?.data?.fusion?.isCollapsePLP === true
            ) {
              setGroupedPLP(true);
            }
            let facetQuery = componentProps.attributeName;
            if (facetFields.find((f) => f === facetQuery)) {
              facetQuery = `**${facetQuery}`;
            }
            currFilters.push({
              facet: facetQuery,
              value: [componentProps.attributevalue.replace(/"/g, '\\"')],
              display: [componentProps.attributevalue],
            });
          }

          currFilters = [...currFilters, ...urlFilters];
          getProductsList(currFilters, false)
          
    } catch (error) {
      console.log(error);
    }}
  }

  onLoadFn()
},[lwAppName,language])

useEffect(()=>{
  getProductsList([])
},[isAuth,material])

useEffect(()=>{
  if (plp.loaded) {
    plpLoadedEvent();
  }
},[plp.loaded])



  const sanitizeText = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  // To get facet labels and to check if pre-selected filters are multi select facets
  const getFacets = async (category) =>{
    return await apim.get(`/search/plp`, {
      params: {
        fl: "id",
        fq: `language_s:("${language}")`,
        q: "*:*",
        rows: 0,
        profilename: `profile_${lwAppName.toLowerCase()}_PLP_${category}`,
        collections: lwAppName.toLowerCase(),
      },
    });
  }

  const getProductsList = (queries, update) => {
   
    let startVal = 0;

    //save queries to handle sorting
    if (queries) {
      setCurFilterQueries(queries)      
    } else {
      queries = curFilterQueries;
      startVal = start;
    }
    if (!update) {
      setStart(0)
      setPromoData([])
    }

    //build lucid work query and make http request
    let fq = [];
    const persona = getUserPersona();
    queries.forEach((q) => {
      let tag = `{!tag="${q.facet}"}`;
      if (!q.facet.match(/^\*{2}/)) {
        q.facet === "Price_Range" ||
        (q.facet.indexOf("priceList") === 0 && !q.facet.includes('saleAvailable_s') && !q.facet.includes('discountOff_s')) ||
        q.facet.indexOf("discountedPriceStartingAt") === 0
          ? plp?.grouped
            ? fq.push(
                encodeURIComponent(
                  `${
                    `{!tag="discountedPriceStartingAt.${persona}_d"}` +
                    `discountedPriceStartingAt.${persona}_d`
                  }:(${q.value.join(" OR ")})`
                )
              )
            : fq.push(
                encodeURIComponent(
                  `${
                    `{!tag="priceList.${persona}.finalPrice_d"}` +
                    `priceList.${persona}.finalPrice_d`
                  }:(${q.value.join(" OR ")})`
                )
              )
          : fq.push(
              encodeURIComponent(
                `${tag + q.facet}:("${q.value.join('" OR "')}")`
              )
            );
        return;
      }
      q.facet === "Price_Range" ||
      (q.facet.indexOf("priceList") === 0 && !q.facet.includes('saleAvailable_s') && !q.facet.includes('discountOff_s')) ||
      q.facet.indexOf("discountedPriceStartingAt") === 0
        ? plp?.grouped
          ? fq.push(
              `${`discountedPriceStartingAt.${persona}_d`.replace(
                /^\*{2}/,
                ""
              )}:(${encodeURIComponent(q.value)})`
            )
          : fq.push(
              `${`priceList.${persona}.finalPrice_d`.replace(
                /^\*{2}/,
                ""
              )}:(${encodeURIComponent(q.value)})`
            )
        : fq.push(
            `${q.facet.replace(/^\*{2}/, "")}:("${encodeURIComponent(
              q.value.join('","')
            )}")`
          );
    });

    let query = {
      fq,
      rows: rows,
      start:startVal,
    };

    if (selectedSort) {
      if (selectedSort?.query === "price+asc") {
        query.sort = `${getPriceSortQuery()}+asc`;
      } else if (selectedSort?.query === "price+desc") {
        query.sort = `${getPriceSortQuery()}+desc`;
      } else {
        query.sort = selectedSort?.query;
      }
    }

    dispatch(getProducts({
      query,
      update,
      category: componentProps.categoryName
  }));
  };

  const handleSort = (query) =>{
    setSelectedSort(query)
    setStart(0)
  }
    
  const handleRows = (rows) => {
    setRows(rows)
    setStart(0)
    setPrevEvent(currentEvent)
    setCurrentEvent("pagination")
    sessionStorage.setItem("rows", rows);
  };

  const handleLoadMore = () =>{
    setStart(start + rows) 
    setRows(30) 
    setPrevEvent(currentEvent)
    setCurrentEvent("loadmore") 
  }


useEffect(() => {
  // Check if either selectedSort or start has changed
  if (selectedSort?.name !== prevSortRef.current?.name){
    getProductsList();
    // Update the previous values
    prevSortRef.current = selectedSort;
   
  }
  if((start !== prevStartRef.current || currentEvent !== prevEvent) && currentEvent === 'loadmore' ){
    getProductsList(null, true)
    // Update the previous values
    prevStartRef.current = start;
  }
  if((rows !== prevRowsRef.current || currentEvent !== prevEvent) && currentEvent === 'pagination'){
    getProductsList()
    // Update the previous values
    prevRowsRef.current = rows;
  }
}, [selectedSort, start,rows,currentEvent]);
  const fetchTitleFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const title = urlParams.get("title");
    setUrlTitle(title ? title : "")
  };

  const plpLoadedEvent = () => {
    const { data } = plp;
    if (
      !data ||
      !data.length ||
      parseInt(data.length / position) === 0 ||
      !xfHtml
    ) {
      addAnalyticsShowEvent();
    } else {
      // checking if all responses are recorded
      const requestedPromos = [];
      data.map((item, i) => {
        if ((i + 1) % position === 0) {
          requestedPromos.push(i);
        }
      });
      if (requestedPromos.length === 0) {
        addAnalyticsShowEvent();
      } else {
        setTimeout(() => {
          addAnalyticsShowEvent();
        }, 5000);
      }
    }
  };

  const getUniqueList = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const addAnalyticsShowEvent = () => {
    let promoData = promoDataVal.filter(
      (promo) => promo && promo.ctaLink
    );
    promoData.sort((a, b) => a.position - b.position);
    promoData = getUniqueList(promoData, "position");
    let slot = 1;
    const component = [];
    promoData.forEach((promoItem) => {
      let title = promoItem.desc || "";
      if (title) {
        const div = document.createElement("div");
        div.innerHTML = title;
        // This contains HTML code so remove some bugs here.
        title = (div.innerText || div.textContent || "")?.toLowerCase();
        title = title.replaceAll("\n", "");
      }
      promoItem.componentInfo = {
        componentID: `${window.location.origin}${promoItem.ctaLink}`,
        componentMessage: `promo-${slot++}`,
        componentName: title,
        componentLocation: "plp:promo tiles",
        componentType: "internal campaign",
      };
      component.push({
        componentInfo: promoItem.componentInfo,
      });
    });

    setPromoData(promoData)

    if (!isShowAnalyticsEventLoaded()) {
      const page = window.pageObj || {};
      page.user = getUserAnalyticsObject();
      const { data } = plp;
      if (page.category) {
        page.category.room =
          data && data[0] && data[0].category1_s
            ? sanitizeTextForAnalytics(data[0].category1_s)
            : "n/a";
      } else {
        page.category = {
          room:
            data && data[0] && data[0].category1_s
              ? sanitizeTextForAnalytics(data[0].category1_s)
              : "n/a",
        };
      }
      if (
        (!page.user.gId || page.user.gId === "n/a") &&
        (!page.user.commerceToolsID || page.user.commerceToolsID === "n/a")
      ) {
        page.component = component;
        window.pageObj = page;
        window.isShowAnalyticsEvent = true;
      } else {
        window.adobeDataLayer.push({
          event: "cmp:show",
          page: page,
          component: component,
        });
      }
    }
  };

  const sendPositionToParent = (position) => {
    setPosition(position)
  };

  const setPromoDataFn = (promo) => {
    const { data } = plp;

    const promoDataNew = promoDataVal;
    if (promo && !promoDataVal.find((item) => item.position === promo.position)) {
      // don't push duplicates
      promoDataNew.push(promo);
    }
    setPromoData(promoDataNew)
    
        // checking if all responses are recorded
        const requestedPromos = [];
        data.map((item, i) => {
          if ((i + 1) % position === 0) {
            requestedPromos.push(i);
          }
        });
        if (requestedPromos.length === promoDataVal.length) {
          addAnalyticsShowEvent();
        }
      
    
  };

  
   
   
    let { data, total, facets, facetLabels, loading, colors, grouped } = plp||{};
    

    data = sanitizeDOMString(data);
    total = sanitizeDOMString(total);
    facets = sanitizeDOMString(facets);
    facetLabels = sanitizeDOMString(facetLabels);
    loading = sanitizeDOMString(loading);
    colors = sanitizeDOMString(colors);
    grouped = sanitizeDOMString(grouped);

    const sorts = [
      { name: t("kf.plp.label.featured"), query: `` },
      { name: t("kf.plp.label.priceDesc"), query: `price+desc` },
      { name: t("kf.plp.label.priceAsc"), query: `price+asc` },
      {
        name: t("kf.plp.label.atoz"),
        query: `${
          grouped ? "Collapse_Title_s+asc" : "Non_Collapse_Title_s+asc"
        }`,
      },
      {
        name: t("kf.plp.label.ztoa"),
        query: `${
          grouped ? "Collapse_Title_s+desc" : "Non_Collapse_Title_s+desc"
        }`,
      },
    ];
    const staticTexts = {
      expanded: t("kf.productTab.aria.expanded"),
      collapsed: t("kf.productTab.aria.collapsed"),
      totalCount: t("kf.plp.label.totalCount", { count: total }),
      hideFilterAria: t("kf.plp.label.hideFilterAria"),
      showFilterAria: t("kf.plp.label.showFilterAria"),
      clearAllAria: t("kf.plp.label.clearAllAria"),
      listWith: t("kf.plp.label.listWith"),
      items: t("kf.plp.label.items"),
      facetListAriaExpandOrCollapse: t(
        "kf.plp.label.facetListAriaExpandOrCollapse"
      ),
      showMoreFiltersAria: t("kf.plp.label.showMoreFiltersAria"),
      sortByAria: (count) =>
        t("kf.plp.label.sortByAria", { sortByCount: count }),
      sortBykey: t("kf.plp.label.sortBykey"),
      salePriceAria: t("kf.plp.label.salePriceAria"),
      originalPriceAria: t("kf.plp.label.originalPriceAria"),
      productDescription: t("kf.plp.label.productDescription"),
      colorsLinkAria: t("kf.plp.label.colorsLinkAria"),
      pageTitleAria: t("kf.plp.label.pageTitleAria", {
        categoryName: componentProps.categoryName,
        brandName: brandName,
      }),
      print: t("kf.plp.label.print"),
      share: t("kf.plp.label.share"),
      wishlist: t("kf.plp.label.wishlist"),
      showFilter: t("kf.plp.label.showFilter"),
      hideFilter: t("kf.plp.label.hideFilter"),
      showFilterState: t("kf.plp.label.showFilterState"),
      hideFilterState: t("kf.plp.label.hideFilterState"),
      filters: t("kf.plp.label.filters"),
      filterSort: t("kf.plp.label.filterSort"),
      listviewFilter: t("kf.plp.label.listViewDeclare"),
      addToCompare: t("kf.plp.label.addToCompare"),
      colors: t("kf.plp.label.colors"),
      color: t("kf.plp.label.color"),
      view: t("kf.plp.label.view"),
      all: t("kf.plp.label.all"),
      gridView: t("kf.plp.label.gridView"),
      columnView: t("kf.plp.label.columnView"),
      sort: t("kf.plp.label.sort"),
      sortbyExpanded: t("kf.plp.label.sortbyExpanded"),
      sortbyClosed: t("kf.plp.label.sortbyClosed"),
      selected: t("kf.plp.arialabel.selected"),
      altSort: t("kf.plp.label.img.altSort"),
      applyFilters: "Apply Filters",
      results: t("kf.plp.label.results"),
      annsacksResults: t("kf.plp.label.annsacksResults"),
      loadMore: t("kf.plp.label.loadMore"),
      backToTop: t("kf.plp.label.backToTop"),
      showMore: componentProps.isAnnsacksPlp
        ? t("annsacks.plp.label.showMore")
        : t("kf.plp.label.showMore"),
      showLess: componentProps.isAnnsacksPlp
        ? t("annsacks.plp.label.showLess")
        : t("kf.plp.label.showLess"),
      clearAll: t("kf.plp.label.clearAll"),
      priceRangeTitle: t("kf.plp.label.priceRangeTitle"),
      priceRangeDesc: t("kf.plp.label.priceRangeDesc"),
      to: t("kf.plp.label.to"),
      minText: t("kf.plp.label.minText"),
      maxText: t("kf.plp.label.maxText"),
      sameCategory: componentProps.sameCategory
        ? componentProps.sameCategory
        : t("kf.compare.validation.sameCategory"),
      sameVariants: t("kf.compare.validation.sameVariants"),
      maxProduct: componentProps.maxProduct
        ? componentProps.maxProduct
        : t("kf.compare.validation.maxProducts"),
      favSuccessMsg: t("kf.favorites.success"),
      favErrorMsg: t("kf.favorites.error"),
      errors: {
        validRange: t("kf.plp.error.validRange"),
        minGreaterThanMax: t("kf.plp.error.minGreaterThanMax"),
        maxGreaterThanZero: t("kf.plp.error.maxGreaterThanZero"),
        checkValid: t("kf.error.message.price"),
      },
      ariaLabel: {
        columnViewSelected: t("kf.productTab.aria.columnViewSelected"),
        columnViewNotSelected: t("kf.productTab.aria.columnViewNotSelected"),
        gridViewSelected: t("kf.productTab.aria.gridViewSelected"),
        gridViewNotSelected: t("kf.productTab.aria.gridViewNotSelected"),
      },
      startingAt: t("kf.plp.label.startingAt"),
      new: componentProps.newBadge
        ? componentProps.newBadge
        : t("kf.plp.label.new"),
      sale: componentProps.sale ? componentProps.sale : t("kf.plp.label.sale"),
      viewResults: t("kf.plp.label.viewResults"),
      exclusive: t("kf.plp.label.exclusive"),
      newExclusive: t("kf.plp.label.newExclusive"),
      filterOpenAlert: t("kf.plp.ariaLabel.filterOpenAlert"),
      filterCloseAlert: t("kf.plp.ariaLabel.filterCloseAlert"),
      removeItem: t("kf.favorites.removeItem"),
      removeItemError: t("kf.favorites.removeItemError"),
      perBox: t("annsacks.pdpProductSummary.label.perBox"),
      perEach: t("annsacks.pdpProductSummary.label.perEach"),
      perSF: t("annsacks.pdpProductSummary.label.perSF"),
      perLF: t("annsacks.pdpProductSummary.label.perLF"),
    };

    return (
      <ProductListView
        loading={loading}
        {...componentProps}
        buyingGuideDescription={
          componentProps?.buyingGuideDescription ?
          sanitizeText(componentProps?.buyingGuideDescription):""
        }
        plpDisplayDescription={
          componentProps?.plpDisplayDescription ?
          sanitizeText(componentProps?.plpDisplayDescription):""
        }
        curRows={rows}
        start={start}
        data={data}
        facets={facets ?? false}
        facetLabels={facetLabels}
        sorts={sorts}
        totalResults={total}
        onFilter={getProductsList}
        onSort={handleSort}
        selectedSort={selectedSort}
        onRows={handleRows}
        onLoadMore={handleLoadMore}
        staticTexts={staticTexts}
        currencySign={currencySign}
        displayShare={
          componentProps.type === "curated"
            ? componentProps.enableShare
            : componentProps.enableSharePlp
        }
        displayPrint={
          componentProps.type === "curated"
            ? componentProps.enablePrint
            : componentProps.enablePrintPlp
        }
        productsPerPage={productsPerPage}
        xfHtml={xfHtml}
        addToCompare={addToCompareProduct}
        removeFromCompare={removeFromCompareProduct}
        comparedProducts={compare}
        colors={colors}
        currFilters={curFilterQueries}
        selectedFilterCount={
          curFilterQueries &&
          curFilterQueries.reduce((acc, item) => {
            return acc + item.value.length;
          }, 0)
        }
        urlTitle={urlTitle}
        isEnable={isEnable}
        resetTitle={fetchTitleFromUrl}
        isCurated={componentProps.type === "curated"}
        isGrouped={grouped}
        favorites={favoritesState}
        setPromoData={setPromoDataFn}
        sendPositionToParent={sendPositionToParent}
        promoData={promoDataVal}
      />
    );
  
}

export default ProductList;