import React, { useEffect, useRef } from "react"
import axios from "axios"
import MissingImage from "@/public/images/image-missing.png"
const SmartCropImage = ({
  url,
  altText,
  smartCrop = {},
  handleImgeClick = () => {},
}) => {
  let s7Image
  if (typeof window !== "undefined") {
    s7Image = window.s7responsiveImage
  }
  const pressImg = useRef(null)
  const imgPlaceholder =
    url ??
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  useEffect(() => {
    if (smartCrop.scriptStatus) {
      smartCrop.applySmartCrop(pressImg.current)
    }
  }, [smartCrop.scriptStatus, url, s7Image])
  const checkImg = e => {
    e.persist()
    const ele = e.target
    if (ele.src !== MissingImage && ele.src === imgPlaceholder) {
      axios
        .head(ele.dataset.src)
        .then(res => {
          if (!res) handleImgErr(e)
        })
        .catch(() => handleImgErr(e))
    }
  }
  const handleImgErr = e => (e.target.src = MissingImage)
  return (
    <img
      ref={pressImg}
      src={url ? imgPlaceholder : MissingImage}
      data-src={url}
      data-mode="smartcrop"
      onLoad={checkImg}
      onError={handleImgErr}
      alt={altText}
      onClick={handleImgeClick}
    />
  )
}
export default SmartCropImage
