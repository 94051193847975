import React, { useState, useEffect, useRef } from "react"
import {
  sanitizeInnerHtml,
  sanitizeDOMString,
  validateField,
  getShortenedUrl,
} from "@/utils/helper"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "next-i18next"
import _get from "lodash/get"
import ReCaptcha, {
  validateRecaptcha,
} from "@/components/core/ReCaptcha/ReCaptcha"
import SubscribeInfoModal from "@/components/Shared/NewsletterFooter/SubscribeInfoModal"
import styles from "@/components/Shared/NewsletterFooter/index.module.scss"
import { selectGenericState, showToast } from "@/store/features/genericSlice"
import { getTranslations } from "@/components/Shared/NewsletterFooter/localization"
import { getConfig } from "@/constants/config"
import newsLetterBody from "@/components/Shared/NewsletterFooter/newsLetterBody"
import { apim } from "@/constants/api"
import { emailSubscribeEndPoint } from "@/constants/index"
const NewsletterFooter = props => {
  const dispatch = useDispatch()
  const [validationError, setValidationError] = useState("")
  const [email, setEmail] = useState("")
  const [captchaActive, setCaptchaActive] = useState(false)
  const submitButton = useRef(null)
  const { t } = useTranslation("common")
  const [staticTexts, setStaticTexts] = useState({})
  const [siteName, setSiteName] = useState("")
  const [language, setLanguage] = useState("en")
  const [homePagePath, setHomePagePath] = useState("/")
  const [isShowModal, setIsShowModal] = useState(false)
  const [isResetForm, setIsResetForm] = useState(false)
  const [isAlreadyExist, setIsAlreadyExist] = useState(false)
  const [leadGenPageUrl, setLeadGenPageUrl] = useState(null)
  const { subscribeModal } = useSelector(selectGenericState)
  const { data, columnClassNames } = props
  const addNewsletterSignupAnalytics = (status, message) => {
    if (window.adobeDataLayer) {
      const { adobeDataLayer: { getState } = {} } = window
      const eventInfo = {
        "@type": siteName?.toLowerCase() + "/components/newslettersignin",
        eventMsg: message || "n/a",
        eventStatus: status,
        clickInternalLinks: "true",
        internalLinkPosition: "newslettersignin",
        internalLinkZoneName: "global footer",
        internalLinkURL: "n/a",
        internalLinkName: "newsletter submit",
        eventName: "newsletter submit",
        internalLinkType: "global footer:submit",
        eventType: "click",
        eventAction: "newsletter submit",
      }
      const page = (getState && getState("page")) || {}
      window.adobeDataLayer.push({
        event: "cmp:click",
        eventInfo: eventInfo,
        page: page,
      })
    }
  }
  const subModalAnalytics = (msg, hreflink) => {
    if (window.adobeDataLayer) {
      const { adobeDataLayer: { getState } = {} } = window
      const eventInfo = {
        eventMsg: "n/a",
        eventStatus: "n/a",
        clickInternalLinks: "true",
        internalLinkPosition: "newsletter:signin complete",
        internalLinkZoneName: "newsletter submit:get ready",
        internalLinkURL: hreflink ? msg + hreflink : "n/a",
        internalLinkName: msg,
        eventName: `newsletter submit:get ready:${msg}`,
        internalLinkType: "newsletter submit:get ready",
        eventType: "navigation",
        eventAction: `newsletter submit:get ready:${msg}`,
      }
      const page = (getState && getState("page")) || {}
      window.adobeDataLayer.push({
        event: "cmp:click",
        eventInfo: eventInfo,
        page: page,
      })
    }
  }
  useEffect(() => {
    if (isResetForm) setEmail("")
    if (isAlreadyExist) setValidationError(staticTexts?.alreadySubscribed)
  }, [isResetForm, isAlreadyExist])
  useEffect(() => {
    setCaptchaActive(false)
    setStaticTexts(getTranslations(t))
    setInitalData()
  }, [])
  const handleModalClose = () => {
    subModalAnalytics("close")
    setIsShowModal(false)
  }
  const handleModalMsg = hreflink => {
    subModalAnalytics(`${siteName?.toLowerCase()}.com`, hreflink)
  }
  const setInitalData = async () => {
    const config = await getConfig()
    setSiteName(_get(config, "general.siteName", ""))
    setLanguage(_get(config, "internationalization.language", "en"))
    setHomePagePath(
      await getShortenedUrl(_get(config, "general.homepageUrl", "/"))
    )
    if (data?.leadGenCtalink) {
      setLeadGenPageUrl(await getShortenedUrl(data?.leadGenCtalink, true))
    }
  }
  const getSubscriptionData = email => {
    const body = newsLetterBody(siteName, email, language)
    validateRecaptcha()
      .then(res => {
        if (res.success) {
          apim
            .post(emailSubscribeEndPoint, body, {
              params: {
                brand: siteName,
              },
            })
            .then(response => {
              if (response && response.data) {
                const isSuccess = response.data.resultMessages ?? []
                if (isSuccess) {
                  setIsShowModal(true)
                  setIsResetForm(true)
                }
                setEmail("")
                addNewsletterSignupAnalytics("success")
              } else {
                addNewsletterSignupAnalytics(
                  "failure",
                  "news letter subscription api: error occured while fetching data from get url property"
                )
                // log.error(
                //   "News letter subscription API: Error occured while fetching data from Get URL Property"
                // )
              }
            })
            .catch(err => {
              if (
                err.response?.data?.error === "Email address already subscribed"
              ) {
                setIsAlreadyExist(true)
                addNewsletterSignupAnalytics(
                  "failure",
                  staticTexts?.alreadySubscribed.toLowerCase()
                )
              } else {
                console.log(staticTexts.genError)
                dispatch(
                  showToast({
                    message: staticTexts.genError,
                    isVisible: true,
                  })
                )
                addNewsletterSignupAnalytics(
                  "failure",
                  staticTexts.genError.toLowerCase()
                )
              }
            })
        } else {
          dispatch(
            showToast({
              message: staticTexts.genError,
              isVisible: true,
            })
          )
          addNewsletterSignupAnalytics(
            "failure",
            staticTexts.genError.toLowerCase()
          )
        }
      })
      .catch(() => {
        dispatch(
          showToast({
            message: staticTexts.genError,
            isVisible: true,
          })
        )
        addNewsletterSignupAnalytics(
          "failure",
          staticTexts.genError.toLowerCase()
        )
      })
  }
  const onSubmit = async () => {
    if (email === "" || email == null || email?.length == 0) {
      setValidationError(staticTexts.enterEmail)
      addNewsletterSignupAnalytics(
        "failure",
        staticTexts.enterEmail.toLowerCase()
      )
    }
    if (email?.length > 0) {
      const isValid = await validateField("email", email)
      if (!isValid) {
        addNewsletterSignupAnalytics(
          "failure",
          staticTexts.enterEmail.toLowerCase()
        )
        setValidationError(staticTexts.validEmail)
      } else {
        setValidationError("")
        if (leadGenPageUrl) {
          window.location.href = `${leadGenPageUrl}?email=${email}`
        } else {
          getSubscriptionData(email)
        }
      }
    }
    handleFocus()
  }
  const handleFocus = () => {
    // removing focus
    submitButton.current.blur()
  }
  return (
    <React.Fragment>
      <div
        className={`${columnClassNames ? columnClassNames : ""} ${
          styles.wrapper
        }`}
      >
        <footer>
          <section className="gbh-footer-newsletter-nav">
            <section className="gbh-container">
              <section
                className="gbh-footer-news-letter"
                aria-label={data?.title}
                role="list"
              >
                <h2>{data?.title}</h2>
                {sanitizeInnerHtml(data?.description)}
                <div
                  className="form-group newsletter-form-group"
                  role="listitem"
                >
                  <label htmlFor="newsletter_input">
                    <input
                      className="form-control"
                      type="text"
                      aria-label={data?.placeholder}
                      placeholder={data?.placeholder}
                      maxLength="60"
                      id="newsletter_input"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      data-testid="newsletter-input"
                    />
                  </label>
                  {data?.submit && (
                    <button
                      aria-label="Submit button. Tap or click here to subscribe for Koher newsletter to receive news and promotions."
                      className={`btn btn-secondary ${
                        email.length ? "active-btn" : ""
                      }`}
                      id="newsletter_submit"
                      onClick={() => onSubmit()}
                      ref={submitButton}
                      data-testid="newsletter-submit"
                    >
                      {data?.submit}
                    </button>
                  )}
                </div>
                <div role="listitem" className="subsribe-error-container">
                  {validationError && (
                    <span
                      id="subsribe-mail-error"
                      tabIndex="0"
                      data-testid="email-error"
                    >
                      {sanitizeDOMString(validationError)}
                    </span>
                  )}
                </div>
              </section>
            </section>
            {captchaActive && <ReCaptcha />}
          </section>
        </footer>
      </div>
      {
        <SubscribeInfoModal
          show={isShowModal}
          handleModalClose={handleModalClose}
          handleModalMsg={handleModalMsg}
          staticTexts={staticTexts}
          homePagePath={homePagePath}
          className={styles.wrapper}
          setEmail={setEmail}
          modalContent={subscribeModal}
        />
      }
    </React.Fragment>
  )
}
export default NewsletterFooter