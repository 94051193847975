import { getPageInfo } from "@/utils/helper"

const getInternalLinkURLS = url => {
  return url?.startsWith("/") || url?.includes(window.location.hostname)
    ? "true"
    : "false"
}

const setLinkAnalytics = (
  linkUrl,
  type,
  getAnalyticsData,
  eventType = "navigation"
) => {
  if (!getAnalyticsData) return null

  const {
    pageTitle = "n/a",
    internalLinkPosition = "n/a",
    internalLinkTypeWithType = null,
    internalLinkType = null,
    addNoFooterInAttr = false,
  } = getAnalyticsData
  if (typeof type !== "string") {
    // this is html content
    const div = document.createElement("div")
    div.innerHTML = type
    // This contains HTML code so remove some bugs here.
    type = (div.innerText || div.textContent || "")?.toLowerCase()
    type = type.replaceAll("\n", "")
  } else {
    type = type.toLowerCase()
  }
  const eventAction = getAnalyticsData.pageTitle
    ? getAnalyticsData.pageTitle +
      (getAnalyticsData?.addNoFooterInAttr ? "" : ":form:footer") +
      ":" +
      type
    : "n/a"

  const eventInfo = {
    clickInternalLinks: getInternalLinkURLS(linkUrl),
    eventAction: eventAction,
    eventName: eventAction,
    eventType,
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: type || "n/a",
    internalLinkPosition: internalLinkPosition ?? "n/a",
    internalLinkType: internalLinkTypeWithType
      ? internalLinkTypeWithType + ":" + type
      : internalLinkType ?? "n/a",
    internalLinkURL: linkUrl
      ? (linkUrl.indexOf("http") === -1 ? window.location.origin : "") + linkUrl
      : "n/a",
    internalLinkZoneName:
      getAnalyticsData?.pageTitle +
        (getAnalyticsData?.addNoFooterInAttr ? "" : ":form:footer") ?? "n/a",
  }

  addAnalyticsForRecaptchLinks(eventInfo, "cmp:click")
}

const addAnalyticsForRecaptchLinks = (eventInfo, event = "cmp:click") => {
  const page = getPageInfo()

  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
  })
}

export { setLinkAnalytics, addAnalyticsForRecaptchLinks }
